import { environment } from "environment";
import { TextTranslation } from "models";
import { post } from "utils";

class LanguageService {
  translateTexts(texts: Array<TextTranslation>) {
    return post(`${environment.languages.translate}`, texts);
  }
}

export const languageService = new LanguageService();
