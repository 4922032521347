import { environment } from "environment";
import { get } from "utils";

class LearningPostService {
  getAll() {
    return get(`${environment.learningPosts.root}`);
  }
}

export const learningPostService = new LearningPostService();
