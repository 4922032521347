import { PlatformButton, PlatformTableRow } from "components";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

interface Props {
  header: Array<string>;
  body: Array<PlatformTableRow>;
  fileName: string;
}

export const ExportTableToXLSXButton = ({ header, body, fileName }: Props) => {
  const { t } = useTranslation();

  const downloadExcel = () => {
    const records: Array<Record<string, unknown>> = [];
    body.forEach(row => {
      const record: Record<string, unknown> = {};
      row.columns.forEach((column, index) => {
        record[header[index]] = column?.content?.toString();
      });
      records.push(record);
    });
    const worksheet = XLSX.utils.json_to_sheet(records);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "data");
    XLSX.writeFile(workbook, fileName);
  };
  return <PlatformButton onClick={() => downloadExcel()} label={t("common.export_xlsx")} />;
};
