import { get } from "lodash";
import { translateJSON } from "./translate-json";

export const prepareFields = async (formValues: object, currentLanguage: string) => {
  const autotranslation = get(formValues, "autotranslation");
  let fields: Record<string, unknown> = { ...formValues };
  delete fields.autotranslation;
  if (autotranslation) {
    fields = await translateJSON(fields, currentLanguage);
  }
  return fields;
};
