import { CircularProgress } from "@mui/material";
import { Feedback, Feedback360Question } from "models";
import { useEffect, useState } from "react";
import { feedback360Service } from "services";
import { FeedbackForm } from "./feedback-form";

interface Props {
  onClose: () => Promise<void>;
  setIsOpen: (value: boolean) => void;
  feedback?: Feedback;
}

export const FeedbackFormWrapper = ({ feedback, onClose, setIsOpen }: Props) => {
  const [questions, setQuestions] = useState<Array<Feedback360Question>>();
  useEffect(() => {
    (async () => {
      const response = await feedback360Service.loadQuestions();
      setQuestions(response.data);
    })();
  }, []);
  if (!questions?.length) {
    return <CircularProgress />;
  }
  return (
    <FeedbackForm
      feedback={feedback}
      questions={questions}
      onClose={onClose}
      setIsOpen={setIsOpen}
    />
  );
};
