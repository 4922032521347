import { DialogActions, DialogContent } from "@mui/material";
import { NexusInput, PlatformButton, PlatformModal } from "components";
import GameTitle from "components/game-title/game-title";
import { ButtonType } from "enums";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { OpenPayU } from "./open-payu";
import { PaymentMethods } from "./payment-methods";
import { useController } from "./use-controller";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  loadUserPlan: () => Promise<void>;
}

export const AddUsersPlanDialog = ({ isOpen, setIsOpen, loadUserPlan }: Props) => {
  const { t } = useTranslation();
  const { methods, isLoading, onSubmit, shouldShowPayUPayment } = useController(
    setIsOpen,
    loadUserPlan,
  );
  return (
    <PlatformModal isOpen={isOpen}>
      <GameTitle gameName={t("user_plan_management.add_users_to_plan")} />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async ({ paymentMethodId, users }) =>
            onSubmit(paymentMethodId, users),
          )}
          id="add-users-plan-form"
          data-testid="add-users-plan-form"
        >
          <DialogContent className={Styles.content}>
            <PaymentMethods />
            <NexusInput
              label={t("user_plan_management.users_to_buy_for_the_plan")}
              name="users"
              type="number"
            />
          </DialogContent>
          {shouldShowPayUPayment && <OpenPayU />}
          <DialogActions>
            <PlatformButton
              onClick={() => {
                methods.reset();
                setIsOpen(false);
              }}
              label={t("common.cancel")}
            />
            <PlatformButton
              isDisabled={isLoading}
              type={ButtonType.SUBMIT}
              label={t("user_plan_management.add_users_to_plan")}
            />
          </DialogActions>
        </form>
      </FormProvider>
    </PlatformModal>
  );
};
