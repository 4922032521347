import { PlatformButton, PlatformModal } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationManagement } from "./organization-management";

export const OrganizationAlternativeManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <PlatformButton
        onClick={() => setIsOpen(true)}
        label={t("slots_management.subdomains_management")}
      />
      <PlatformModal isOpen={isOpen}>
        {isOpen && <OrganizationManagement onClose={() => setIsOpen(false)} />}
      </PlatformModal>
    </>
  );
};
