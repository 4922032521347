import NeonBox from "components/neon-box/neon-box";
import { useUsernameFormatted } from "hooks";
import { useTranslation } from "react-i18next";
import { RootState, useShallowEqualSelector } from "store";
import { getQuestion } from "utils";

export const Question = () => {
  const { t, i18n } = useTranslation();

  const { questionLanguages, userForStoryTime } = useShallowEqualSelector((state: RootState) => ({
    questionLanguages: state.room.gameState?.question?.questionLanguages,
    userForStoryTime: state.room.gameState?.userForStoryTime,
  }));

  const question = getQuestion(questionLanguages, i18n);
  const userName = useUsernameFormatted(userForStoryTime);
  return (
    <NeonBox>
      <h1 className="text">
        {userName}, {t("think_fast.say_a_word_about")}: {question}
      </h1>
    </NeonBox>
  );
};
