import { CircularProgress } from "@mui/material";
import { PlatformModuleHeader, UpdateCurrentPlan } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { UserPlan } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { planService } from "services";
import Styles from "./index.module.css";
import { PlanOverview } from "./plan-overview";
import { SlotsConsumption } from "./slots-consumption";
import { UpdateRenovationPlan } from "./update-renovation-plan";
import { OrganizationAlternativeManagement } from "./organization-alternative-management";

export const SlotsManagement = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [userPlan, setUserPlan] = useState<UserPlan>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await planService.findUserPlanActive();
        setUserPlan(response.data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  let content = <CircularProgress />;

  if (!isLoading) {
    let options = null;

    if (userPlan) {
      options = (
        <>
          <UpdateRenovationPlan userPlan={userPlan} setUserPlan={setUserPlan} />
          <OrganizationAlternativeManagement />
        </>
      );
    }

    content = (
      <>
        <div className={Styles.buttons}>
          <UpdateCurrentPlan userPlan={userPlan} />
          {options}
        </div>
        {userPlan && <PlanOverview userPlan={userPlan} />}
      </>
    );
  }

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.pay_as_you_go")} />
        {content}
        <SlotsConsumption />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
