export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "100vw",
  bgcolor: "background.paper",
  border: "1px solid #FFFFFF",
  p: 4,
};
