import { useTranslation } from "react-i18next";
import { formatOnlyDate } from "utils";
import { FeedbackAnswerSummary } from "./feedback-answer-summary";
import Styles from "./index.module.css";
import { Feedback, Feedback360Question } from "models";

interface Props {
  feedback: Feedback;
  questions: Array<Feedback360Question>;
}

export const FeedbackResultsViewer = ({ feedback, questions }: Props) => {
  const { t } = useTranslation();
  const questionsIds = Object.keys(feedback?.answers || {});
  return (
    <>
      <p className={Styles.date}>
        <b>{t("common.date")}:</b> {formatOnlyDate(feedback?.createdAt)}
      </p>
      {questionsIds.map((questionId, index) => (
        <FeedbackAnswerSummary
          answers={feedback?.answers[questionId]}
          currentUserId={feedback?.userId}
          key={questionId}
          questionId={questionId}
          questions={questions}
          index={index}
        />
      ))}
    </>
  );
};
