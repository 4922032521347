export enum GameID {
  NEVER_HAVE_I_EVER = 1,
  THINK_FAST = 2,
  MOST_LIKELY_TO = 3,
  A_LIE = 4,
  CHARADES = 5,
  HYPOTETHICALLY = 6,
  DEEP_QUESTIONS = 7,
  DEBATE = 8,
  WHO_AM_I = 9,
  TRIVIA = 10,
}
