import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "toastr/build/toastr.min.css";
import App from "./App";
import "./index.css";
import "./fonts.css";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import "./lang/i18";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";
import { environment } from "environment";
import * as Sentry from "@sentry/react";

const release = process.env.REACT_APP_RELEASE_VERSION;

const isLocal = !release || release === "local";

const rootElement = document.getElementById("root");

let app = (
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </BrowserRouter>
);

console.info(`Running version: ${release}`);

if (!isLocal) {
  Sentry.init({
    dsn: "https://895aeef7fac043538489726e8bf463f3@o4505269826682880.ingest.sentry.io/4505279222841344",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [],
      }),
      new Sentry.Replay(),
    ],
    release,
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app = <Sentry.ErrorBoundary>{app}</Sentry.ErrorBoundary>;
}

if (environment.isPrerender && rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(app, rootElement);
} else {
  ReactDOM.render(app, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
