import { toast } from "react-toastify";

export const registerNewPopup = (
  key: string,
  message: string,
  ms: number,
  link?: string,
  duration = 500,
) => {
  const storageKey = `popup_${key}`;
  const alreadyShowed = localStorage.getItem(storageKey);
  localStorage.setItem(storageKey, "1");
  if (!alreadyShowed) {
    setTimeout(() => {
      const onClick = () => {
        localStorage.setItem(storageKey, "1");
        if (link) {
          window.open(link, "_blank");
        }
      };
      toast.dark(message, {
        onClick,
        autoClose: duration,
        className: "neon",
      });
    }, ms);
  }
};
