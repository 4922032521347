import { NexusInput } from "components";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

export const QuestionForm = (language: string) => {
  const { t } = useTranslation();
  const label = `${t("custom_questions.type")} ${t("custom_questions.the_topic")} ${t(
    "custom_questions.in",
  )} ${t(`languages.${language}`).toLowerCase()}`;

  return (
    <div>
      <NexusInput
        className={Styles.input}
        name={`topic_${language}`}
        label={label}
      />
    </div>
  );
};
