import { FeelingsThermometer, PlatformBox } from "components";
import { useState } from "react";

export const FeelingsSection = () => {
  const [isAlreadySent, setAlreadySent] = useState(false);
  const hasAlreadyGiveUsFeelingsToday = localStorage.getItem("feelings_day");
  const today = new Date().toISOString().split("T").shift();
  if (hasAlreadyGiveUsFeelingsToday === today || isAlreadySent) return null;

  return (
    <PlatformBox className="center">
      <FeelingsThermometer isHorizontal={true} afterSubmit={() => setAlreadySent(true)} />
    </PlatformBox>
  );
};
