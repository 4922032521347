import { environment } from "environment";
import { User } from "models";
import { deleteHttp, get, getLocalTimezone, patch, post } from "utils";

export class UserService {
  findFromOrganization() {
    return get(environment.users.organization);
  }

  findUserWithToken() {
    return get(`${environment.login.root}`);
  }

  updateUserInformation(changes: Partial<User>) {
    return patch(environment.users.root, changes);
  }

  findSubscription() {
    const timezone = getLocalTimezone();
    return get(`${environment.users.subscription}?t=${timezone}`);
  }

  findCreditCards() {
    return get(`${environment.users.creditCard}`);
  }

  createCreditCard(
    name: string,
    identificationNumber: number,
    creditCardNumber: string,
    franchise: string,
    expirationMonth: string,
    expirationYear: string,
    ccv: number,
    language: string,
    shippingAddress: string,
    shippingCity: string,
    shippingState: string,
    shippingCountry: string,
    postalCode: string,
    phone: string,
  ) {
    return post(`${environment.users.creditCard}`, {
      name,
      identificationNumber,
      number: creditCardNumber,
      franchise,
      expirationMonth,
      expirationYear,
      ccv,
      language,
      shippingAddress,
      shippingCity,
      shippingState,
      shippingCountry,
      postalCode,
      phone,
    });
  }

  deleteCreditCard(id: number) {
    return deleteHttp(`${environment.users.creditCard}/${id}`);
  }
}

export const userService = new UserService();
