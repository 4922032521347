import { PlatformTable, PlatformTableRow } from "components";
import { useCurrentLanguage } from "hooks";
import { Category } from "models";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Actions } from "./actions";
import { ManageQuestionsActions } from "./manage-questions-action";
import Styles from "./index.module.css";
import { find } from "lodash";

const titles = ["custom_categories.category", "custom_categories.add_questions", "common.actions"];

interface Props {
  loadCategories: () => Promise<void>;
  categories: Array<Category>;
  isLoading: boolean;
}

export const Table = ({ loadCategories, categories, isLoading }: Props) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();

  useEffect(() => {
    loadCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = categories.map(category => ({
    id: category._id,
    columns: [
      {
        id: `name_${category._id}`,
        content: find(category?.categoryLanguages, e => e.languageId === currentLanguage)?.label,
      },
      {
        id: `add_questions_${category._id}`,
        content: <ManageQuestionsActions category={category} />,
      },
      {
        id: `actions_${category._id}`,
        content: <Actions loadCategories={loadCategories} category={category} />,
      },
    ],
  }));
  return (
    <PlatformTable
      className={`neonScroll ${Styles.table}`}
      isLoading={isLoading}
      header={header}
      body={body}
    />
  );
};
