import excitedIcon from "assets/icons/feelings/excited.png";
import fineIcon from "assets/icons/feelings/fine.png";
import neutralIcon from "assets/icons/feelings/neutral.png";
import overwhelmedIcon from "assets/icons/feelings/overwhelmed.png";
import sadIcon from "assets/icons/feelings/sad.png";
import { Feeling } from "enums";

export const feelings = [
  {
    label: "feelings.overwhelmed",
    value: Feeling.OVERWHELMED,
    icon: overwhelmedIcon,
  },
  {
    label: "feelings.sad",
    value: Feeling.SAD,
    icon: sadIcon,
  },
  {
    label: "feelings.neutral",
    value: Feeling.NEUTRAL,
    icon: neutralIcon,
  },
  {
    label: "feelings.fine",
    value: Feeling.FINE,
    icon: fineIcon,
  },
  {
    label: "feelings.excited",
    value: Feeling.EXCITED,
    icon: excitedIcon,
  },
];

