import { PlatformBox, PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { useCurrentLanguage } from "hooks";
import { find } from "lodash";
import { CategoryLanguage } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { categoryService } from "services";
import { GameID } from "utils";
import {
  AddCharades,
  AddDebate,
  AddDeepQuestions,
  AddHypotethically,
  AddMostLikelyTo,
  AddNeverHaveIEver,
  AddThinkFast,
  AddTrivia,
} from "./games";
import Styles from "./index.module.css";

export const CustomQuestions = () => {
  const { t } = useTranslation();
  const [category, setCategory] = useState("");
  const { gameId, categoryId } = useParams();

  const currentLanguage = useCurrentLanguage();

  let module = null;
  let gameName = "";

  useEffect(() => {
    (async () => {
      const response = await categoryService.getCategoryById(categoryId as string);
      const categoryName = find(
        response?.data?.categoryLanguages,
        (e: CategoryLanguage) => e.languageId === currentLanguage,
      ).label;
      setCategory(categoryName);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, currentLanguage]);

  switch (Number(gameId) as unknown as GameID) {
    case GameID.NEVER_HAVE_I_EVER:
      module = <AddNeverHaveIEver />;
      gameName = t("games.never_have_i_ever");
      break;
    case GameID.THINK_FAST:
      module = <AddThinkFast />;
      gameName = t("games.think_fast");
      break;
    case GameID.MOST_LIKELY_TO:
      module = <AddMostLikelyTo />;
      gameName = t("games.most_likely_to");
      break;
    case GameID.CHARADES:
      module = <AddCharades />;
      gameName = t("games.charades");
      break;
    case GameID.HYPOTETHICALLY:
      module = <AddHypotethically />;
      gameName = t("games.hypotethically");
      break;
    case GameID.DEEP_QUESTIONS:
      module = <AddDeepQuestions />;
      gameName = t("games.deep_questions");
      break;
    case GameID.DEBATE:
      module = <AddDebate />;
      gameName = t("games.debate");
      break;
    case GameID.TRIVIA:
      module = <AddTrivia />;
      gameName = t("games.trivia");
      break;
  }

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.custom_questions")} />
        <PlatformBox className={Styles.container}>
          <label>
            {t("common.game")}: {gameName}
          </label>
          <label>
            {t("custom_categories.category")}: {category}
          </label>
        </PlatformBox>
        {module}
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
