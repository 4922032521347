import { PlatformTable, PlatformTableRow } from "components";
import { Bill } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { paymentService } from "services";
import { formatOnlyDate, formatPrice } from "utils";
import Styles from "./index.module.css";
import { TransactionIdColumn } from "./transaction-id-column";

const titles = [
  "billing.payment_date",
  "billing.accounts_amount",
  "billing.amount",
  "billing.currency",
  "billing.transaction_id",
];

export const Table = () => {
  const [bills, setBills] = useState<Array<Bill>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await paymentService.findAll();
      setBills(response.data);
      setIsLoading(false);
    })();
  }, []);
  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = bills.map(bill => ({
    id: String(bill.id),
    columns: [
      {
        id: `payment_date_${bill.id}`,
        content: formatOnlyDate(bill.createdAt),
      },
      {
        id: `accounts_amount_${bill.id}`,
        content: bill.accountsAmount,
      },
      {
        id: `amount_${bill.id}`,
        content: <label>{formatPrice(bill.price)}</label>,
      },
      {
        id: `currency_${bill.id}`,
        content: bill.currency,
      },
      {
        id: `payment_method_${bill.id}`,
        content: (
          <div className="right">
            <TransactionIdColumn bill={bill} />
          </div>
        ),
      },
    ],
  }));
  return (
    <PlatformTable
      isLoading={isLoading}
      className={`${Styles.table} neonScroll`}
      header={header}
      body={body}
    />
  );
};
