import { languageResources } from "lang/languages";
import { CategoryLanguage } from "models";
import { categoryService } from "services";
import { prepareFields } from "utils";

const languages = Object.keys(languageResources);

export const onSubmit =
  (
    currentLanguage: string,
    setIsLoading: (value: boolean) => void,
    afterCompleteForm: () => Promise<void>,
    categoryId?: string,
  ) =>
  async (formValues: object) => {
    setIsLoading(true);
    const fields = await prepareFields(formValues, currentLanguage);

    const categoryLanguages: Array<CategoryLanguage> = languages.map(languageId => ({
      languageId,
      label: fields[`name_${languageId}`] as string,
    }));

    if (categoryId) {
      await categoryService.editCategory(categoryId, categoryLanguages);
    } else {
      await categoryService.createCategory(categoryLanguages);
    }
    setIsLoading(false);
    await afterCompleteForm();
  };
