import { environment } from "environment";
import { OrganizationAlternative } from "models";
import { get, getLocalTimezone, patch, post } from "utils";

class PlanService {
  findUserPlan() {
    return get(environment.plans.plans);
  }

  findUserPlanConsumption() {
    const timezone = getLocalTimezone();
    return get(`${environment.plans.plansConsumption}/${timezone}`);
  }

  findUserPlanConsumptionByMonthAndYear(month: string, year: string) {
    return get(`${environment.plans.plansConsumption}/${month}/${year}`);
  }

  getSlotsConsumption() {
    return get(`${environment.plans.slotsConsumption}`);
  }

  getOrganizationAlternatives() {
    return get(`${environment.plans.organizationAlternatives}`);
  }

  updateOrganizationAlternatives(domains: Array<OrganizationAlternative>) {
    return patch(`${environment.plans.organizationAlternatives}`, domains);
  }

  findUserPlanAsAdmin() {
    return get(environment.plans.plansAsAdmin);
  }

  findUserPlanActive() {
    return get(environment.plans.plansActive);
  }

  findUserLicenseAssignments() {
    return get(environment.plans.userLicenseAssignments);
  }

  createUserLicenseAssignment(email: string, name: string) {
    return post(environment.plans.userLicenseAssignment, {
      email: String(email).toLowerCase().trim(),
      name: String(name).trim(),
    });
  }

  updateActiveUserLicenseAssignment(userId: number, active: boolean) {
    return patch(environment.plans.userLicenseAssignment, {
      userId,
      active,
    });
  }

  updateSlotsRenovationPlan(limit: number) {
    return patch(environment.plans.renovationPlan, {
      limit,
    });
  }

  payPerUsers(paymentMethodId: number, users: number) {
    return patch(environment.plans.payPerUsers, {
      paymentMethodId,
      users,
    });
  }

  updateUserPlan(limit: number) {
    return patch(environment.plans.plansActive, {
      limit,
    });
  }

  updateIsHostUserLicenseAssignment(userId: number, active: boolean) {
    return patch(environment.plans.host, {
      userId,
      active,
    });
  }

  updateIsAdminUserLicenseAssignment(userId: number, active: boolean) {
    return patch(environment.plans.admin, {
      userId,
      active,
    });
  }
}

export const planService = new PlanService();
