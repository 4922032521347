import { PlatformBox } from "components";
import { useCurrentLanguage } from "hooks";
import { compact, get } from "lodash";
import { Feedback360Question, FeedbackAnswer } from "models";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { Comments } from "./comments";

interface Props {
  questionId?: string;
  questions?: Array<Feedback360Question>;
  currentUserId?: number;
  answers?: Record<string, FeedbackAnswer>;
  index: number;
}
export const FeedbackAnswerSummary = ({
  questions,
  questionId,
  currentUserId,
  answers,
  index,
}: Props) => {
  const currentLanguage = useCurrentLanguage();
  const { t } = useTranslation();

  const questionLabel = questions
    ?.find(({ _id }) => _id === questionId)
    ?.feedbackLanguages.find(({ languageId }) => languageId === currentLanguage)?.label;

  const participantsIds = Object.keys(answers || {}).filter(
    userId => String(currentUserId) !== userId,
  );

  const partnersScore = participantsIds.reduce((currentScore, participantId) => {
    const participantScore = get(answers, `${participantId}`)?.score as number;
    return currentScore + participantScore;
  }, 0);

  const comments = compact(
    participantsIds.map(participantId => get(answers, `${participantId}`)?.comment),
  );

  const questionIndex = index + 1;

  return (
    <div className={Styles.container}>
      <div className={Styles.questions}>
        <label className={Styles.questionTitle}>
          {t("custom_questions.question")} #{questionIndex}:
        </label>
        <label> {questionLabel}</label>
      </div>
      <div className={Styles.scoresContainer}>
        <PlatformBox className={Styles.scoreContainer}>
          <label>{t("feedback_360.self_assessment_score")}: </label>
          <label>{Number(get(answers, `${currentUserId}.score`)).toFixed(2)}</label>
        </PlatformBox>
        <PlatformBox className={Styles.scoreContainer}>
          <label>{t("feedback_360.peers_score")}: </label>
          <label>{(partnersScore / participantsIds.length).toFixed(2)}</label>
        </PlatformBox>
      </div>
      <Comments comments={comments} questionId={questionIndex} />
    </div>
  );
};
