import ShowUsersWithFocus from "components/show-users-with-focus/show-users-with-focus";
import { NextButton } from "./next-button";
import { Title } from "./title";
import { SkipPlayerButton } from "components";

export const BreakTime = () => {
  return (
    <>
      <SkipPlayerButton />
      <Title />
      <ShowUsersWithFocus />
      <NextButton />
    </>
  );
};
