import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { RoutingConfiguration } from "./routing";
const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <RoutingConfiguration />
    </LocalizationProvider>
  );
};

export default App;
