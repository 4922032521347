import { UseFormReturn } from "react-hook-form";
import { BuildQuestionFormType } from "../types";
import { ModalBodyWithTabs } from "./modal-body-with-tabs";

interface Props {
  currentLanguage: string;
  methods: UseFormReturn<Record<string, string | number | boolean>, object>;
  buildQuestionForm: BuildQuestionFormType;
}
export const ModalBody = ({ currentLanguage, methods, buildQuestionForm }: Props) => {
  const values = methods.watch();

  if (values.autotranslation) {
    return <>{buildQuestionForm(currentLanguage)}</>;
  }

  return (
    <ModalBodyWithTabs buildQuestionForm={buildQuestionForm} currentLanguage={currentLanguage} />
  );
};
