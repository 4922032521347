import { AddQuestionButton } from "../../common/add-question-button";
import { BuildInitValuesType, BuildValidationsType } from "../../common/types";
import { useLoadQuestions } from "../../utils/use-load-questions";
import { QuestionForm } from "./question-form";
import { Table } from "./table";
import { buildInitValues, buildValidations, onSubmit } from "./utils";

export const AddThinkFast = () => {
  const { isLoading, questions, loadQuestions } = useLoadQuestions();
  return (
    <>
      <AddQuestionButton
        loadQuestions={loadQuestions}
        buildInitValues={buildInitValues as BuildInitValuesType}
        buildValidations={buildValidations as BuildValidationsType}
        onSubmit={onSubmit}
        suffix="topic"
        buildQuestionForm={QuestionForm}
      />
      <Table
        buildQuestionForm={QuestionForm}
        loadQuestions={loadQuestions}
        isLoading={isLoading}
        questions={questions}
      />
    </>
  );
};
