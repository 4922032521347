import { FormControl, FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import Styles from "./index.module.css";

class Props {
  label!: string;
  name!: string;
  className?: string;
  items: Array<{ id: string | number; label: string }> = [];
}

export const NexusAutocomplete = ({ label, name, items }: Props) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const hasErrors = !!errors[name];
  const options = items.map(({ id }) => id);
  const labels = new Map();
  for (const item of items) {
    labels.set(item.id, item.label);
  }
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...props } }) => (
        <div className={Styles.container}>
          <FormControl>
            <Autocomplete
              disablePortal
              options={options}
              sx={{ width: '100%' }}
              renderInput={params => {
                return <TextField error={hasErrors} {...params} label={label} />;
              }}
              getOptionLabel={option => labels.get(option)}
              onChange={(_, data) => onChange(data)}
              {...props}
            />
            {hasErrors ? (
              <FormHelperText error={true}>{errors[name]?.message}</FormHelperText>
            ) : null}
          </FormControl>
        </div>
      )}
    ></Controller>
  );
};
