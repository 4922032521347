import { ChangeLanguageButton, PlatformBox, PlatformButton, WhatsappButton } from "components";
import { environment } from "environment";
import { GalacticWallpaper } from "hoc";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const { platformUrl } = environment;

const goToLogin = () => window.location.replace(`${platformUrl}/login`);

export const InvalidEmail = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const { t } = useTranslation();

  return (
    <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
      <PlatformBox>
        <p>
          {t("errors.this_is_the_email_you_used")} {email}.
        </p>
        <p>{t("errors.please_use_an_email_from_the_enterprise")}</p>
        <PlatformButton onClick={goToLogin} label={t("login.go_to_login")} />
      </PlatformBox>
      <ChangeLanguageButton />
      <WhatsappButton position="left" />
    </GalacticWallpaper>
  );
};
