import { environment } from "environment";
import { get, patch, post } from "utils";
import { WebSocketEvent } from "enums/websocket-event";
import { gameManager } from "./game-manager.service";
import { CategoryLanguage } from "models";

class CategoryService {
  getCategoriesByGameId(gameId: number) {
    return get(`${environment.categories.root}/game/${gameId}`);
  }

  getCategoryById(categoryId: string) {
    return get(`${environment.categories.root}/${categoryId}`);
  }

  selectCategory(roomPin: string, categoryId: string) {
    gameManager.sendMessage(WebSocketEvent.SELECT_CATEGORY, {
      roomPin,
      categoryId,
      operation: "add",
    });
  }

  unselectCategory(roomPin: string, categoryId: string) {
    gameManager.sendMessage(WebSocketEvent.SELECT_CATEGORY, {
      roomPin,
      categoryId,
      operation: "delete",
    });
  }

  createCategory(categoryLanguages: Array<CategoryLanguage>) {
    return post(environment.categories.root, {
      categoryLanguages,
    });
  }

  editCategory(categoryId: string, categoryLanguages: Array<CategoryLanguage>) {
    return patch(`${environment.categories.root}/${categoryId}`, {
      categoryLanguages,
    });
  }

  getCategories() {
    return get(`${environment.categories.root}`);
  }
}

export const categoryService = new CategoryService();
