import { NexusInput } from "components";
import { useTranslation } from "react-i18next";
import { optionFields } from "../constants";
import Styles from "./index.module.css";

export const QuestionForm = (language: string) => {
  const { t } = useTranslation();
  const prefix = `${t("custom_questions.type")}`;
  const suffix = `${t("custom_questions.in")} ${t(`languages.${language}`).toLowerCase()}`;

  return (
    <div>
      <div>
        <NexusInput
          className={Styles.input}
          name={`question_${language}`}
          label={`${prefix} ${t("custom_questions.the_question")} ${suffix}`}
        />
      </div>
      <div className={Styles.optionsContainer}>
        {optionFields.map(optionField => {
          const label = optionField === "right" ? "right" : "wrong";
          return (
            <NexusInput
              key={optionField}
              className={Styles.input}
              name={`${optionField}_${language}`}
              label={`${prefix} ${t(`custom_questions.${label}_option`)} ${suffix}`}
            />
          );
        })}
      </div>
    </div>
  );
};
