import Logo from "assets/images/logo.webp";
import { PlatformButton } from "components";
import { environment } from "environment";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
const { platformUrl } = environment;

const goToLogin = () => window.location.replace(`${platformUrl}/login`);
const goToSignUp = () => window.location.replace(`${platformUrl}/login?signUp=true`);

export const Header = () => {
  const { t } = useTranslation();
  return (
    <header className={Styles.header}>
      <div className={Styles.logoContainer}>
        <img className={Styles.logo} alt={"logo"} src={Logo} />
      </div>
      <div className={Styles.loginButtonContainer}>
        <PlatformButton
          className={Styles.loginButton}
          isAlternative={true}
          onClick={goToLogin}
          label={t("landing.login")}
        />
        <PlatformButton
          className={Styles.signUpButton}
          onClick={goToSignUp}
          label={t("landing.sign_up")}
        />
      </div>
    </header>
  );
};
