import { UserContainer } from "components";
import { User } from "models";
import { useFormContext } from "react-hook-form";
import Styles from "./index.module.css";

interface Props {
  user: User;
}
export const UserToSelect = ({ user }: Props) => {
  const { setValue } = useFormContext();
  return (
    <div className={Styles.container} onClick={() => setValue("user", user)}>
      <UserContainer user={user} />
    </div>
  );
};
