import { formatOnlyDate } from "utils";
import Styles from "./index.module.css";
interface Props {
  description: string;
  timestamp?: string;
}

const colorsArray = [
  "F3D3B7",
  "FFDAC1",
  "FCD5CE",
  "D5C7BC",
  "B7D1A3",
  "B5EAD7",
  "A2D2FF",
  "C7B5E8",
  "E8B5E0",
  "F3B3CB",
  "F9C1BB",
  "CBE1EF",
  "E3C9DE",
  "FCEBB6",
];

const colors = new Map(colorsArray.map((color, index) => [index, color]));

export const PostIt = ({ description, timestamp }: Props) => {
  const colorId = description.length % colors.size;
  const color = colors.get(colorId);
  return (
    <div className={Styles.container} style={{ backgroundColor: `#${color}` }}>
      <div className={Styles.timestamp}>
        <label>{formatOnlyDate(timestamp)}</label>
      </div>
      <label className={Styles.description}>{description}</label>
    </div>
  );
};
