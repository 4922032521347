import { UserLicenseAssignment, UserPlan } from "models";
import { AddUser } from "./add-user";
import { AdminCount } from "./admin-count";
import { HostCount } from "./host-count";
import Styles from "./index.module.css";
import { UserCount } from "./user-count";

class Props {
  users?: Array<UserLicenseAssignment> = [];
  loadUsers?: () => void;
  userPlan?: UserPlan;
}

export const Header = (props: Props) => {
  return (
    <section className={Styles.container}>
      <div className={Styles.usersCount}>
        <UserCount totalAccounts={props.userPlan?.maxTotalAccounts} users={props.users} />
        <HostCount totalHosts={props.userPlan?.plan?.maxHosts} users={props.users}/>
        <AdminCount totalAdmin={props.userPlan?.plan?.maxAdmin} users={props.users}/>
      </div>
      <div className={Styles.addUserContainer}>
        <AddUser loadUsers={props.loadUsers} />
      </div>
    </section>
  );
};
