import { RootState, useShallowEqualSelector } from "store";

export const useUserIsSupport = () => {
  const { usersDetails, userId } = useShallowEqualSelector((state: RootState) => ({
    usersDetails: state.room.usersDetails,
    userId: state.user.id,
  }));

  const userDetails = usersDetails[String(userId)];

  if (!userDetails) return false;
  
  return userDetails?.isSupport || false;

}
