import { size, trim } from "lodash";
import { OrganizationAlternative } from "models";
import { useEffect, useState } from "react";
import { planService } from "services";

const cleanOrganizationAlternatives = (alternatives: Array<OrganizationAlternative>) => {
  return alternatives.filter(alternative => !!size(trim(alternative.domain)));
};

export const useController = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [organizationAlternatives, setOrganizationAlternatives] = useState<
    Array<OrganizationAlternative>
  >([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await planService.getOrganizationAlternatives();
        setOrganizationAlternatives(response.data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const addAlternative = () => {
    setOrganizationAlternatives([...organizationAlternatives, new OrganizationAlternative()]);
  };

  const updateAlternatives = async () => {
    setIsLoading(true);
    try {
      const response = await planService.updateOrganizationAlternatives(
        cleanOrganizationAlternatives(organizationAlternatives),
      );
      setOrganizationAlternatives(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAlternative =
    (alternativeIndex: number) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newOrganizationAlternatives = [...organizationAlternatives];
      newOrganizationAlternatives[alternativeIndex] = {
        ...newOrganizationAlternatives[alternativeIndex],
        domain: e?.target?.value,
      };
      setOrganizationAlternatives(newOrganizationAlternatives);
    };

  const deleteAlternative = (alternativeIndex: number) => () => {
    const newAlternatives = [...organizationAlternatives];
    newAlternatives.splice(alternativeIndex, 1);
    setOrganizationAlternatives(newAlternatives);
  };
  return {
    isLoading,
    organizationAlternatives,
    updateAlternative,
    deleteAlternative,
    addAlternative,
    updateAlternatives,
  };
};
