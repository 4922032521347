import { useEffect, useRef } from "react";
import Styles from "./index.module.css";
import { Options } from "./options";

interface Props {
  onClickOutside: () => void;
}

export const Menu = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const hasClickOutside = ref.current && !ref.current.contains(event.target as Node);
      if (hasClickOutside) {
        onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div ref={ref} className={Styles.container}>
      <Options />
    </div>
  );
};
