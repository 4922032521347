import { CircularProgress, TextField } from "@mui/material";
import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { LearningPost } from "models";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { learningPostService } from "services";
import { Post } from "./post";
import Styles from "./index.module.css";
import { PostModal } from "./post-modal";
import { includesString } from "utils";
import { useCurrentLanguage } from "hooks";
import { find } from "lodash";

export const LearningCenter = () => {
  const { t } = useTranslation();
  const [learningPosts, setLearningPosts] = useState<Array<LearningPost>>([]);
  const [loading, setLoading] = useState(false);
  const [learningPost, setLearningPost] = useState<LearningPost | null>(null);
  const [filter, setFilter] = useState("");
  const language = useCurrentLanguage();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await learningPostService.getAll();
        setLearningPosts(data);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  let posts = <CircularProgress />;

  const postsFiltered = useMemo(() => {
    if (!learningPosts) return [];
    return learningPosts.filter(post => {
      const label = find(
        post?.linkLanguages,
        linkLanguage => linkLanguage.languageId === language,
      )?.label;
      return includesString(label || "", filter);
    });
  }, [filter, language, learningPosts]);

  if (!loading) {
    posts = (
      <div className={Styles.postContainer}>
        {postsFiltered.map(post => (
          <Post key={post._id} setLearningPost={setLearningPost} post={post} />
        ))}
      </div>
    );
  }

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.learning_center")} />
        <div className={Styles.filterContainer}>
          <TextField
            label={t("learning_center.filter_posts")}
            value={filter}
            onChange={event => setFilter(event.target.value)}
          ></TextField>
        </div>
        {posts}
        <PostModal post={learningPost as LearningPost} onClose={() => setLearningPost(null)} />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
