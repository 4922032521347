import { useTranslation } from "react-i18next";

interface Props {
  boolean: boolean,
}

export const BooleanColumn = (props: Props) => {
  const { t } = useTranslation();
  const message = props.boolean
    ? t("common.yes")
    : t("common.no");
  return <label>{message}</label>;
}