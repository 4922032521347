/* eslint-disable react/jsx-no-target-blank */
import { sanitizeLanguage } from "lang/i18";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

export const Links = () => {
  const { t, i18n } = useTranslation();
  const lang = sanitizeLanguage(i18n.language);
  return (
    <div className="centerContent">
      <div className={Styles.container}>
        <a target="_blank" href={`https://joinnexus.io/legal/${lang}/terms-and-conditions.pdf`}>
          {t("landing.terms_and_conditions")}
        </a>
        <a target="_blank" href={`https://joinnexus.io/legal/${lang}/privacy.pdf`}>
          {t("landing.privacy_policy")}
        </a>
        <a target="_blank" href={`https://joinnexus.io/faq/${lang}.pdf`}>
          {t("landing.faq")}
        </a>
      </div>
    </div>
  );
};
