import { Fragment } from "react";
import { PlatformButton } from "components";
import { ButtonType } from "enums";
import { useTranslation } from "react-i18next";
import { useController } from "../../../use-controller";
import { useFormContext } from "react-hook-form";

import Styles from "./index.module.css";
import { useCurrentLanguage } from "hooks";
import { CircularProgress, Tooltip } from "@mui/material";

interface Props {
  closeModal: () => void;
}

export const SelectProtonType = ({ closeModal }: Props) => {
  const { t } = useTranslation();
  const { loading, protonTypes } = useController();
  const { setValue } = useFormContext();

  const currentLanguage = useCurrentLanguage();

  let content = <CircularProgress />;
  if (!loading) {
    content = (
      <ul className={Styles.list}>
        {protonTypes.map(type => (
          <Tooltip
            key={type.id}
            title={
              type.protonTypeLanguages.find(({ languageId }) => languageId === currentLanguage)
                ?.description as string
            }
          >
            <div className={Styles.typeContainer} onClick={() => setValue("protonType", type)}>
              <figure>
                <img src={type.protonTypeImage} alt="Proton" className={Styles.typeImage} />
              </figure>
              <label>
                {
                  type.protonTypeLanguages.find(({ languageId }) => languageId === currentLanguage)
                    ?.name
                }
              </label>
            </div>
          </Tooltip>
        ))}
      </ul>
    );
  }

  return (
    <Fragment>
      <h2 className={Styles.titleSection}>Select a proton</h2>
      <section>{content}</section>
      <PlatformButton
        onClick={() => closeModal()}
        type={ButtonType.BUTTON}
        label={t("common.cancel")}
      />
    </Fragment>
  );
};
