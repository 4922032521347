import { environment } from "environment";
import { get, post } from "utils";

class AnonymousFeedbackService {
  getCategoriesByGameId(sizeId: number, startFrom = "") {
    return get(`${environment.anonymousFeedback.cursor}?sizeId=${sizeId}&startFrom=${startFrom}`);
  }

  getCount() {
    return get(`${environment.anonymousFeedback.count}`);
  }

  giveFeedback(userId: number, feedback: string) {
    return post(environment.anonymousFeedback.root, {
      userId,
      feedback,
    });
  }
}

export const anonymousFeedbackService = new AnonymousFeedbackService();
