import { languageResources } from "lang/languages";
import { Question } from "models";
import { TFunction } from "react-i18next";

const languages = Object.keys(languageResources);

export const buildInitValues = (t: TFunction<"translation", undefined>, question: Question) => {
  const initValuesTemplate: Record<string, string | number | boolean> = {
    autotranslation: 1,
  };
  languages.forEach(language => {
    const prefix = `${t("most_likely_to.question_prefix", { lng: language })}`;
    initValuesTemplate[`question_${language}`] = `${prefix} `;
  });

  if (question) {
    question.questionLanguages.forEach(({ label, languageId }) => {
      initValuesTemplate[`question_${languageId}`] = label;
    });
  }

  return initValuesTemplate;
};
