import { t } from "i18next";
import { languageResources } from "lang/languages";
import { Question } from "models";
import { questionService } from "services";
import { prepareFields } from "utils";

const languages = Object.keys(languageResources);

export const onSubmit =
  (
    currentLanguage: string,
    setIsLoading: (value: boolean) => void,
    afterCompleteForm: () => Promise<void>,
    categoryId: string,
    gameId: number,
    questionId?: string,
  ) =>
  async (formValues: object) => {
    setIsLoading(true);
    const fields = await prepareFields(formValues, currentLanguage);

    Object.keys(fields).forEach(field => {
      if (field === "autotranslation") return;
      const value = fields[field] as string;
      const language = field.split("_").pop();
      const prefix = t("hypotethically.prefix", { lng: language });
      const valueWithoutPrefix = value.replace(prefix, "").trim();
      fields[field] = valueWithoutPrefix;
    });

    const categoryLanguages: Partial<Question> = {
      categories: {
        [categoryId]: 1,
      },
      questionLanguages: languages.map(languageId => ({
        languageId,
        label: fields[`question_${languageId}`] as string,
      })),
    };

    if (questionId) {
      await questionService.editQuestion(gameId, questionId, categoryLanguages);
    } else {
      await questionService.createQuestion(gameId, categoryLanguages);
    }
    setIsLoading(false);
    await afterCompleteForm();
  };
