import { lowerCase, size, trim } from "lodash";
import { UserLicenseAssignment } from "models";
import { normalize } from "utils";

export const filterAssignments = (assignments: Array<UserLicenseAssignment> = [], query = "") => {
  if (!size(trim(query))) return assignments;
  const queryFormatted = normalize(lowerCase(trim(query)));

  const filterByProperty = (property?: string) =>
    normalize(lowerCase(trim(property))).includes(queryFormatted);

  const filterByNameOrEmail = (assignment: UserLicenseAssignment) =>
    filterByProperty(assignment?.toUser.name) || filterByProperty(assignment?.toUser.email);

  return assignments.filter(filterByNameOrEmail);
};
