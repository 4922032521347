import { CircularProgress } from "@mui/material";
import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { Event } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { eventService } from "services";
import { EventSettings } from "./event-settings";
import { PriorityHosts } from "./priority-hosts";
import { Table } from "./table";
import Styles from "./index.module.css";
import { useParams } from "react-router-dom";

export const AdminEvent = () => {
  const { t } = useTranslation();
  const [event, setEvent] = useState<Event | undefined>();
  const { eventId } = useParams();
  useEffect(() => {
    if (!eventId) return;
    (async () => {
      const response = await eventService.getEvent(eventId as string);
      setEvent(response.data);
    })();
  }, [eventId]);

  let settings = (
    <div className="center">
      <CircularProgress />
    </div>
  );
  if (event) {
    settings = (
      <div className={Styles.settingsContainer}>
        <EventSettings event={event} />
        <PriorityHosts event={event} />
      </div>
    );
  }

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("event.event_rooms")} />
        <Table eventId={event?.eventId as string} />
        <PlatformModuleHeader label={t("event.settings")} />
        {settings}
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
