import { GalacticWallpaper, PlatformLayout } from "hoc";
import { Header } from "./header";
import { Table } from "./table";

export const BillsView = () => {
  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <Header />
        <Table />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
