import { PlatformButton, PlatformModal } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryModal } from "../category-modal";
import Styles from "./index.module.css";

interface Props {
  loadCategories: () => Promise<void>;
}

export const AddCategories = ({ loadCategories }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const afterCompleteForm = async () => {
    await loadCategories();
    setIsOpen(false);
  };
  return (
    <>
      <div className={Styles.container}>
        <PlatformButton
          onClick={() => setIsOpen(true)}
          label={t("custom_categories.add_category")}
        />
      </div>
      <PlatformModal isOpen={isOpen}>
        <CategoryModal
          closeModal={() => setIsOpen(false)}
          afterCompleteForm={afterCompleteForm}
        />
      </PlatformModal>
    </>
  );
};
