import { NexusDatePicker, NexusSelect, PlatformButton } from "components";
import { ButtonType } from "enums";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { useController } from "./use-controller";
import { Table } from "./table";

export const ProtonsInPeriod = () => {
  const { t } = useTranslation();
  const { methods, onSubmit, protonTypeList, isLoading, protons } = useController();
  return (
    <div>
      <FormProvider {...methods}>
        <form
          className={Styles.container}
          onSubmit={methods.handleSubmit(onSubmit)}
          id="protons-per-period-form"
        >
          <NexusDatePicker label={t("events.start_date")} name="startDate" />
          <NexusDatePicker label={t("events.end_date")} name="endDate" />
          <NexusSelect
            label={t("proton_center.proton")}
            name="protonTypeId"
            items={protonTypeList}
          />
          <PlatformButton label={t("common.send")} type={ButtonType.SUBMIT} />
        </form>
        <Table isLoading={isLoading} protons={protons} />
      </FormProvider>
    </div>
  );
};
