import { CircularProgress } from "@mui/material";
import { GalacticWallpaper, GoToFeedButtonWrapper, NotActivePlan } from "hoc";
import { useUserPlan } from "hooks/use-user-plan";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export const OnlyAdminWrapper = ({ children }: Props) => {
  const userPlan = useUserPlan();
  if (!userPlan) {
    return (
      <GoToFeedButtonWrapper>
        <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
          <CircularProgress />
        </GalacticWallpaper>
      </GoToFeedButtonWrapper>
    );
  }
  if (!userPlan?.id) {
    return (
      <GoToFeedButtonWrapper>
        <NotActivePlan />
      </GoToFeedButtonWrapper>
    );
  }
  return <>{children}</>;
};
