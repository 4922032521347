import { CircularProgress } from "@mui/material";
import { PlatformBox, PlatformButton, PlatformTable, PlatformTableRow } from "components";
import { environment } from "environment";
import { RoomSummary } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routing } from "routing";
import { eventService } from "services";
import { getRoomUrl, shareLink } from "utils";
import Styles from "./index.module.css";

const titles = [
  "events.room_id",
  "event.members",
  "events.host",
  "event.people_in_room",
  "common.actions",
];

interface Props {
  eventId: string;
}

export const Table = ({ eventId }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [rooms, setIsRoom] = useState<Array<RoomSummary>>([]);
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    (async () => {
      if (!eventId) return;
      const response = await eventService.getRoomSummary(eventId);
      setIsRoom(response.data);
      setIsLoading(false);
    })();
  }, [eventId, time]);

  useEffect(() => {
    const MINUTES_3_TO_MS = 180000;
    const interval = setInterval(() => setTime(Date.now()), MINUTES_3_TO_MS);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = rooms.map(room => ({
    id: String(room.roomPin),
    columns: [
      {
        id: `pin_${room.roomPin}`,
        content: room.roomPin,
      },
      {
        id: `members_${room.roomPin}`,
        content: room.usernames.join(", "),
      },
      {
        id: `host_${room.roomPin}`,
        content: room.host,
      },
      {
        id: `amount_${room.roomPin}`,
        content: room.usernames.length + (room.host === "-" ? 0 : 1),
      },
      {
        id: `actions_${room.roomPin}`,
        content: (
          <div className={Styles.actions}>
            <PlatformButton
              onClick={() => shareLink(t, getRoomUrl(room.roomPin))}
              label={t("common.share_link")}
            />
          </div>
        ),
      },
    ],
  }));

  const peopleAtTheEvent = rooms.reduce(
    (current, room) => room.usernames.length + current + (room.host === "-" ? 0 : 1),
    0,
  );

  let shareButton = <CircularProgress />;

  if (eventId) {
    const link = `${environment.platformUrl}/${Routing.platformModule}/${Routing.platform.event}/${eventId}`;
    const copyLink = () => shareLink(t, link);
    shareButton = <PlatformButton label={t("event.share_event_link")} onClick={copyLink} />;
  }

  return (
    <>
      <PlatformTable
        className={`${Styles.tableContainer} neonScroll`}
        body={body}
        header={header}
        isLoading={isLoading}
      />
      <PlatformBox className={Styles.options}>
        <div className={Styles.leftOption}>
          <label>
            <label>
              <b>{t("event.people_in_event")}: </b>
            </label>
            <label>{peopleAtTheEvent}</label>
          </label>
        </div>
        <div className={Styles.rightOption}>{shareButton}</div>
      </PlatformBox>
    </>
  );
};
