import { CircularProgress, MenuItem } from "@mui/material";
import { PlatformButton, PlatformModal } from "components";
import GameTitle from "components/game-title/game-title";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Event } from "models";
import { eventService } from "services";

interface Props {
  event: Event;
  loadEvents: () => Promise<void>;
}

export const DeleteEvent = ({ event, loadEvents }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteEvent = async () => {
    setIsLoading(true);
    try {
      await eventService.deleteEvent(event.eventId);
      await loadEvents();
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    setIsOpen(false);
  };

  let buttons = (
    <>
      <PlatformButton
        isAlternative={true}
        onClick={() => setIsOpen(false)}
        label={t("common.cancel")}
      />
      <PlatformButton onClick={deleteEvent} label={t("events.delete_event")} />
    </>
  );

  if (isLoading) {
    buttons = <CircularProgress />;
  }

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>{t("events.delete_event")}</MenuItem>
      <PlatformModal isOpen={isOpen}>
        <GameTitle gameName={t("events.are_you_sure_you_want_to_delete")} />
        {buttons}
      </PlatformModal>
    </>
  );
};
