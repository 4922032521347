import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu, MenuItem } from "@mui/material";
import { Category } from "models";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Routing } from "routing";
import { GameID } from "utils";

interface Props {
  category: Category;
}

const games = [
  {
    id: GameID.NEVER_HAVE_I_EVER,
    label: "games.never_have_i_ever",
  },
  {
    id: GameID.THINK_FAST,
    label: "games.think_fast",
  },
  {
    id: GameID.MOST_LIKELY_TO,
    label: "games.most_likely_to",
  },
  {
    id: GameID.CHARADES,
    label: "games.charades",
  },
  {
    id: GameID.HYPOTETHICALLY,
    label: "games.hypotethically",
  },
  {
    id: GameID.DEEP_QUESTIONS,
    label: "games.deep_questions",
  },
  {
    id: GameID.DEBATE,
    label: "games.debate",
  },
  {
    id: GameID.TRIVIA,
    label: "games.trivia",
  },
];

export const ManageQuestionsActions = ({ category }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToCustomQuestions = (gameId: number) => () =>
    navigate(
      `/${Routing.platformModule}/${Routing.platform.customQuestions}/${gameId}/${category._id}`,
    );

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{ color: "white", background: "#283eaf", border: "1px solid #0c249d" }}
      >
        {t("custom_categories.select_a_game")}
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {games.map(({ id, label }) => (
          <MenuItem key={id} onClick={goToCustomQuestions(id)} disableRipple>
            {t(label)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
