import { YoutubePlayer } from "components";
import { GameInstructions } from "components/game-instructions";
import { useTranslation } from "react-i18next";

export const Instructions = () => {
  const { t } = useTranslation();
  return (
    <GameInstructions maxFails={10} gameName={t("games.who_am_i")}>
      <YoutubePlayer label="instructions" link="https://youtube.com/embed/wjXxQdkht2Y" />
    </GameInstructions>
  );
};
