import { PrivacyPolicy, TermsAndConditions } from "pages/legal";
import { Route, Routes } from "react-router-dom";

export const Legal = () => {
  return (
    <Routes>
      <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
      <Route path={"terms-and-conditions"} element={<TermsAndConditions />} />
    </Routes>
  );
};
