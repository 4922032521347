import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { anonymousFeedbackService } from "services";
import * as yup from "yup";
import { SelectUserFromOrganization } from "components/select-user-from-organization";
import { TypeFeedback } from "./type-feedback";

const initValues = {
  user: null,
  userSearch: "",
  feedback: "",
};

const title = "anonymous_feedback.who_is_going_to_receive_your_feedback";

interface Props {
  closeModal: () => void;
}

export const FeedbackForm = ({ closeModal }: Props) => {
  const { t } = useTranslation();
  const requiredMessage = t("common.is_required");

  const validationSchema = yup.object().shape({
    user: yup.object(),
    userSearch: yup.string(),
    feedback: yup.string().required(`${t("common.anonymous_feedback")} ${requiredMessage}`),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initValues,
  });

  const { user } = methods.watch();

  const onSubmit = async (values: object) => {
    const userId = get(values, "user.id");
    const feedback = get(values, "feedback");
    await anonymousFeedbackService.giveFeedback(userId, feedback);
    closeModal();
  };

  let step = <SelectUserFromOrganization title={title} closeModal={closeModal} />;

  if (user) {
    step = <TypeFeedback closeModal={closeModal} />;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        id="feedback-form"
        data-testid="feedback-form"
      >
        {step}
      </form>
    </FormProvider>
  );
};
