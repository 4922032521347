import { CircularProgress, TextField } from "@mui/material";
import { PlatformBox, PlatformButton } from "components";
import { Event } from "models";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { useController } from "./use-controller";

interface Props {
  event: Event;
}

export const PriorityHosts = ({ event }: Props) => {
  const {
    priorityHosts,
    isLoading,
    addNewPriorityHost,
    deletePriorityHost,
    updatePriorityHost,
    onSubmit,
  } = useController(event);
  const { t } = useTranslation();

  let button = <CircularProgress />;

  if (!isLoading) {
    button = <PlatformButton onClick={onSubmit} label={t("common.save")} />;
  }

  return (
    <PlatformBox className={`${Styles.boxContainer} neonScroll`}>
      <div className={Styles.container}>
        {priorityHosts.map((priorityHost, index) => (
          <div key={`${index}`} className={Styles.inputContainer}>
            <TextField
              className={Styles.input}
              label={`${t("event.priority_host_email")} ${index + 1}`}
              variant="outlined"
              value={priorityHost}
              onChange={updatePriorityHost(index)}
            />

            <div
              onClick={deletePriorityHost(index)}
              className={`${Styles.deleteButtonContainer} centerContent`}
            >
              <p>X</p>
            </div>
          </div>
        ))}
      </div>

      <div className="centerContent">
        <div onClick={addNewPriorityHost} className={`${Styles.addButtonContainer} centerContent`}>
          <p>+</p>
        </div>
      </div>

      <div className={Styles.buttonContainer}>{button}</div>
    </PlatformBox>
  );
};
