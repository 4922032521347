import { Clock } from "components/clock";
import { Carrousel } from "./carrousel";
import { NextButton } from "./next-button";
import { Question } from "./question";
import { SkipPlayerButton } from "components";

export const SayWord = () => {
  return (
    <>
      <SkipPlayerButton />
      <Question />
      <Carrousel />
      <Clock />
      <NextButton />
    </>
  );
};
