import { useCurrentLanguage } from "hooks";
import ReactPlayer from "react-player";
import Styles from "./index.module.css";

export const Trailer = () => {
  const currentLanguage = useCurrentLanguage();
  return (
    <section className="column">
      <div className={`${Styles.trailer}`}>
        <ReactPlayer
            url={`/assets/videos/intro_${currentLanguage}.mp4`}
            playing={false}
            controls={true}
            loop={true}
            muted={false}
            playsinline={true} 
          />
      </div>
    </section>
  );
};
