import { environment } from "environment";
import { get } from "utils";

class AnalyticsService {
  getFeelingsPerMonth(month: number | string, year: number | string) {
    return get(`${environment.analytics.feelingPerMonth}/${month}/${year}`);
  }

  getProtonsPerPeriod(startDate: Date, endDate: Date, protonTypeId: number) {
    const formatDate = (date: Date) => date.toISOString().split("T")[0];
    const parameters = [formatDate(startDate), formatDate(endDate), protonTypeId];
    return get(`${environment.analytics.protonsInPeriod}/${parameters.join("/")}`);
  }
}

export const analyticsService = new AnalyticsService();
