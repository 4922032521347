import { useCurrentLanguage, useUsernameFormatted } from "hooks";
import { useStoryUserIsTheSameUserInSession } from "hooks/story-user-is-the-same-user-in-session";
import { find } from "lodash";
import { useTranslation } from "react-i18next";
import { RootState, useShallowEqualSelector } from "store";

const Instructions = () => {
  const { t } = useTranslation();
  const { userForStoryTime, questionLanguages } = useShallowEqualSelector((state: RootState) => ({
    userForStoryTime: state.room.gameState?.userForStoryTime,
    questionLanguages: state.room.gameState?.question?.questionLanguages,
  }));
  const storyUserIsTheSameUserInSession = useStoryUserIsTheSameUserInSession();
  const userNameFormatted = useUsernameFormatted(userForStoryTime);
  const currentLanguage = useCurrentLanguage();
  const question = find(
    questionLanguages,
    questionLanguage => questionLanguage.languageId === currentLanguage,
  );

  if (storyUserIsTheSameUserInSession) {
    return (
      <>
        <h3 className="text">{t("charades.you_have_to_act_out_this_word")}</h3>
        <h2 className="text neon">{question?.label}</h2>
      </>
    );
  }

  return (
    <h3 className="text">
      {t("charades.you_have_to_guess_the_word_that")} {userNameFormatted}{" "}
      {t("charades.is_acting_out")}
    </h3>
  );
};

export default Instructions;
