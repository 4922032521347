import { Plan } from "enums";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useShallowEqualSelector, RootState } from "store";

interface Props {
  label: string;
  link: string;
  plans?: Array<Plan>;
}

export const Option = ({ label, link, plans }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { planId } = useShallowEqualSelector((state: RootState) => ({
    planId: state?.user?.plan?.id,
  }));
  
  const shouldHide = !!plans && !plans.includes(planId as number);
  if (shouldHide) return null;
  return <p onClick={() => navigate(link)}>{t(label)}</p>;
};
