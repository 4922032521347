import { PlatformMenu } from "components";
import { Event } from "models";
import { DeleteEvent } from "./delete-event";
import { GoToEvent } from "./go-to-event";

interface Props {
  event: Event;
  loadEvents: () => Promise<void>;
}

export const Actions = ({ event, loadEvents }: Props) => {
  return (
    <PlatformMenu>
      <GoToEvent event={event} />
      <DeleteEvent loadEvents={loadEvents} event={event} />
    </PlatformMenu>
  );
};
