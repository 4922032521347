import { NexusSelect } from "components";
import { useTranslation } from "react-i18next";

export const TranslateLanguagesHelper = () => {
  const { t } = useTranslation();
  const items = [
    { label: t("custom_categories.automatic"), value: 1 },
    { label: t("custom_categories.manual"), value: 0 },
  ];
  return (
    <div>
      <NexusSelect
        label={t(
          "custom_categories.select_the_languages_you_want_us_to_help_you_with_the_translation",
        )}
        name={"autotranslation"}
        items={items}
      />
    </div>
  );
};
