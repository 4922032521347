import { PlatformTable, PlatformTableRow } from "components";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

const titles = ["common.email", "proton_center.protons_count"];

interface Props {
  isLoading: boolean;
  protons: Array<{ email: string; amount: string }>;
}

export const Table = ({ isLoading, protons }: Props) => {
  const { t } = useTranslation();
  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = (protons || []).map(proton => {
    const id = String(proton.email);
    return {
      id,
      columns: [
        {
          id: `email_${id}`,
          content: proton.email,
        },
        {
          id: `count_${id}`,
          content: proton.amount,
        },
      ],
    };
  });
  return (
    <PlatformTable className={Styles.table} isLoading={isLoading} header={header} body={body} />
  );
};
