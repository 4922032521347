import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { useTranslation } from "react-i18next";
import { AddFeedback360 } from "./add-feedback-360";
import { useEffect, useState } from "react";
import { feedback360Service } from "services";
import { Feedbacks360 } from "./feedbacks-360";
import { FeedbackRequests360 } from "./feedback-requests-360";
import { CircularProgress } from "@mui/material";
import Styles from "./index.module.css";

export const Feedback360 = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackRequests, setFeedbackRequests] = useState([]);

  const loadFeedbacks = async () => {
    setIsLoading(true);
    try {
      const [feedbacksResponse, feedbackRequestsResponse] = await Promise.all([
        feedback360Service.loadFeedbacks(),
        feedback360Service.loadFeedbackRequests(),
      ]);
      setFeedbacks(feedbacksResponse.data);
      setFeedbackRequests(feedbackRequestsResponse.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadFeedbacks();
  }, []);

  let content = <CircularProgress />;

  if (!isLoading) {
    content = (
      <div className={Styles.feedbacksContainer}>
        <FeedbackRequests360 loadFeedbacks={loadFeedbacks} feedbackRequests={feedbackRequests} />
        <Feedbacks360 feedbacks={feedbacks} />
      </div>
    );
  }

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.feedback_360")} />
        <AddFeedback360 loadFeedbacks={loadFeedbacks} />
        {content}
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
