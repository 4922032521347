import NextButton from "components/next-button/next-button";
import VideoPlayers from "components/video-players/video-players";
import Results from "./results";
import { SkipPlayerButton } from "components";

const ShowResults = () => {
  return (
    <>
      <SkipPlayerButton />
      <VideoPlayers />
      <Results />
      <NextButton />
    </>
  );
};

export default ShowResults;
