import { NexusAutocomplete } from "components";
import { TRANSLATION_ES } from "lang/es";
import { keys } from "lodash";
import { useTranslation } from "react-i18next";

const countries = keys(TRANSLATION_ES.countries);

export const CountryInput = () => {
  const { t } = useTranslation();

  const options = countries.map(code => ({ id: code, label: t(`countries.${code}`)}));

  return (
    <NexusAutocomplete
      items={options}
      label={`${t("payment_methods.shippingCountry")}*`}
      name="shippingCountry"
    />
  );
};
