import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { useTranslation } from "react-i18next";
import { AnalyticsMetric } from "./components";

export const Analytics = () => {
  const { t } = useTranslation();
  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.analytics")} />
        <AnalyticsMetric />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
