import { PlatformBox, PlatformButton } from "components";
import { Feedback } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatOnlyDate } from "utils";
import { FeedbackResultsViewerWrapper } from "./feedback-results-viewer-wrapper";
import Styles from "./index.module.css";

interface Props {
  feedbacks: Array<Feedback>;
}

const hasPendingUsers = (feedback: Feedback) => {
  return Object.values(feedback.participants).filter(isCompleted => isCompleted).length > 0;
};

export const Feedbacks360 = ({ feedbacks }: Props) => {
  const { t } = useTranslation();
  const [currentFeedback, setCurrentFeedback] = useState<Feedback>();

  let content = <label className="neon">{t("feedback_360.no_feedbacks")}</label>;

  if (feedbacks.length) {
    content = (
      <>
        {feedbacks.map(feedback => (
          <div key={feedback._id} className={Styles.row}>
            <label className={Styles.createdAt}>{formatOnlyDate(feedback.createdAt, true)}</label>
            <div className={Styles.buttonContainer}>
              <PlatformButton
                className={Styles.button}
                label={t(
                  hasPendingUsers(feedback)
                    ? "feedback_360.waiting_for_feedback"
                    : "feedback_360.open_feedback",
                )}
                isDisabled={hasPendingUsers(feedback)}
                onClick={() => setCurrentFeedback(feedback)}
              />
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <PlatformBox>
      <label>{t("feedback_360.feedback_received")}</label>
      <div className={Styles.container}></div>
      {content}
      <FeedbackResultsViewerWrapper
        currentFeedbackId={currentFeedback?._id}
        setCurrentFeedback={(feedback: Feedback | undefined) => setCurrentFeedback(feedback)}
      />
    </PlatformBox>
  );
};
