import { Clock } from "components/clock";
import ShowUsersWithFocus from "components/show-users-with-focus/show-users-with-focus";
import { ItsYourTurnWrapper } from "hoc";
import { useTranslation } from "react-i18next";
import { BottomSection } from "./bottom-section";
import { Character } from "./character";
import { Clue } from "./clue";
import { SkipPlayerButton } from "components";

export const Presentation = () => {
  const { t } = useTranslation();
  return (
    <ItsYourTurnWrapper customMessage={t("who_am_i.its_your_turn")}>
      <SkipPlayerButton />
      <Character />
      <br />
      <ShowUsersWithFocus />
      <BottomSection />
      <Clock />
      <Clue />
    </ItsYourTurnWrapper>
  );
};
