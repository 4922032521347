import { ShowUserInStoryTime, SkipPlayerButton } from "components";
import { ItsYourTurnWrapper } from "hoc";
import { mostLikelyToService } from "services/most-likely-to.service";

const nextStep = (roomPin: string) => {
  mostLikelyToService.changeStoryTimeUser(roomPin);
};

const StoryTime = () => {
  return (
    <ItsYourTurnWrapper>
      <SkipPlayerButton />
      <ShowUserInStoryTime
        nextStep={nextStep}
        questionPrefix={""}
        questionSuffix={""}
        storyTimePrefix="most_likely_to.storyTimePrefix"
        storyTimeSuffix="most_likely_to.storyTimeSuffix"
      />
    </ItsYourTurnWrapper>
  );
};

export default StoryTime;
