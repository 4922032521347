import { MenuItem } from "@mui/material";
import { PlatformModal } from "components";
import { get } from "lodash";
import { UserLicenseAssignment } from "models";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { planService } from "services";
import { DesactivateWarning } from "../desactivate-warning";

interface Props {
  assignment: UserLicenseAssignment;
  loadUsers?: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void,
}

export const DesactivateAction = (props: Props) => {
  const { t } = useTranslation();
  const message = props.assignment.active ? t("common.inactivate") : t("common.activate");

  const updateStatus = async () => {
    const newState = !props.assignment.active;
    await planService.updateActiveUserLicenseAssignment(props.assignment.toUser.id, newState);
    props.handleClose();
    if (props.loadUsers) {
      await props.loadUsers();
    }
  };

  const changeAssignmentStatus = async () => {
    const isActive = props.assignment.active;
    if (isActive) {
      props.setIsOpen(true);
      return;
    }
    try {
      await updateStatus();
      return;
    } catch (e) {
      const errorMessage = get(e, "response.data.message");
      toast.error(t(errorMessage));
    }
  };

  return (
    <>
      <MenuItem onClick={changeAssignmentStatus}>{message}</MenuItem>
      <PlatformModal isOpen={props.isOpen}>
        <DesactivateWarning
          onClick={updateStatus}
          handleClose={props.handleClose}
          user={props.assignment.toUser}
        />
      </PlatformModal>
    </>
  );
};
