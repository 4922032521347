import { YoutubePlayer } from "components";
import { GameInstructions } from "components/game-instructions";
import { useTranslation } from "react-i18next";

const Instructions = () => {
  const { t } = useTranslation();
  return (
    <GameInstructions
      styles={{ maxHeight: "50vh" }}
      minPlayersAmount={3}
      gameName={t("games.most_likely_to")}
    >
      <YoutubePlayer label="instructions" link="https://youtube.com/embed/ugfZjgPLxJc" />
    </GameInstructions>
  );
};

export default Instructions;
