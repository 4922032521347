import { GameButton } from "components";
import AddUsersToPlanImg from "assets/icons/platform/add-users-to-plan.svg";
import Styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import { AddUsersPlanDialog } from "./add-users-plan-dialog";
import { useState } from "react";

interface Props {
  loadUserPlan: () => Promise<void>;
}

export const AddUsersToPlan = ({ loadUserPlan }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={Styles.buttonContainer}>
        <GameButton
          tooltip={t("user_plan_management.add_users_to_plan")}
          imageSrc={AddUsersToPlanImg}
          onClick={() => setIsOpen(true)}
        />
      </div>
      <AddUsersPlanDialog isOpen={isOpen} setIsOpen={setIsOpen} loadUserPlan={loadUserPlan} />
    </>
  );
};
