import { ButtonType } from "enums/button-type";
import { useEffect, useState } from "react";
import { isPromise, waitFor } from "utils";
import Styles from "./index.module.css";

interface Props {
  onClick?: () => void;
  label: string;
  isAlternative?: boolean;
  type?: ButtonType;
  isDisabled?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  className?: string;
}

export const PlatformButton = (props: Props) => {
  const [shouldWait, setShouldWait] = useState(false);
  const [isRender, setIsRender] = useState(true);

  useEffect(() => () => setIsRender(false), []);
  
  const className = props.isAlternative ? Styles.buttonAlternative : Styles.button;
  let action = props.onClick;
  if (props.isDisabled) {
    action = () => 0;
  }
  const onButtonClicked = async () => {
    if (shouldWait || !action) return;
    setShouldWait(true);
    if (isPromise(action)) {
      await action();
    } else {
      action();
      await waitFor(3 * 1000);
    }
    if (isRender) {
      setShouldWait(false);
    }
  };

  return (
    <button
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      type={props.type}
      className={`${className} ${props.className}`}
      onClick={onButtonClicked}
      disabled={props.isDisabled}
    >
      {props.label}
    </button>
  );
};
