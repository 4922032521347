import { CircularProgress } from "@mui/material";
import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { get, last } from "lodash";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { LoadMore } from "./load-more";
import { ProtonCard } from "./proton-card";
import { RightMenu } from "./right-menu";
import { useController } from "./use-controller";

export const ProtonCenter = () => {
  const {
    protons,
    addProton,
    hasMoreProtons,
    loading,
    setCursor,
    setAddProton,
    setSelectedType,
    loadInitialProtons,
  } = useController();
  const { t } = useTranslation();

  const loadMoreClick = () => {
    const newCursor = get(last(protons), "id", 0);
    setCursor(newCursor);
  };

  let content = (
    <div className="center">
      <CircularProgress />
    </div>
  );

  if (!loading) {
    if (protons.length > 0) {
      content = (
        <div className={`${Styles.displayContainer} `}>
          <section className={`${Styles.protonDisplay} neonScroll`}>
            {protons.map(singleProton => (
              <ProtonCard key={singleProton.id} singleProton={singleProton} />
            ))}
          </section>
        </div>
      );
    } else {
      content = (
        <div className="center">
          <label className="neon"> {<p>{t("proton_center.no_protons")}</p>}</label>
        </div>
      );
    }
  }

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("proton_center.proton_center")} />
        <RightMenu
          addProton={addProton}
          setAddProton={setAddProton}
          setSelectedType={setSelectedType}
          loadInitialProtons={loadInitialProtons}
        />
        {content}
        {hasMoreProtons && <LoadMore onClick={loadMoreClick} />}
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
