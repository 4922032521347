import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ExportTableToXLSXButton, PlatformTable, PlatformTableRow } from "components";
import { UserPlanTrackDay } from "models";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { planService } from "services";
import { formatDatetime } from "utils";
import Styles from "./index.module.css";

const titles = [
  "#",
  "common.date",
  "common.user",
  "common.room_type",
  "pay_as_you_go.room_name",
  "pay_as_you_go.room_owner",
  "pay_as_you_go.room_created_at",
];

export const Consumption = () => {
  const [consumption, setConsumption] = useState<Array<UserPlanTrackDay>>([]);
  const [isLoading, setLoading] = useState(false);
  const [monthDate, setMonthDate] = useState(moment());
  const { t } = useTranslation();

  const [month, year] = monthDate.format("MM/YYYY").split("/");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await planService.findUserPlanConsumptionByMonthAndYear(month, year);
      setConsumption(response.data);
      setLoading(false);
    })();
  }, [month, year]);

  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = (consumption || [])?.map((track, index) => {
    const id = String(`${track.date}_${track.userId}`);
    return {
      id,
      columns: [
        {
          id: `#_${id}`,
          content: index + 1,
        },
        {
          id: `date_${id}`,
          content: track.date,
        },
        {
          id: `user_${id}`,
          content: track.userEmail,
        },
        {
          id: `room_type_${id}`,
          content: t(`rooms.${track.type}`),
        },
        {
          id: `room_name_${id}`,
          content: track.roomName,
        },
        {
          id: `room_owner_${id}`,
          content: track.ownerUserEmail,
        },
        {
          id: `room_created_at_${id}`,
          content: formatDatetime(track.createdAt),
        },
      ],
    };
  });

  const xlsxFileName = `${t("pay_as_you_go.pay_as_you_go_consumption")}_${monthDate.format(
    "MM_YYYY",
  )}.xlsx`;

  return (
    <PlatformTable
      className={`${Styles.table} neonScroll`}
      body={body}
      header={header}
      isLoading={isLoading}
      topSection={
        <>
          <div className={Styles.topContainer}>
            <DatePicker
              value={monthDate}
              views={["month", "year"]}
              openTo="month"
              disableFuture={true}
              onChange={value => setMonthDate(value || moment())}
              renderInput={params => {
                return <TextField {...params} label={t("pay_as_you_go.month_and_year")} />;
              }}
              minDate={moment("2023-05-05T02:39:04.131Z")}
              inputFormat="MM/YYYY"
            />
            <div className={Styles.exportContainer}>
              <ExportTableToXLSXButton header={header} body={body} fileName={xlsxFileName} />
            </div>
          </div>
        </>
      }
    />
  );
};
