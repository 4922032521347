import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Divider, FormHelperText, Slider } from "@mui/material";
import { NexusInput, PlatformButton } from "components";
import GameTitle from "components/game-title/game-title";
import { ButtonType } from "enums";
import { useCurrentLanguage } from "hooks";
import { Feedback, Feedback360Question, FeedbackAnswer } from "models";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";
import { AnyObject } from "yup/lib/types";
import Styles from "./index.module.css";
import { compact } from "lodash";
import { feedback360Service } from "services";

interface Props {
  questions: Array<Feedback360Question>;
  onClose: () => Promise<void>;
  setIsOpen: (value: boolean) => void;
  feedback?: Feedback;
}

export const FeedbackForm = ({ questions, feedback, onClose, setIsOpen }: Props) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchemaObject: Record<
    string,
    | yup.StringSchema<string | undefined, AnyObject, string | undefined>
    | yup.NumberSchema<number | undefined, AnyObject, number | undefined>
  > = {};

  const initialValues: Record<string, string | number> = {};

  if (!feedback?._id) {
    validationSchemaObject.subordinate = yup.string().email(t("common.enter_a_valid_email"));
    validationSchemaObject.peer = yup.string().email(t("common.enter_a_valid_email"));
    validationSchemaObject.supervisor = yup.string().email(t("common.enter_a_valid_email"));

    initialValues.subordinate = "";
    initialValues.peer = "";
    initialValues.supervisor = "";
  }

  questions.forEach(question => {
    const pointsKey = `question_${question._id}_points`;
    const descriptionKey = `question_${question._id}_description`;

    validationSchemaObject[pointsKey] = yup
      .number()
      .moreThan(0, t("feedback_360.add_a_rating_please"));
    initialValues[pointsKey] = 0;

    validationSchemaObject[descriptionKey] = yup.string();
    initialValues[descriptionKey] = "";
  });

  const validationSchema = yup.object().shape(validationSchemaObject);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const errors = methods.formState.errors;

  const values = methods.watch();

  let buttonContainer = <CircularProgress />;

  if (!isLoading) {
    buttonContainer = (
      <>
        <PlatformButton
          type={ButtonType.BUTTON}
          onClick={() => setIsOpen(false)}
          label={t("common.cancel")}
        />
        <div className={Styles.sendButton}>
          <PlatformButton label={t("feedback_360.send_feedback_360")} />
        </div>
      </>
    );
  }

  let askFeedbackToOthers = null;

  if (!feedback?._id) {
    askFeedbackToOthers = (
      <div>
        <p>{t("feedback_360.feedback_suffix")}</p>
        <p>{t("feedback_360.feedback_suffix_2")}</p>
        <NexusInput label={t("feedback_360.supervisor")} name="supervisor" />
        <NexusInput label={t("feedback_360.peer")} name="peer" />
        <NexusInput label={t("feedback_360.subordinate")} name="subordinate" />
      </div>
    );
  }

  const onSubmit = async (formValues: Record<string, unknown>) => {
    const { subordinate, peer, supervisor } = formValues;
    const hasAllMissing = !subordinate && !peer && !supervisor;
    if (hasAllMissing && !feedback?._id) {
      toast.warning(t("feedback_360.at_least_somebody_else"));
      return;
    }
    setIsLoading(true);
    try {
      const answers: Array<FeedbackAnswer> = questions.map(question => ({
        questionId: question._id,
        score: formValues[`question_${question._id}_points`] as number,
        comment: formValues[`question_${question._id}_description`] as string,
      }));
      if (feedback?._id) {
        await feedback360Service.giveFeedback(feedback?._id, answers);
      } else {
        const emails = compact([subordinate, peer, supervisor]) as Array<string>;
        await feedback360Service.createFeedback(emails, answers);
      }
      await onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    toast.error(t("feedback_360.error_in_form"));
  };

  const title = feedback?._id
    ? `${t("feedback_360.answer_the_following_questions_regarding_the_performance_of")} ${
        feedback?.email
      }`
    : t("feedback_360.answer_the_following_questions_for_yourself");

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit, onError)}
        id="feedback-form"
        data-testid="feedback-form"
        className={`${Styles.container} neonScroll`}
      >
        <GameTitle gameName={t("menu.feedback_360")} />
        <label>{title}:</label>
        <div className={Styles.divider}>
          <Divider />
        </div>
        <div className={Styles.questionsContainer}>
          {questions.map(question => (
            <div className={Styles.sectionContainer} key={question._id}>
              <div className={Styles.questionContainer}>
                <label key={question._id}>
                  {
                    question?.feedbackLanguages?.find(
                      ({ languageId }) => languageId === currentLanguage,
                    )?.label
                  }
                </label>
                <div>
                  <Slider
                    style={{ color: "#b800e6" }}
                    valueLabelDisplay="off"
                    marks={true}
                    min={1}
                    max={5}
                    step={1}
                    value={values[`question_${question._id}_points`] as number}
                    onChange={(_, value) =>
                      methods.setValue(`question_${question._id}_points`, value as number)
                    }
                  />
                  {(values[`question_${question._id}_points`] as number) < 1 ? (
                    <FormHelperText error={true}>
                      {errors[`question_${question._id}_points`]?.message}
                    </FormHelperText>
                  ) : null}
                </div>

                <label className={Styles.questionPoints}>
                  {values[`question_${question._id}_points`]}
                </label>
              </div>
              <NexusInput
                label={t("feedback_360.any_comments_you_want_to_add")}
                name={`question_${question._id}_description`}
                multiline
                rows={2}
              />
              <div className={Styles.divider}>
                <Divider />
              </div>
            </div>
          ))}
        </div>
        {askFeedbackToOthers}
        <div className={Styles.buttonsContainer}>{buttonContainer}</div>
      </form>
    </FormProvider>
  );
};
