import { yupResolver } from "@hookform/resolvers/yup";
import { Slider } from "@mui/material";
import { HelpTooltip, NexusInput, PlatformBox, PlatformButton } from "components";
import { ButtonType } from "enums/button-type";
import { Event } from "models";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { eventService } from "services";
import { formatOnlyDate } from "utils";
import * as yup from "yup";
import Styles from "./index.module.css";

interface Props {
  event: Event;
}

export const EventSettings = ({ event }: Props) => {
  const { t } = useTranslation();
  const { eventId } = useParams();
  
  const requiredMessage = t("common.is_required");

  const validationSchema = yup.object().shape({
    maxUsersPerRoom: yup.string().required(`${t("event.max_users_per_room")} ${requiredMessage}`),
  });
  
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      maxUsersPerRoom: event?.maxUsersPerRoom,
      linkAfterRoom: event?.linkAfterRoom,
    },
  });

  const formValues = methods.watch();

  const onSubmit = async (values: Partial<Event>) => {
    await eventService.updateEvent(eventId as string, values);
    toast.success(t("event.event_updated"));
  };

  const onError = (errors: unknown, e: unknown) => console.error(errors, e);

  return (
    <PlatformBox className={`${Styles.boxContainer} neonScroll`}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit, onError)}
          id="event-settings-form"
          data-testid="event-settings-form"
        >
          <div className={Styles.formContainer}>
            <div className={Styles.maxAmountUsersPerRoomContainer}>
              <div className={Styles.maxAmountUsersPerRoom}>
                <label>
                  {t("event.max_users_per_room")}:{formValues.maxUsersPerRoom}
                  <HelpTooltip help={t("event.max_users_per_room_help")} />
                </label>
                <Slider
                  style={{ color: "#b800e6", marginTop: "20px" }}
                  valueLabelDisplay="auto"
                  marks={true}
                  min={2}
                  max={10}
                  step={1}
                  value={formValues.maxUsersPerRoom}
                  onChange={(_, value) => methods.setValue("maxUsersPerRoom", value as number)}
                />
              </div>
            </div>
            <div>
              <NexusInput
                label={`${t("rooms.link_after_room")}`}
                name="linkAfterRoom"
                variant="outlined"
              />
            </div>
            <div>
              <p>
                {t("event.expiration_date")}
                <HelpTooltip help={t("event.expiration_date_help")} />
              </p>
              <p>{formatOnlyDate(event.expirationAt)}</p>
            </div>
            <div className={Styles.buttonContainer}>
              <PlatformButton label={t("common.save")} type={ButtonType.SUBMIT} />
            </div>
          </div>
        </form>
      </FormProvider>
    </PlatformBox>
  );
};
