import { PlatformModal } from "components";
import React from "react";
import { GiveProton } from "./give-proton";
import AddIcon from 'assets/icons/misc/add.svg';
import Styles from "./index.module.css";

interface Props {
  addProton: boolean;
  setAddProton: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedType: React.Dispatch<React.SetStateAction<boolean>>;
  loadInitialProtons: () => Promise<void>;
}

export const RightMenu = ({
  addProton,
  setAddProton,
  setSelectedType,
  loadInitialProtons,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const openAddProtonModal = () => {
    setAddProton(!addProton);
    setSelectedType(false);
    setIsOpen(true);
  };

  return (
    <>
      <button className={Styles.rightMenuPlusButton} onClick={openAddProtonModal}>
        <img alt="Give proton" src={AddIcon} />
      </button>
      <PlatformModal isOpen={isOpen}>
        <GiveProton closeModal={() => setIsOpen(false)} loadInitialProtons={loadInitialProtons} />
      </PlatformModal>
    </>
  );
};
