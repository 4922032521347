import { CircularProgress } from "@mui/material";
import { GameButton } from "components";
import NeonBox from "components/neon-box/neon-box";
import { GalacticColors } from "enums";
import { GalacticWallpaper } from "hoc";
import { t } from "i18next";
import { Room } from "models";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { roomService } from "services";
import { RootState, useShallowEqualSelector } from "store";
import { goToEvent, goToFeed } from "utils";

interface Props {
  children: ReactNode;
}
export const RoomFullGuard = ({ children }: Props) => {
  const [isRoomFull, setIsRoomFull] = useState<boolean | undefined>();
  const [eventId, setEventId] = useState('');
  const { userId } = useShallowEqualSelector((state: RootState) => ({
    userId: state.user.id,
  }));
  const { pin } = useParams();
  useEffect(() => {
    (async () => {
      try {
        const response = await roomService.findRoomByPin(pin);
        const room: Room = response.data;
        if (!room.pin) {
          goToFeed();
        }
        const isRoomFullOfUsers =
          room.eventId && room.isStrict && room.usersIdsOrder.length >= room.maxUsersPerRoom;
        const isRoomOwnedByUser = room.ownerUserId === userId;
        const shouldAvoidUserLogin = !!isRoomFullOfUsers && !isRoomOwnedByUser;
        setEventId(room.eventId);
        setIsRoomFull(shouldAvoidUserLogin);
      } catch (e) {
        goToFeed();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRoomFull === undefined) {
    return (
      <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
        <CircularProgress />
      </GalacticWallpaper>
    );
  }
  if (isRoomFull) {
    return (
      <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
        <NeonBox>
          <h2 className="subtitle">{t("room.is_full")}</h2>
          <div className="center">
            <GameButton
              backgroundImage={GalacticColors.BLUE}
              label={t("room.go_to_another_room")}
              onClick={goToEvent(eventId)}
            />
          </div>
        </NeonBox>
      </GalacticWallpaper>
    );
  }

  return <>{children}</>;
};
