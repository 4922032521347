import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  onClick: () => void;
}

export const LoadMore = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <section className={Styles.loadMore}>
      <button className={Styles.loadMoreButton} onClick={onClick}>
        {t("proton_center.load_more")}
      </button>
    </section>
  );
};
