import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { t } from "i18next";
import { AddFeedback } from "./add-feedback";
import { Feedbacks } from "./feedbacks";

export const AnonymousFeedback = () => {
  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.anonymous_feedback")} />
        <AddFeedback />
        <Feedbacks />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
