import { NexusInput } from "components";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  language: string;
}

export const CategoryForm = ({ language }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <NexusInput
        className={Styles.input}
        name={`name_${language}`}
        label={`${t("custom_categories.type_the_category_name_in")} ${t(
          `languages.${language}`,
        ).toLowerCase()}`}
      />
    </div>
  );
};
