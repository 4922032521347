import { languageResources } from "lang/languages";
import { TextTranslation } from "models";
import { languageService } from "services";

const languages = Object.keys(languageResources);

const getFieldsList = (formValues: Record<string, unknown>) => {
  const fields = new Set(
    Object.keys(formValues).map(field => {
      const fieldName = field.split("_");
      if (fieldName.length === 1) {
        return field;
      }
      fieldName.pop();
      return fieldName.join("_");
    }),
  );
  fields.delete("autotranslation");
  return Array.from(fields);
};

export const translateJSON = async (
  formValues: Record<string, unknown>,
  defaultLanguage: string,
) => {
  const fields = getFieldsList(formValues);
  let translations: Array<TextTranslation> = [];
  for (const field of fields) {
    for (const language of languages) {
      if (language === defaultLanguage) continue;
      translations.push({
        id: `${field}_${language}`,
        text: formValues[`${field}_${defaultLanguage}`] as string,
        translatedText: "",
        to: language,
        from: defaultLanguage,
      });
    }
  }
  const response = await languageService.translateTexts(translations);
  translations = response.data as Array<TextTranslation>;
  for (const field of translations) {
    formValues[field.id] = field.translatedText;
  }

  return formValues;
};
