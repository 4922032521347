import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LandingPage } from "pages/landing";
import { InvalidEmail } from "pages/platform";
import LoginPage from "pages/platform/login/login.page";
import PricingPage from "pages/pricing/pricing.page";
import { Route, Routes } from "react-router-dom";
import { Legal } from "./legal";
import Platform from "./platform";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const Routing = {
  landing: "/",
  pricing: "pricing",
  login: "login",
  checkEmail: "check-email",
  platformModule: "platform",
  platform: {
    root: "platform/*",
    rooms: "rooms",
    room: "room",
    adminEvent: "admin/event",
    event: "event",
    events: "events",
    paymentMethods: "payment-methods",
    slotsManagement: "slots-management",
    payAsYouGoPlan: "pay-as-you-go-plan",
    userPlanManagement: "user-plan-management",
    account: "account",
    customCategories: "custom-categories",
    bills: "bills",
    customQuestions: "custom-questions",
    anonymousFeedback: "anonymous-feedback",
    learningCenter: "learning-center",
    protonCenter: "proton-center",
    analytics: "analytics",
    feedback360: "feedback-360",
  },
};

export const RoutingConfiguration = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="*" element={<LandingPage />} />
        <Route path={Routing.landing} element={<LandingPage />} />
        <Route path={Routing.pricing} element={<PricingPage />} />
        <Route path={Routing.login} element={<LoginPage />} />
        <Route path={Routing.checkEmail} element={<InvalidEmail />} />
        <Route path="platform/*" element={<Platform />} />
        <Route path="legal/*" element={<Legal />} />
      </Routes>
    </ThemeProvider>
  );
};
