interface Props {
  link: string;
  label: string;
}

const options = [
  "modestbranding=1",
  "playsinline=1",
  "rel=0",
  "widget_referrer=platform",
  "iv_load_policy=3",
  "fs=1",
];

export const YoutubePlayer = ({ link, label }: Props) => {
  return <iframe width="1243" height="699" src={`${link}?${options.join("&")}`} title={label} />;
};
