import { yupResolver } from "@hookform/resolvers/yup";
import { useCurrentLanguage } from "hooks";
import { get } from "lodash";
import { ProtonType } from "models";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { analyticsService } from "services";
import { protonsService } from "services/protons.service";
import * as yup from "yup";

const initialValues = {
  startDate: moment().add(-1, "month").toDate(),
  endDate: new Date(),
  protonTypeId: 0,
};
export const useController = () => {
  const { t } = useTranslation();
  const [protonTypes, setProtonTypes] = useState<Array<ProtonType>>([]);
  const [protons, setProtons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const requiredMessage = t("common.is_required");
  const currentLanguage = useCurrentLanguage();

  const protonTypeList = useMemo(() => {
    const protonList = protonTypes.map(protonTypeValue => ({
      value: protonTypeValue?.id,
      label: protonTypeValue?.protonTypeLanguages?.find(
        ({ languageId }) => languageId === currentLanguage,
      )?.name as string,
    }));
    return [{ value: 0, label: t("proton_center.no_proton") }, ...protonList];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protonTypes, currentLanguage]);

  const validationSchema = yup.object().shape({
    startDate: yup.date().required(`${t("events.start_date")} ${requiredMessage}`),
    endDate: yup.date().required(`${t("events.end_date")} ${requiredMessage}`),
    protonTypeId: yup.number(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const onSubmit = async (values: unknown) => {
    const startDate = get(values, "startDate");
    const endDate = get(values, "endDate");
    const protonTypeId = get(values, "protonTypeId");
    try {
      setIsLoading(true);
      const response = await analyticsService.getProtonsPerPeriod(startDate, endDate, protonTypeId);
      setProtons(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await protonsService.getProtonTypes();
      setProtonTypes(response.data);
    })();
  }, []);

  return {
    methods,
    protonTypeList,
    protons,
    isLoading,
    onSubmit,
  };
};
