import { CircularProgress } from "@mui/material";
import { PlatformBox } from "components";
import { UserPlan } from "models";
import { useTranslation } from "react-i18next";
import { formatOnlyDate } from "utils";
import Styles from "./index.module.css";

interface Props {
  userPlan: UserPlan;
}

export const PlanOverview = ({ userPlan }: Props) => {
  const { t } = useTranslation();
  if (!userPlan) return <CircularProgress />;
  return (
    <PlatformBox className={Styles.boxContainer}>
      <div className={Styles.container}>
        <div>
          <p>{t("billing.expiration_date")}</p>
          <label>{formatOnlyDate(userPlan?.activeUntil, true)}</label>
        </div>
        <div>
          <p>{t("slots_management.current_plan")}</p>
          <label>
            {userPlan?.currentSlots} {t("slots_management.slots")}
          </label>
        </div>
        <div>
          <p>{t("slots_management.renovation_plan")}</p>
          <label>
            {userPlan?.defaultSlotsRenovation} {t("slots_management.slots")}
          </label>
        </div>
        <div>
          <p>{t("slots_management.available_slots")}</p>
          <label>
            {userPlan?.maxTotalAccounts} {t("slots_management.slots")}
          </label>
        </div>
      </div>
    </PlatformBox>
  );
};
