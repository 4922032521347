import { languageResources } from "lang/languages";
import { TFunction } from "react-i18next";
import * as yup from "yup";
import { optionFields } from "../constants";

const languages = Object.keys(languageResources);

const validations: Record<string, yup.StringSchema | yup.NumberSchema | yup.BooleanSchema> = {
  autotranslation: yup.number(),
};

const fields = ["question", ...optionFields];

export const buildValidations = (
  t: TFunction<"translation", undefined>,
  requiredMessage: string,
  currentLanguage: string,
) => {
  languages.forEach(language => {
    let validationSchema = yup.string();
    const prefix = `${t("custom_questions.type")}`;
    const suffix = `${t("custom_questions.in")} ${t(
      `languages.${language}`,
    ).toLowerCase()} ${requiredMessage}`;

    for (const field of fields) {
      const requireFieldMessage = `${prefix} ${field} ${suffix}`;
      if (language === currentLanguage) {
        validationSchema = validationSchema.required(requireFieldMessage);
      } else {
        validationSchema = validationSchema.when("autotranslation", {
          is: 0,
          then: yup.string().required(requireFieldMessage),
        });
      }
      validations[`${field}_${language}`] = validationSchema;
    }
  });
  return yup.object().shape(validations);
};
