import { ReactNode } from "react";
import Styles from "./index.module.css";

interface Props {
  children: ReactNode;
  className?: string;
}

export const PlatformBox = (props: Props) => {
  return <div className={`${Styles.container} ${props.className}`}>{props.children}</div>;
};
