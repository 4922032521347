import { RootState, useShallowEqualSelector } from "store";
import { formatUsername } from "utils";

export const useUsernameFormatted = (userId?: number) => {
  const { usersDetails } = useShallowEqualSelector((state: RootState) => ({
    usersDetails: state.room.usersDetails,
  }));
  if (!userId) return "";
  const userDetail = usersDetails[String(userId)];
  const userName = userDetail?.name;
  return formatUsername(userName);
};
