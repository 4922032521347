import { languageResources } from "lang/languages";
import { TFunction } from "react-i18next";
import * as yup from "yup";

const languages = Object.keys(languageResources);

const validations: Record<string, yup.StringSchema | yup.NumberSchema | yup.BooleanSchema> = {
  autotranslation: yup.number(),
};

export const buildValidations = (
  t: TFunction<"translation", undefined>,
  requiredMessage: string,
  currentLanguage: string,
) => {
  languages.forEach(language => {
    let validationSchema = yup.string();
    const label = `${t("custom_questions.type")} ${t("custom_questions.the_question")} ${t(
      "custom_questions.in",
    )} ${t(`languages.${language}`).toLowerCase()}`;
    const requireFieldMessage = `${label} ${requiredMessage}`;
    if (language === currentLanguage) {
      validationSchema = validationSchema.required(requireFieldMessage);
    } else {
      validationSchema = validationSchema.when("autotranslation", {
        is: 0,
        then: yup.string().required(requireFieldMessage),
      });
    }
    validations[`question_${language}`] = validationSchema;
  });
  return yup.object().shape(validations);
};
