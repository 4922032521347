import { ScoreTable } from "components";
import { Clock } from "components/clock";
import GameTitle from "components/game-title/game-title";
import { useTranslation } from "react-i18next";

export const GlobalUsersScores = () => {
  const { t } = useTranslation();
  return (
    <>
      <Clock />
      <GameTitle gameName={t("room.final_score_table")} />;
      <ScoreTable orderBy="desc" />
    </>
  );
};
