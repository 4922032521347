import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { useTranslation } from "react-i18next";
import { EventsList } from "./events-list";

export const Events = () => {
  const { t } = useTranslation();

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.events")} />
        <EventsList />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
