import { Slider } from "@mui/material";
import { PlatformButton, PlatformModal } from "components";
import GameTitle from "components/game-title/game-title";
import { pricingPlans } from "consts";
import { UserPlan } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { OpenPayU } from "./open-payu";

interface Props {
  userPlan?: UserPlan;
}

export const UpdateCurrentPlan = ({ userPlan }: Props) => {
  const currentSlots = userPlan?.currentSlots || 0;
  const optionToUpdateThePlan = pricingPlans.filter(e => e.value > currentSlots);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [slots, setSlots] = useState(optionToUpdateThePlan[0].value);
  const [isLoading, setIsLoading] = useState(false);

  let buttonsContainer = (
    <div className={Styles.buttonContainer}>
      <PlatformButton label={t("common.cancel")} onClick={() => setIsOpen(false)} />
      <PlatformButton
        label={t("slots_management.update_current_plan")}
        onClick={() => setIsLoading(true)}
      />
    </div>
  );

  if (isLoading) {
    const accountsToAdd = slots - currentSlots;
    buttonsContainer = <OpenPayU newPlanAccounts={slots} accountsToAdd={accountsToAdd} />;
  }

  return (
    <>
      <PlatformButton
        onClick={() => setIsOpen(true)}
        label={t("slots_management.update_current_plan")}
      />
      <PlatformModal isOpen={isOpen}>
        <GameTitle gameName={t("slots_management.update_current_plan")} />
        <div>
          <div className="center">
            <label className={Styles.slots}>{slots}</label>
            <label className={Styles.suffix}>{t("slots_management.slots")}</label>
          </div>

          <Slider
            min={optionToUpdateThePlan[0].value}
            max={optionToUpdateThePlan[optionToUpdateThePlan.length - 1].value}
            step={null}
            value={slots}
            onChange={(_, value) => setSlots(value as number)}
            valueLabelDisplay="off"
            marks={optionToUpdateThePlan}
          />
        </div>
        {buttonsContainer}
      </PlatformModal>
    </>
  );
};
