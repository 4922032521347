import { yupResolver } from "@hookform/resolvers/yup";
import { SelectUserFromOrganization } from "components/select-user-from-organization";
import { get } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { protonsService } from "services/protons.service";
import * as yup from "yup";
import { SelectProtonType } from "./select-proton-type";
import { SendProton } from "./send-proton";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const initValues = {
  protonType: undefined,
  userSearch: "",
  description: "",
  user: undefined,
};

interface Props {
  closeModal: () => void;
  loadInitialProtons: () => Promise<void>;
}

export const GiveProton = ({ closeModal, loadInitialProtons }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    protonType: yup.object().required(),
    userSearch: yup.string(),
    description: yup.string().required(),
    user: yup.object().required(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initValues,
  });

  const { user, protonType } = methods.watch();

  const onSubmit = async (values: object) => {
    setIsLoading(true);
    try {
      const userSelected = get(values, "user.id");
      const descriptionGiven = get(values, "description");
      const protonTypeSelected = get(values, "protonType");
      await protonsService.giveProton(userSelected, descriptionGiven, protonTypeSelected);
      await loadInitialProtons();
      closeModal();
    } finally {
      setIsLoading(false);
    }
  };

  let step = (
    <SelectUserFromOrganization
      title={t("proton_center.select_user_title")}
      closeModal={closeModal}
    />
  );

  if (user) {
    step = <SelectProtonType closeModal={closeModal} />;
  }

  if (protonType) {
    step = <SendProton closeModal={closeModal} isLoading={isLoading} />;
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        id="feedback-form"
        data-testid="feedback-form"
      >
        {step}
      </form>
    </FormProvider>
  );
};
