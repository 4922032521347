import { yupResolver } from "@hookform/resolvers/yup";
import { NexusInput, PlatformBox, PlatformButton } from "components";
import { ButtonType } from "enums";
import { Plan } from "models";
import { FormProvider, get, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { planService } from "services";
import * as yup from "yup";
import Styles from "./index.module.css";

interface Props {
  plan: Plan;
}

export const Form = ({ plan }: Props) => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    limit: yup
      .number()
      .min(
        plan.payAsYouGoCount as number,
        `${t("common.the_field")} '${t("pay_as_you_go.plan_limit")}' ${t(
          "common.must_be_greater_than_or_equal_to",
        )} ${plan.payAsYouGoCount}`,
      ),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      limit: plan.payAsYouGoLimit,
      consumption: plan.payAsYouGoCount,
    },
  });

  const onSubmit = async (values: unknown) => {
    try {
      await planService.updateUserPlan((values as { limit: number }).limit);
      toast.success(t("pay_as_you_go.plan_updated"));
    } catch (e: unknown) {
      const message = get(e, "response.data.message");
      toast.error(t(message));
    }
  };

  const onError = (errors: unknown, e: unknown) => console.error(errors, e);

  return (
    <PlatformBox>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit, onError)}
          id="contact-form"
          data-testid="contact-form"
          className={Styles.container}
        >
          <NexusInput
            label={`${t("pay_as_you_go.plan_limit")}`}
            name="limit"
            type="number"
            variant="standard"
          />
          <NexusInput
            label={`${t("pay_as_you_go.consumption")}`}
            name="consumption"
            variant="standard"
            disabled={true}
            type="number"
          />
          <div></div>
          <div className={Styles.buttonContainer}>
            <PlatformButton label={t("common.save")} type={ButtonType.SUBMIT} />
          </div>
        </form>
      </FormProvider>
    </PlatformBox>
  );
};
