import GameTitle from "components/game-title/game-title";
import { useCurrentLanguage } from "hooks";
import { find } from "lodash";
import { Game } from "models";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { gameService } from "services/game.service";
import { RootState, useShallowEqualSelector } from "store";
import { GoBackButton } from "./components";
import { getGameWithCategories } from "./utils/get-game-with-categories";

interface Props {
  children?: React.ReactNode;
}

export const GameWrapper = (props: Props) => {
  const { gameId, games, currentGame } = useShallowEqualSelector((state: RootState) => ({
    gameId: state.room.gameId,
    games: state.game.games,
    currentGame: state.game.game,
  }));
  const currentLanguage = useCurrentLanguage();
  const gameName = find(currentGame?.gameLanguages, gl => gl.languageId === currentLanguage);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!gameId) return;

    let game = find(games, _game => _game.id === gameId);

    if (game) {
      (async () => {
        await getGameWithCategories(game as Game, dispatch);
      })();
    } else {
      (async () => {
        const response = await gameService.findGameWithDetails(gameId);
        game = response.data;
        await getGameWithCategories(game as Game, dispatch);
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  return (
    <>
      <GoBackButton />
      <section>
        <GameTitle gameName={gameName?.label as string} />
        <section>{props.children}</section>
      </section>
    </>
  );
};
