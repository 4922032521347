import { RoomType } from "enums";
import { environment } from "environment";
import { Event } from "models";
import { deleteHttp, get, patch, post } from "utils";

class EventService {
  createEvent(eventName: string, startDate: Date, roomType: RoomType, linkAfterRoom: string) {
    return post(`${environment.rooms.event}`, {
      eventName,
      startDate,
      roomType,
      linkAfterRoom,
    });
  }

  getEvent(eventId: string) {
    return get(`${environment.rooms.event}/${eventId}`);
  }

  getEvents() {
    return get(`${environment.rooms.events}`);
  }

  getRoomSummary(eventId: string) {
    return get(`${environment.rooms.eventRoomSummary}/${eventId}`);
  }

  getEventRoom(eventId: string) {
    return get(`${environment.rooms.room}/${eventId}`);
  }

  getRoomFromShuffle(eventId: string, roomId: string) {
    return post(`${environment.rooms.event}/${eventId}/shuffle`, { pin: roomId });
  }

  updateEvent(eventId: string, values: Partial<Event>) {
    return patch(`${environment.rooms.event}/${eventId}`, values);
  }

  updatePriorityHostsInEvent(eventId: string, priorityHosts: Array<string>) {
    return patch(`${environment.rooms.event}/${eventId}/update-priority-hosts`, { priorityHosts });
  }

  deleteEvent(eventId: string) {
    return deleteHttp(`${environment.rooms.event}/${eventId}`);
  }
}

export const eventService = new EventService();
