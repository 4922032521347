import ShuffleIcon from "assets/icons/platform/shuffle.svg";
import { GameButton } from "components";
import { useStoryUserIsTheSameUserInSession } from "hooks/story-user-is-the-same-user-in-session";
import { useTranslation } from "react-i18next";
import { eventService } from "services";
import { RootState, useShallowEqualSelector } from "store";
import { goToEvent, goToRoom } from "utils";
import Styles from "./index.module.css";

export const ShuffleRoomButton = () => {
  const { t } = useTranslation();
  const isStoryTimeUser = useStoryUserIsTheSameUserInSession();
  const { eventId, roomPin } = useShallowEqualSelector((state: RootState) => ({
    eventId: state.room.eventId,
    roomPin: state.room.pin,
  }));

  if (!roomPin) {
    return null;
  }

  if (!eventId) {
    return null;
  }
  const onClick = async () => {
    const response = await eventService.getRoomFromShuffle(eventId, roomPin);
    const pin = response.data.pin;
    if (pin) {
      goToRoom(pin)();
    } else {
      goToEvent(eventId)();
    }
  }

  return (
    <div className={Styles.suffleButton}>
      <GameButton
        imageSrc={ShuffleIcon}
        tooltip={`${t("room.go_to_another_room")}`}
        isDisabled={isStoryTimeUser}
        onClick={onClick}
      />
    </div>
  );
};
