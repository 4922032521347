import { languageResources } from "lang/languages";
import { Question } from "models";
import { questionService } from "services";
import { prepareFields } from "utils";

const languages = Object.keys(languageResources);

const options = [
  {
    isPositive: true,
    id: 1,
    optionLanguages: [
      { languageId: "en", label: "Yes, I Have" },
      { languageId: "es", label: "Si lo he hecho" },
      { languageId: "pt", label: "Se já aconteceu comigo" },
    ],
  },
  {
    isPositive: false,
    id: 2,
    optionLanguages: [
      { languageId: "en", label: "No, I haven't" },
      { languageId: "es", label: "No lo he hecho" },
      { languageId: "pt", label: "Não aconteceu comigo" },
    ],
  },
];

export const onSubmit =
  (
    currentLanguage: string,
    setIsLoading: (value: boolean) => void,
    afterCompleteForm: () => Promise<void>,
    categoryId: string,
    gameId: number,
    questionId?: string,
  ) =>
  async (formValues: object) => {
    setIsLoading(true);
    const fields = await prepareFields(formValues, currentLanguage);

    const categoryLanguages: Partial<Question> = {
      categories: {
        [categoryId]: 1,
      },
      options,
      questionLanguages: languages.map(languageId => ({
        languageId,
        label: fields[`question_${languageId}`] as string,
      })),
    };

    if (questionId) {
      await questionService.editQuestion(gameId, questionId, categoryLanguages);
    } else {
      await questionService.createQuestion(gameId, categoryLanguages);
    }
    setIsLoading(false);
    await afterCompleteForm();
  };
