import { PlatformButton, PlatformModal } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FeedbackFormWrapper } from "../feedback-form-wrapper";
import Styles from "./index.module.css";

interface Props {
  loadFeedbacks: () => Promise<void>;
}

export const AddFeedback360 = ({ loadFeedbacks }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const onClose = async () => {
    setIsOpen(false);
    await loadFeedbacks();
  };

  return (
    <div className={Styles.container}>
      <PlatformButton
        onClick={() => setIsOpen(true)}
        label={t("feedback_360.start_feedback_360_process")}
      />
      <PlatformModal isOpen={isOpen}>
        <FeedbackFormWrapper onClose={onClose} setIsOpen={setIsOpen} />
      </PlatformModal>
    </div>
  );
};
