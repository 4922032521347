import { YoutubePlayer } from "components";
import { GameInstructions } from "components/game-instructions";
import { useTranslation } from "react-i18next";

export const Instructions = () => {
  const { t } = useTranslation();
  return (
    <GameInstructions
      styles={{ maxHeight: "60vh" }}
      gameName={t("games.a_lie")}
      hideCategories={true}
    >
      <YoutubePlayer label="instructions" link="https://youtube.com/embed/2KKAezRFHt0" />
    </GameInstructions>
  );
};
