import MoreIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Menu } from "@mui/material";
import { UserLicenseAssignment } from "models";
import { useState } from "react";
import { ChangeAdminAction } from "./change-admin-action";
import { ChangeHostAction } from "./change-host-action";
import { DesactivateAction } from "./desactivate-action";

interface Props {
  assignment: UserLicenseAssignment;
  loadUsers?: () => void;
}

const ITEM_HEIGHT = 48;

const paperProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5,
    width: "250px",
  },
};

export const ActionsColumn = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <div>
      <IconButton aria-label="more" onClick={handleClick}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={paperProps}
      >
        <DesactivateAction
          assignment={props.assignment}
          loadUsers={props.loadUsers}
          handleClose={handleClose}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <ChangeHostAction
          handleClose={handleClose}
          assignment={props.assignment}
          loadUsers={props.loadUsers}
        />
        <ChangeAdminAction
          handleClose={handleClose}
          assignment={props.assignment}
          loadUsers={props.loadUsers}
        />
      </Menu>
    </div>
  );
};
