import { TFunction } from "react-i18next";
import { toast } from "react-toastify";

export const onError = (t: TFunction<"translation", undefined>) => (errors: unknown) => {
  const fieldsWithErrors = Object.keys(errors as Record<string, unknown>);
  const languagesKeys = new Set(fieldsWithErrors.map(field => field.split("_").pop()));
  const message = `${t(
    "custom_categories.missing_fields_to_translate_check_the_following_translations",
  )}: `;
  const languages: Array<string> = [];
  languagesKeys.forEach(language => {
    languages.push(`${t(`languages.${language}`)}`);
  });
  toast.error(`${message} ${languages.join(", ")}`);
};
