import { Reducer } from "react";

export interface State {
  isLoading: boolean;
  openRoomErrorDialog: boolean;
  openCreateRoomDialog: boolean;
}

export enum ActionType {
  IS_LOADING = "IS_LOADING",
  IS_NOT_LOADING = "IS_NOT_LOADING",
  OPEN_ROOM_ERROR_DIALOG = "OPEN_ROOM_ERROR_DIALOG",
  CLEAR_DIALOG = "CLEAR_DIALOG",
  OPEN_CREATE_ROOM_DIALOG = "OPEN_CREATE_ROOM_DIALOG",
}

export class Action<A> {
  type!: A;
  name?: string;
  roomType?: string;
}

export const initialState: State = {
  isLoading: false,
  openRoomErrorDialog: false,
  openCreateRoomDialog: false,
};

export const reducer: Reducer<State, Action<ActionType>> = (
  state: State,
  action: Action<ActionType>,
): State => {
  switch (action.type) {
    case ActionType.IS_LOADING:
      return { ...state, isLoading: true };
    case ActionType.IS_NOT_LOADING:
      return { ...state, isLoading: false };
    case ActionType.OPEN_ROOM_ERROR_DIALOG:
      return { ...state, ...initialState, openRoomErrorDialog: true };
    case ActionType.OPEN_CREATE_ROOM_DIALOG:
      return { ...initialState, openCreateRoomDialog: true };
    case ActionType.CLEAR_DIALOG:
      return { ...initialState };
    default:
      return state;
  }
};
