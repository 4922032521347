import { yupResolver } from "@hookform/resolvers/yup";
import { get } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { planService } from "services";
import * as yup from "yup";

export const useController = (
  setIsOpen: (isOpen: boolean) => void,
  loadUserPlan: () => Promise<void>,
) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowPayUPayment, setShouldShowPayUPayment] = useState(false);

  const requiredMessage = t("common.is_required");

  const validationSchema = yup.object().shape({
    users: yup
      .number()
      .moreThan(
        0,
        `${t("user_plan_management.users_to_buy_for_the_plan")} ${t(
          "common.must_be_greater_than_or_equal_to",
        )} 1`,
      )
      .typeError(`${t("user_plan_management.users_to_buy_for_the_plan")} ${requiredMessage}`)
      .required(`${t("user_plan_management.users_to_buy_for_the_plan")} ${requiredMessage}`),
    paymentMethodId: yup
      .number()
      .typeError(`${t("billing.payment_method")} ${requiredMessage}`)
      .required(`${t("billing.payment_method")} ${requiredMessage}`),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      paymentMethodId: 0,
      users: 0,
    },
  });

  const onSubmit = async (paymentMethodId: number, users: number) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      if (paymentMethodId === 0) {
        setShouldShowPayUPayment(true);
        return;
      }
      await planService.payPerUsers(paymentMethodId, users);
      methods.reset();
      await loadUserPlan();
      setIsLoading(false);
      setIsOpen(false);
      toast.success(t("user_plan_management.accounts_added_successfully"));
    } catch (e) {
      const message = get(e, "response.data.message");
      toast.error(t(message));
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    methods,
    onSubmit,
    shouldShowPayUPayment,
  };
};
