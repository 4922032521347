import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel } from "components";
import { t } from "i18next";
import { languageResources } from "lang/languages";
import { useEffect, useState } from "react";
import { a11yProps } from "utils";
import { BuildQuestionFormType } from "../../types";

const languages = Object.keys(languageResources);

interface Props {
  currentLanguage: string;
  buildQuestionForm: BuildQuestionFormType;
}

export const ModalBodyWithTabs = ({ buildQuestionForm, currentLanguage }: Props) => {
  const [tab, setTab] = useState(0);

  useEffect(() => {
    const defaultIndex = languages.findIndex(language => language === currentLanguage);
    setTab(defaultIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChange}>
          {languages.map((language, index) => (
            <Tab key={language} label={t(`languages.${language}`)} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {languages.map((language, index) => (
        <TabPanel key={language} value={tab} index={index}>
          {buildQuestionForm(language)}
        </TabPanel>
      ))}
    </>
  );
};
