import { PlatformTable, PlatformTableRow } from "components";
import { CreditCard } from "models";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

const titles = ["payment_methods.franchise", "payment_methods.number"];

interface Props {
  creditCards: Array<CreditCard> | undefined;
  loadCreditCards: () => Promise<void>
}

export const Table = ({ creditCards, loadCreditCards }: Props) => {
  const { t } = useTranslation();
  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = (creditCards || []).map(creditCard => {
    const id = String(creditCard.id);
    return {
      id,
      columns: [
        {
          id: `franchise_${id}`,
          content: creditCard.franchise,
        },
        {
          id: `maskedNumber_${id}`,
          content: `****-****-****-${creditCard.maskedNumber}`,
        },
       /* {
          id: `actions_${id}`,
          content: <OptionsButton creditCard={creditCard} loadCreditCards={loadCreditCards} />,
        }, */
      ],
    };
  });
  return (
    <PlatformTable
      className={`${Styles.table} neonScroll`}
      body={body}
      header={header}
      isLoading={!creditCards}
    />
  );
};
