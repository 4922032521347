import { NexusInput, PlatformButton } from "components";
import { ButtonType } from "enums";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Styles from "./index.module.css";
import { useCurrentLanguage } from "hooks";
import { CircularProgress } from "@mui/material";

interface Props {
  closeModal: () => void;
  isLoading: boolean;
}

export const SendProton = ({ closeModal, isLoading }: Props) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { user, protonType } = watch();
  const language = useCurrentLanguage();

  const typeLanguage = protonType.protonTypeLanguages.find(
    (e: { languageId: string }) => e.languageId === language,
  );

  let buttonSection = <CircularProgress />;

  if (!isLoading) {
    buttonSection = (
      <div className={Styles.buttonContainer}>
        <PlatformButton
          onClick={() => closeModal()}
          type={ButtonType.BUTTON}
          label={t("common.cancel")}
        />
        <PlatformButton label={t("proton_center.send_proton")} type={ButtonType.SUBMIT} />
      </div>
    );
  }

  return (
    <>
      <h2 className={Styles.Title}>{t("proton_center.give_proton_title")}</h2>
      <section className={Styles.MainSection}>
        <div className={Styles.ProtonData}>
          <img src={protonType.protonTypeImage} alt="Proton Type" />
          <span className={Styles.DataInfo}>
            <h3>{typeLanguage.name}</h3>
            <p>{typeLanguage.description}</p>
          </span>
        </div>
        <div className={Styles.InputSection}>
          <p>
            {t("proton_center.to")} {user.name}
          </p>
          <NexusInput
            name="description"
            label={t("proton_center.why_are_you_sending_this_proton")}
            rows={3}
            multiline
          />
        </div>
      </section>
      {buttonSection}
    </>
  );
};
