import { CircularProgress } from "@mui/material";
import { PlatformModuleHeader, WhatsappButton } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { UserLicenseAssignment, UserPlan } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { planService } from "services";
import { AddUsersToPlan } from "./add-users-to-plan";
import { Header } from "./header";
import { Table } from "./table";

export const UserPlanManagement = () => {
  const [users, setUsers] = useState<Array<UserLicenseAssignment> | undefined>();
  const { t } = useTranslation();
  const [userPlan, setUserPlan] = useState<UserPlan>();
  const loadUserPlan = async () => {
    const response = await planService.findUserPlanAsAdmin();
    setUserPlan(response.data);
  };
  
  useEffect(() => {
    loadUserPlan();
  }, []);

  const loadUsers = async () => {
    const response = await planService.findUserLicenseAssignments();
    setUsers(response.data);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  let content = (
    <>
      <AddUsersToPlan loadUserPlan={loadUserPlan} />
      <PlatformModuleHeader label={t("user_plan_management.title")} />
      <Header userPlan={userPlan} users={users} loadUsers={loadUsers} />
      <Table assignment={users} loadUsers={loadUsers} />
    </>
  );

  if (!users) {
    content = <CircularProgress />;
  }

  return (
    <GalacticWallpaper isContentHorizontallyCentered={!users} isContentVerticallyCentered={!users}>
      <PlatformLayout>{content}</PlatformLayout>
      <WhatsappButton position="left" />
    </GalacticWallpaper>
  );
};
