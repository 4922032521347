import { FormControl, FormHelperText, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Styles from "./index.module.css";

interface IReactHookFormTextFieldProps {
  label: string;
  name: string;
  className?: string;
  [props: string]: unknown;
}

export const NexusDateTimePicker: FC<IReactHookFormTextFieldProps> = ({
  label,
  name,
  ...otherProps
}: IReactHookFormTextFieldProps) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const hasErrors = !!errors[name];
  const helperText = errors[name]?.message ?? "";
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...props } }) => (
        <div className={Styles.input}>
          <FormControl className={Styles.container}>
            <DateTimePicker
              renderInput={renderProps => <TextField {...register(name)} {...renderProps} />}
              inputFormat="DD/MM/yyyy, h:mm a"
              {...register(name)}
              onChange={newValue => {
                setValue(name, newValue);
              }}
              label={label}
              {...props}
              {...otherProps}
            />
            {hasErrors ? <FormHelperText error={true}>{helperText}</FormHelperText> : null}
          </FormControl>
        </div>
      )}
    />
  );
};
