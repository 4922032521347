import news1Img from "assets/images/news/news_1.webp";
import news2Img from "assets/images/news/news_2.webp";
import news3Img from "assets/images/news/news_3.webp";

export const news = [
  {
    image: news1Img,
    languages: {
      es: {
        title: "Sugerencias anónimas",
        subtitle:
          "Revoluciona la cultura de feedback en tu organización con la nueva funcionalidad de Joinnexus para dar feedback anónimo",
        url: "https://medium.com/@joinnexus/revoluciona-la-cultura-de-feedback-en-tu-organizaci%C3%B3n-con-la-nueva-funcionalidad-de-joinnexus-para-1a6a5e30b0fe?source=friends_link&sk=dd1bae921dc19bd73270b551669959d6",
      },
      en: {
        title: "Anonymous feedback",
        subtitle:
          "Revolutionize the feedback culture in your organization with Joinnexus’ new feature for anonymous feedback.",
        url: "https://medium.com/@joinnexus/revolutionize-the-feedback-culture-in-your-organization-with-joinnexus-new-feature-for-anonymous-24bb1ad6bae4?source=friends_link&sk=37fa6e72b651cc03d3b4a4c69bd00589",
      },
      pt: {
        title: "Sugestões anônimas",
        subtitle:
          "Revolutionize a cultura de feedback em sua organização com a nova funcionalidade da Joinnexus para dar feedback anônimo",
        url: "https://medium.com/@joinnexus/revolutionize-a-cultura-de-feedback-em-sua-organiza%C3%A7%C3%A3o-com-a-nova-funcionalidade-da-joinnexus-para-5ea9ea45a13c?source=friends_link&sk=d7320e842fae2771e33b3ffa8215a2bc",
      },
    },
  },
  {
    image: news2Img,
    languages: {
      es: {
        title: "Finalmente puedes adaptar Joinnexus a tu medida!",
        subtitle: "Ahora podras crear nuevas preguntas y adaptar Joinnexus a tu organización",
        url: "https://medium.com/@joinnexus/finalmente-puedes-adaptar-joinnexus-a-tu-medida-b061ae760995?source=friends_link&sk=499c396dd52cebfee13c160be9a70b9e",
      },
      en: {
        title: "Finally, you can customize Joinnexus to your needs!",
        subtitle: "Now you can create new questions and adapt Joinnexus to your organization",
        url: "https://medium.com/@joinnexus/finally-you-can-customize-joinnexus-to-your-needs-c5bc42a5fbcd?source=friends_link&sk=8780a7374530d33f3e7a3ee9e9dea3fa",
      },
      pt: {
        title: "Finalmente você pode adaptar o Joinnexus à sua medida!",
        subtitle: "Agora você pode criar novas perguntas e adaptar o Joinnexus à sua organização",
        url: "https://medium.com/@joinnexus/finalmente-voc%C3%AA-pode-adaptar-o-joinnexus-%C3%A0-sua-medida-696014643351?source=friends_link&sk=344b5a41e981329c728c4f252b1ad249",
      },
    },
  },
  {
    image: news3Img,
    languages: {
      es: {
        title:
          "Mejora la colaboración en equipos remotos con espacios de teambuilding",
        subtitle: "Descubre como puedes mejorar la comunicación en tu equipo",
        url: "https://medium.com/@joinnexus/mejora-la-comunicaci%C3%B3n-y-colaboraci%C3%B3n-en-equipos-remotos-con-herramientas-innovadoras-y-espacios-de-cba2dfe59433?source=friends_link&sk=4dcc69273eb3752e37522049910be4b5",
      },
      en: {
        title:
          "Improve collaboration in remote teams with innovative tools and teambuilding spaces",
        subtitle: "Discover how you can improve communication in your team",
        url: "https://medium.com/@joinnexus/improve-communication-and-collaboration-in-remote-teams-with-innovative-tools-and-teambuilding-258e3f6af3ae?source=friends_link&sk=b482ba33a161f6ba032a8fb2c71c261c",
      },
      pt: {
        title:
          "Melhore colaboração em equipes remotas com espaços de teambuilding",
        subtitle: "Descubra como você pode melhorar a comunicação em sua equipe",
        url: "https://medium.com/@joinnexus/melhore-a-comunica%C3%A7%C3%A3o-e-colabora%C3%A7%C3%A3o-em-equipes-remotas-com-ferramentas-inovadoras-e-espa%C3%A7os-de-2d7c1c6e9f46?source=friends_link&sk=56883750f764a574929c7a9fd3ce347d",
      },
    },
  },
];
