import { PlatformTable, PlatformTableRow } from "components";
import { UserLicenseAssignment } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { titles } from "./constants";
import { Filter } from "./filter";
import Styles from "./index.module.css";
import { buildRow, filterAssignments } from "./utils";

interface Props {
  assignment?: Array<UserLicenseAssignment>;
  loadUsers?: () => void;
}

export const Table = (props: Props) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  if (!props.assignment) {
    return null;
  }

  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = filterAssignments(props.assignment, query).map(
    buildRow(props.loadUsers),
  );

  return (
    <PlatformTable
      topSection={<Filter query={query} setQuery={setQuery} />}
      className={`${Styles.table} neonScroll`}
      header={header}
      body={body}
    />
  );
};
