import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { PlatformBox } from "components";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { analyticsService } from "services";
import { FeelingsChart } from "./feelings-chart";
import Styles from "./index.module.css";

export const FeelingPerMonth = () => {
  const [monthDate, setMonthDate] = useState(moment());
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [month, year] = monthDate.format("MM/YYYY").split("/");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await analyticsService.getFeelingsPerMonth(month, year);
        setData(response.data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [month, year]);

  return (
    <>
      <DatePicker
        value={monthDate}
        views={["month", "year"]}
        openTo="month"
        disableFuture={true}
        onChange={value => setMonthDate(value || moment())}
        renderInput={params => {
          return <TextField {...params} label={t("analytics.month_year")} />;
        }}
        minDate={moment("2023-05-05T02:39:04.131Z")}
        inputFormat="MM/YYYY"
      />
      <PlatformBox className={Styles.container}>
        <FeelingsChart isLoading={isLoading} data={data}/>
      </PlatformBox>
    </>
  );
};
