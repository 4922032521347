import { languageResources } from "lang/languages";
import { Question } from "models";
import { TFunction } from "react-i18next";
import { optionFields } from "../constants";

const languages = Object.keys(languageResources);

export const buildInitValues = (t: TFunction<"translation", undefined>, question: Question) => {
  const initValuesTemplate: Record<string, string | number | boolean> = {
    autotranslation: 1,
  };
  languages.forEach(language => {
    initValuesTemplate[`question_${language}`] = "";
    optionFields.forEach(optionField => {
      initValuesTemplate[`${optionField}_${language}`] = "";
    });
  });

  if (question) {
    question.questionLanguages.forEach(({ label, languageId }) => {
      initValuesTemplate[`question_${languageId}`] = label;
    });
    let wrongQuestionsAdded = 1;
    for (const option of question.options) {
      let key = "right";
      if (!option.isPositive) {
        key = `wrong_${wrongQuestionsAdded}`;
        ++wrongQuestionsAdded;
      }
      option.optionLanguages.forEach(({ languageId, label }) => {
        initValuesTemplate[`${key}_${languageId}`] = label;
      });
    }
  }

  return initValuesTemplate;
};
