import { environment } from "environment";
import { FeedbackAnswer } from "models";
import { get, patch, post } from "utils";

class Feedback360Service {
  loadQuestions() {
    return get(environment.feedback360.questions);
  }

  loadFeedbacks() {
    return get(environment.feedback360.root);
  }

  loadFeedbackRequests() {
    return get(environment.feedback360.requests);
  }

  getFeedbackById(feedbackId: string) {
    return get(`${environment.feedback360.root}/${feedbackId}`);
  }

  createFeedback(participantsEmailsToAdd: Array<string>, answersToAdd: Array<FeedbackAnswer>) {
    return post(environment.feedback360.root, {
      participantsEmailsToAdd,
      answersToAdd,
    });
  }

  giveFeedback(feedbackId: string, answersToAdd: Array<FeedbackAnswer>) {
    return patch(`${environment.feedback360.root}/${feedbackId}`, {
      answersToAdd,
    });
  }
}

export const feedback360Service = new Feedback360Service();
