import { PlatformButton, PlatformModal, YoutubePlayer } from "components";
import GameTitle from "components/game-title/game-title";
import { useCurrentLanguage } from "hooks";
import { find, get } from "lodash";
import { LearningPost } from "models";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  post?: LearningPost;
  onClose: () => void;
}

export const PostModal = ({ post, onClose }: Props) => {
  const { t } = useTranslation();
  const linkLanguages = post?.linkLanguages;
  const currentLanguage = useCurrentLanguage();

  const linkLanguage =
    find(linkLanguages, ({ languageId }) => languageId === currentLanguage) ||
    get(linkLanguages, 0);

  const label = linkLanguage?.label;
  const link = linkLanguage?.link;

  return (
    <PlatformModal isOpen={!!post}>
      <div className="center">
        <GameTitle gameName={label as string} />
      </div>
      <div className={Styles.iframe}>
        <YoutubePlayer label={label as string} link={link as string} />
      </div>
      <div className="center">
        <PlatformButton onClick={onClose} label={t("common.close")} />
      </div>
    </PlatformModal>
  );
};
