import { size } from "lodash";
import { Event } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { eventService } from "services";


export const useController = (event: Event) => {
  const initialValue = event?.priorityHosts?.length ? event.priorityHosts : [""];

  const [priorityHosts, setPriorityHosts] = useState<Array<string>>(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const addNewPriorityHost = () => setPriorityHosts([...priorityHosts, ""]);

  const deletePriorityHost = (index: number) => () => {
    const newPriorityHosts = [...priorityHosts];
    newPriorityHosts.splice(index, 1);
    setPriorityHosts(newPriorityHosts);
  };

  const updatePriorityHost =
    (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newPriorityHosts = [...priorityHosts];
      newPriorityHosts[index] = e?.target?.value;
      setPriorityHosts(newPriorityHosts);
    };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const cleanPriorityHosts = priorityHosts
        .map(priorityHost => priorityHost.trim().toLowerCase())
        .filter(priorityHost => size(priorityHost));
      await eventService.updatePriorityHostsInEvent(event.eventId, cleanPriorityHosts);
      toast.success(t("event.event_updated"));
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return {
    priorityHosts,
    isLoading,
    addNewPriorityHost,
    deletePriorityHost,
    updatePriorityHost,
    onSubmit,
  }
}