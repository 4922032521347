import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  query: string;
  setQuery: (query: string) => void;
}

export const Filter = ({ query, setQuery }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.container}>
      <TextField
        value={query}
        onChange={event => setQuery(event.target.value)}
        label={t("user_plan_management.filter")}
      />
    </div>
  );
};
