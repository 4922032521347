import { Tooltip } from "@mui/material";
import { formatOnlyDate } from "utils";

import { useCurrentLanguage } from "hooks";
import { Proton } from "models/protons.model";
import Styles from "./index.module.css";
import { useTranslation } from "react-i18next";

interface Props {
  singleProton: Proton;
}

export const ProtonCard = ({ singleProton }: Props) => {
  const {
    createdAt,
    protonType: { protonTypeImage, protonTypeLanguages },
    description,
    fromUser,
    toUser,
  } = singleProton;
  const {t} = useTranslation();
  const currentLanguage = useCurrentLanguage();

  const protonTypeLanguage = protonTypeLanguages.find(
    ({ languageId }) => languageId === currentLanguage,
  );

  const protonTypeName = protonTypeLanguage?.name;
  const protonTypeDescription = protonTypeLanguage?.description;

  return (
    <article className={Styles.protonCard}>
      <p className={Styles.protonCardDate}>{formatOnlyDate(createdAt, true)}</p>
      <Tooltip title={protonTypeDescription as string}>
        <div className={Styles.protonCardBigImages}>
          <img src={protonTypeImage} alt="proton type"></img>
          <label className="neon">{protonTypeName}</label>
        </div>
      </Tooltip>
      <Tooltip title={description}>
        <p className={Styles.protonCardDescription}>{description}</p>
      </Tooltip>

      <div className={Styles.protonCardFooter}>
        <p>
          <label>
            <strong>{t('common.to')}:</strong> {toUser.name}
          </label>
        </p>
        <p>
          <label>
            <strong>{t('common.from')}:</strong> {fromUser.name}
          </label>
        </p>
      </div>
    </article>
  );
};
