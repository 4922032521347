import { MenuItem } from "@mui/material";
import { PlatformModal } from "components";
import { Category } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryModal } from "../../../category-modal";

interface Props {
  category: Category;
  loadCategories: () => Promise<void>;
}

export const UpdateCategoryAction = ({ category, loadCategories }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const afterCompleteForm = async () => {
    await loadCategories();
    setIsOpen(false);
  };

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>{t("custom_categories.edit")}</MenuItem>
      <PlatformModal isOpen={isOpen}>
        <CategoryModal
          closeModal={() => setIsOpen(false)}
          afterCompleteForm={afterCompleteForm}
          category={category}
        />
      </PlatformModal>
    </>
  );
};
