import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "services";
import { AddCreditCard } from "./add-credit-card";
import { Table } from "./table";

export const PaymentMethods = () => {
  const { t } = useTranslation();
  const [creditCards, setCreditCards] = useState();

  const loadCreditCards = async () => {
    const response = await userService.findCreditCards();
    setCreditCards(response.data);
  };

  useEffect(() => {
    loadCreditCards();
  }, []);

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.payment_methods")} />
        <AddCreditCard loadCreditCards={loadCreditCards} />
        <Table creditCards={creditCards} loadCreditCards={loadCreditCards}/>
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
