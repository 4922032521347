import { PlatformBox, PlatformButton, PlatformModal } from "components";
import { Feedback } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FeedbackFormWrapper } from "../feedback-form-wrapper";
import Styles from "./index.module.css";

interface Props {
  feedbackRequests: Array<Feedback>;
  loadFeedbacks: () => Promise<void>;
}
export const FeedbackRequests360 = ({ feedbackRequests, loadFeedbacks }: Props) => {
  const { t } = useTranslation();
  const [currentFeedback, setCurrentFeedback] = useState<Feedback>();

  const onClose = async () => {
    setCurrentFeedback(undefined);
    await loadFeedbacks();
  };

  let content = <label className="neon">{t("feedback_360.no_feedback_requests")}</label>;
  if (feedbackRequests.length) {
    content = (
      <>
        {feedbackRequests.map(feedback => (
          <div key={feedback._id} className={Styles.row}>
            <label className={Styles.email}>{feedback.email}</label>
            <div className={Styles.buttonContainer}>
              <PlatformButton
                className={Styles.button}
                label={t("feedback_360.give_feedback_360")}
                onClick={() => setCurrentFeedback(feedback)}
              />
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <PlatformBox>
        <label>{t("feedback_360.feedback_requests")}</label>
        <div className={Styles.container}>{content}</div>
      </PlatformBox>
      <PlatformModal isOpen={!!currentFeedback}>
        <FeedbackFormWrapper
          feedback={currentFeedback}
          onClose={onClose}
          setIsOpen={(value: boolean) => {
            if (!value) {
              setCurrentFeedback(undefined);
            }
          }}
        />
      </PlatformModal>
    </>
  );
};
