import { Tooltip } from "@mui/material";
import { ButtonType } from "enums/button-type";
import { galacticBackgrounds, GalacticColors } from "enums/galactic-backgrounds";
import { CSSProperties, MouseEventHandler, ReactElement, useEffect, useState } from "react";
import { isPromise, waitFor } from "utils";
import Styles from "./index.module.css";

class Props {
  backgroundImage?: GalacticColors = GalacticColors.BLUE;
  label?: string;
  imageSrc?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | void;
  style?: CSSProperties;
  isRound?: boolean;
  isDisabled?: boolean;
  dataTestId?: string;
  tooltip?: string;
  className?: string;
  isFit?: boolean;
  type?: ButtonType;
}

export const GameButton = (props: Props) => {
  const [shouldWait, setShouldWait] = useState(false);
  const [isRender, setIsRender] = useState(true);

  useEffect(() => () => setIsRender(false), []);
  let onClick = props.onClick as MouseEventHandler<HTMLButtonElement>;
  let backgroundImage =
    galacticBackgrounds[(props.backgroundImage as GalacticColors) || GalacticColors.BLUE];
  if (props.isDisabled) {
    // tslint:disable-next-line: no-empty
    onClick = () => {};
    backgroundImage = galacticBackgrounds[GalacticColors.DISABLE];
  }
  const style = {
    backgroundImage,
    ...(props.style || {}),
  };
  if (props.isRound) {
    style.borderRadius = "100px";
  }
  let content: string | ReactElement = props.label as string;
  if (props.imageSrc) {
    content = <img alt="" className={`${Styles.img} centerContent`} src={props.imageSrc} />;
  }

  const classnames = [Styles.gameButton];

  if (props.className) {
    classnames.push(props.className);
  }

  if (props.isFit) {
    classnames.push(Styles.fitButton);
  }

  // tslint:disable-next-line: no-any
  const onButtonClicked = async (event: any): Promise<void> => {
    if (shouldWait || !onClick) return;
    setShouldWait(true);
    if (isPromise(onClick)) {
      await onClick(event);
    } else {
      onClick(event);
      await waitFor(3 * 1000);
      setShouldWait(false);
    }
    if (isRender) {
      setShouldWait(false);
    }
  };

  const button = (
    <button
      onClick={onButtonClicked}
      style={style}
      className={classnames.join(" ")}
      data-testid={props.dataTestId}
      type={props.type}
      {...(props.isDisabled ? { disabled: true } : {})}
    >
      {content}
    </button>
  );

  if (props.tooltip) {
    return (
      <Tooltip data-testid="tooltip" title={props.tooltip}>
        <div>{button}</div>
      </Tooltip>
    );
  }

  return button;
};
