import { PlatformTableColumn } from "./platform-table-column";
import { PlatformTableRow } from "./platform-table-row";
import Styles from "./index.module.css";
import { PlatformBox } from "components";
import { ReactNode } from "react";
import { CircularProgress } from "@mui/material";

interface Props {
  header: Array<string>;
  body: Array<PlatformTableRow>;
  isLoading?: boolean;
  className?: string;
  topSection?: ReactNode;
  bottomSection?: ReactNode;
}

export const PlatformTable = (props: Props) => {
  let body = (
    <tr>
      <td colSpan={50}>
        <div className="center">
          <CircularProgress />
        </div>
      </td>
    </tr>
  );

  if (!props.isLoading) {
    body = (
      <>
        {props.body.map(rows => (
          <tr key={rows.id}>
            {rows.columns.map(column => (
              <td key={column.id}>{column.content}</td>
            ))}
          </tr>
        ))}
      </>
    );
  }

  return (
    <PlatformBox className={`${Styles.container} ${props.className}`}>
      {props.topSection}
      <table>
        <thead>
          <tr>
            {props.header.map(head => (
              <th key={head}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>{body}</tbody>
      </table>
      {props.bottomSection}
    </PlatformBox>
  );
};

export type { PlatformTableRow, PlatformTableColumn };
