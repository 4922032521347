import { GameRoundedButton } from "components";
import { ButtonType } from "enums/button-type";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { openCalendlyLink } from "utils";

export const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <div className={`centerContent`}>
      <div className={Styles.card}>
        <div className={Styles.title}>{t("landing.book_for_more_information")}</div>

        <div className={`${Styles.sendButton} center`}>
          <GameRoundedButton
            id="submit-button"
            type={ButtonType.SUBMIT}
            label={t("landing.book_a_meeting_with_us")}
            onClick={openCalendlyLink}
          />
        </div>
      </div>
    </div>
  );
};
