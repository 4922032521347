import { useEffect, ReactText } from "react";
import { useTranslation } from "react-i18next";
import { registerNewPopup } from "./utils/register-new-popup";
import { toast } from "react-toastify";
import { setTimeout } from "timers";
import { FeelingsThermometer } from "components";

interface Props {
  // tslint:disable-next-line: no-any
  children: any;
}

const showIn15Minutes = 15 * 60 * 1000;
const showIn10Minutes = 10 * 60 * 1000;
const closeIn15Seconds = 15 * 1000;

const closeIn5Minutes = 5 * 60 * 1000;

export const PopupsMessagesWrapper = (props: Props) => {
  const { t } = useTranslation();
  let feelingsPopup: ReactText;

  const registerFeedbackPopup = () => {
    registerNewPopup(
      "ask_feedback",
      t("messages.your_feedback_is_important_to_us"),
      showIn15Minutes,
      "https://forms.gle/EJf4PdTbWwPdQpTSA",
      closeIn15Seconds,
    );
  };

  const registerFeelingsPopup = () => {
    const hasAlreadyGiveUsFeelingsToday = localStorage.getItem("feelings_day");
    const today = new Date().toISOString().split("T").shift();
    if (hasAlreadyGiveUsFeelingsToday === today) return;

    setTimeout(() => {
      feelingsPopup = toast(
        <FeelingsThermometer afterSubmit={() => toast.dismiss(feelingsPopup)} />,
        {
          autoClose: closeIn5Minutes,
          theme: "dark",
          closeOnClick: false,
        },
      );
    }, showIn10Minutes);
  };

  useEffect(() => {
    registerFeedbackPopup();
    registerFeelingsPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return props.children;
};
