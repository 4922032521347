import Chat from "components/chat";
import { useUsernameFormatted } from "hooks";
import { useStoryUserIsTheSameUserInSession } from "hooks/story-user-is-the-same-user-in-session";
import { useTranslation } from "react-i18next";
import { charadesService } from "services";
import { RootState, useShallowEqualSelector } from "store";
import { useUserIsSupport } from "../../../hooks";

const sendMessage =
  (
    roomPin: string,
    userId: number,
    message: string,
    setMessage: React.Dispatch<React.SetStateAction<string>>,
  ) =>
  () => {
    setMessage("");
    charadesService.addGuess(roomPin, userId, message);
  };

export const BottomSection = () => {
  const { t } = useTranslation();
  const { userForStoryTime } = useShallowEqualSelector((state: RootState) => ({
    userForStoryTime: state.room.gameState?.userForStoryTime,
  }));

  const userNameFormatted = useUsernameFormatted(userForStoryTime);

  const label = `${t("charades.type_the_word_that")} ${userNameFormatted} ${t(
    "charades.is_acting_out",
  )}`;

  const storyUserIsTheSameUserInSession = useStoryUserIsTheSameUserInSession();

  const isSupport = useUserIsSupport();

  const canAddMessage = !storyUserIsTheSameUserInSession && !isSupport;

  return (
    <Chat
      sendMessage={sendMessage}
      canAddMessage={canAddMessage}
      defaultLabel={label}
    />
  );
};
