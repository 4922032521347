import { CircularProgress, Slider } from "@mui/material";
import { PlatformButton, PlatformModal } from "components";
import GameTitle from "components/game-title/game-title";
import { UserPlan } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { planService } from "services";
import { pricingPlans } from "consts";

interface Props {
  userPlan: UserPlan;
  setUserPlan: React.Dispatch<React.SetStateAction<UserPlan | undefined>>;
}

export const UpdateRenovationPlan = ({ userPlan, setUserPlan }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [slots, setSlots] = useState(userPlan?.defaultSlotsRenovation);
  const [isLoading, setIsLoading] = useState(false);

  const updatePlan = async () => {
    setIsLoading(true);
    try {
      await planService.updateSlotsRenovationPlan(slots);
      setUserPlan({ ...userPlan, defaultSlotsRenovation: slots });
    } finally {
      setIsLoading(false);
    }
    setIsOpen(false);
  };

  let buttonsContainer = <CircularProgress />;

  if (!isLoading) {
    buttonsContainer = (
      <div className={Styles.buttonContainer}>
        <PlatformButton label={t("common.cancel")} onClick={() => setIsOpen(false)} />
        <PlatformButton label={t("slots_management.update_renovation_plan")} onClick={updatePlan} />
      </div>
    );
  }

  return (
    <>
      <PlatformButton
        onClick={() => setIsOpen(true)}
        label={t("slots_management.update_renovation_plan")}
      />
      <PlatformModal isOpen={isOpen}>
        <GameTitle gameName={t("slots_management.update_renovation_plan")} />
        <div>
          <div className="center">
            <label className={Styles.slots}>{slots}</label>
            <label className={Styles.suffix}>{t("slots_management.slots")}</label>
          </div>

          <Slider
            min={pricingPlans[0].value}
            max={pricingPlans[pricingPlans.length - 1].value}
            step={null}
            value={slots}
            onChange={(_, value) => setSlots(value as number)}
            valueLabelDisplay="off"
            marks={pricingPlans}
          />
        </div>
        {buttonsContainer}
      </PlatformModal>
    </>
  );
};
