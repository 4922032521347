import { environment } from "environment";
import { Question } from "models";
import { get, patch, post } from "utils";

export class QuestionService {
  getQuestions(gameId: number, categoryId: string) {
    return get(`${environment.questions.game}/${gameId}/${categoryId}`);
  }

  createQuestion(gameId: number, question: Partial<Question>) {
    return post(`${environment.questions.game}/${gameId}`, question);
  }

  editQuestion(gameId: number, questionId: string, question: Partial<Question>) {
    return patch(`${environment.questions.game}/${gameId}/${questionId}`, question);
  }
}

export const questionService = new QuestionService();
