import SkipImg from 'assets/icons/platform/skip.svg';
import { GameButton } from "components/game-button";
import { useUserIsHost } from "hooks";
import { useTranslation } from "react-i18next";
import { roomService } from "services";
import { RootState, useShallowEqualSelector } from "store";
import { GameID } from "utils";
import Styles from "./index.module.css";

export const SkipPlayerButton = () => {
  const { t } = useTranslation();
  const isHost = useUserIsHost();
  const { gameId, roomPin } = useShallowEqualSelector((state: RootState) => ({
    gameId: state.room.gameId,
    roomPin: state.room.pin,
  }));

  if (!isHost) return null;

  return (
    <div className={Styles.container}>
      <GameButton
        isRound={true}
        imageSrc={SkipImg}
        tooltip={t("common.skip_player")}
        onClick={() => roomService.skipPlayer(roomPin as string, gameId as GameID)}
      />
    </div>
  );
};
