import { UserLicenseAssignment } from "models";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  users?: Array<UserLicenseAssignment>;
  totalAccounts?: number;
}

export const UserCount = (props: Props) => {
  const { t } = useTranslation();
  if (!props.users) {
    return null;
  }

  let total = props.users.filter(u => u.active).length || 0;
  total = total + 1;

  return (
    <div>
      <label className={Styles.title}>{t("user_plan_management.active_users")}</label>
      <label className={Styles.subtitle}>
        {total} / {props.totalAccounts}
      </label>
    </div>
  );
};
