import PricingBackground from "assets/backgrounds/section_pricing_wallpaper.webp";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { Plans } from "./plans";

export const Section6 = () => {
  const {t} = useTranslation();
  return (
    <div className={`${Styles.container}`} style={{ background: `url(${PricingBackground})` }}>
      <label className={Styles.title}>{t('landing.pricing.title')}</label>
      <div>
        <Plans />
      </div>
    </div>
  );
};
