import Hacku from "./assets/hacku.webp";
import Keyrus from "./assets/keyrus.svg";
import Perceptio from "./assets/perceptio.webp";
import Styles from './index.module.css';

const clients = [Hacku, Keyrus, Perceptio];
export const OurClients = () => {
  return (
    <div className={Styles.container}>
      <p className={Styles.title}>Clientes que nos respaldan</p>
      <div className={Styles.clientContainer}>
        {clients.map(client => (
          <img key={client} className={Styles.clientImage} src={client} alt={client} />
        ))}
      </div>
    </div>
  );
};
