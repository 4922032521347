import { PlatformButton, PlatformModal } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateAnEventForm } from "./create-an-event-form";
import Styles from "./index.module.css";

interface Props {
  loadEvents: () => Promise<void>;
}

export const CreateAnEvent = ({ loadEvents }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);

  return (
    <>
      <div className={Styles.container}>
        <PlatformButton onClick={() => setIsOpen(true)} label={t("events.create_an_event")} />
      </div>
      <PlatformModal isOpen={isOpen}>
        <CreateAnEventForm loadEvents={loadEvents} closeModal={closeModal} />
      </PlatformModal>
    </>
  );
};
