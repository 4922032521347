import RocketImg from "assets/images/mockups/rocket.webp";
import { useTranslation } from "react-i18next";
import { Banner } from "../banner";
import ReactPlayer from "react-player";
import Styles from './index.module.css';

export const Section2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner
        title={t("landing.focus_on_human_interaction")}
        subtitle={t("landing.focus_on_human_interaction_message")}
        img={RocketImg}
      />
      <div className={`centerContent ${Styles.container}`}>
        <ReactPlayer
          url={"/assets/videos/joinnexus.mp4"}
          playing={true}
          controls={true}
          loop={true}
          muted={true}
          playsinline={true}   
        />
      </div>
    </>
  );
};
