import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Divider } from "@mui/material";
import { PlatformButton, TranslateLanguagesHelper } from "components";
import { useCurrentLanguage } from "hooks";
import { Category } from "models";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { ModalBody } from "./modal-body";
import { buildValidations, initValuesTemplate, onError, onSubmit } from "./utils";

interface Props {
  afterCompleteForm: () => Promise<void>;
  closeModal: () => void;
  category?: Category;
}

export const CategoryModal = ({ afterCompleteForm, closeModal, category }: Props) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const [isLoading, setIsLoading] = useState(false);

  const requiredMessage = t("common.is_required");

  const modulePrefix = category?._id ? "edit" : "add";

  const initialValues = { ...initValuesTemplate };

  if (category) {
    category.categoryLanguages.forEach(({ label, languageId }) => {
      initialValues[`name_${languageId}`] = label;
    });
  }

  const methods = useForm({
    resolver: yupResolver(buildValidations(t, requiredMessage, currentLanguage)),
    defaultValues: initialValues,
  });

  let bottomBar = <CircularProgress />;

  if (!isLoading) {
    bottomBar = (
      <>
        <PlatformButton onClick={closeModal} label={t("common.cancel")} />
        <PlatformButton label={t(`custom_categories.${modulePrefix}_category`)} />
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(
          onSubmit(currentLanguage, setIsLoading, afterCompleteForm, category?._id),
          onError(t),
        )}
        className={Styles.formContainer}
        id={`${modulePrefix}-category-form`}
        data-testid={`${modulePrefix}-category-form`}
      >
        <label className="modalTitle">{t(`custom_categories.${modulePrefix}_category`)}</label>
        <TranslateLanguagesHelper />
        <Divider />
        <ModalBody currentLanguage={currentLanguage} methods={methods} />
        <div className={Styles.buttonContainer}>{bottomBar}</div>
      </form>
    </FormProvider>
  );
};
