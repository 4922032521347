import { PlatformButton } from "components";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  image: string;
  title: string;
  subtitle: string;
  url: string;
}

export const Card = ({ image, subtitle, title, url }: Props) => {
  const { t } = useTranslation();

  const onClick = () => window.open(url, "_blank");

  return (
    <div onClick={onClick} className={Styles.container}>
      <div className={Styles.content}>
        <img className={Styles.image} src={image} alt={title} />
        <p className={Styles.title}>{title}</p>
        <p className={Styles.subtitle}>{subtitle}</p>
        <div className="centerContent">
          <PlatformButton onClick={onClick} label={t("common.learn_more")} />
        </div>
      </div>
    </div>
  );
};
