import { User } from "models";
import Styles from "./index.module.css";
import DefaultImage from "assets/icons/platform/astronaut.svg";

interface Props {
  user: User;
}

export const UserContainer = ({ user }: Props) => {
  return (
    <div className={Styles.container}>
      <img
        className={Styles.image}
        src={user.picture || DefaultImage}
        alt={`profile of ${user.name}`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = DefaultImage;
        }}
      />
      <p>{user.name}</p>
    </div>
  );
};
