import { environment } from "environment";
import { get, post } from "utils";

class ProtonsService {
  getAllProtons(limit = 5, cursor?: number) {
    let endpoint = `${environment.protons.root}?limit=${limit}&cursor=`;

    if (cursor) {
      endpoint = endpoint + cursor;
    }

    return get(endpoint);
  }

  getProtonsCount() {
    return get(`${environment.protons.protonCount}`);
  }

  getProtonTypes() {
    return get(`${environment.protons.protonTypes}`);
  }

  giveProton(toUserId: number, description: string, protonType: number) {
    return post(environment.protons.root, {
      toUserId,
      description,
      protonType,
    });
  }
}

export const protonsService = new ProtonsService();
