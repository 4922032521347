import { PlatformButton, PlatformModal } from "components";
import { Question } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestionModal } from "../question-modal";
import {
  BuildInitValuesType,
  BuildQuestionFormType,
  BuildValidationsType,
  OnSubmitType,
} from "../types";
import Styles from "./index.module.css";

interface Props {
  loadQuestions: () => Promise<void>;
  buildInitValues: BuildInitValuesType;
  buildValidations: BuildValidationsType;
  onSubmit: OnSubmitType;
  suffix: string;
  buildQuestionForm: BuildQuestionFormType;
  question?: Question;
}

export const AddQuestionButton = ({ loadQuestions, ...questionModalProps }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const afterCompleteForm = async () => {
    await loadQuestions();
    setIsOpen(false);
  };
  return (
    <div className={Styles.container}>
      <PlatformButton
        onClick={() => setIsOpen(true)}
        label={t(`custom_questions.add_${questionModalProps.suffix}`)}
      />
      <PlatformModal isOpen={isOpen}>
        <QuestionModal
          {...questionModalProps}
          afterCompleteForm={afterCompleteForm}
          closeModal={() => setIsOpen(false)}
        />
      </PlatformModal>
    </div>
  );
};
