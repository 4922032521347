import { environment } from "environment";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { Routing } from "routing";
import { LocalStorage } from "enums";
import { getLoginCookie } from "./utils/get-login-cookie";
import { setUserFromToken } from "./utils/set-user-from-token";

const { loginCookieKey } = environment;

interface Props {
  children?: React.ReactNode;
}

export const TokenExtractor = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([loginCookieKey]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const roomPath = useMatch(`/${Routing.platformModule}/${Routing.platform.room}/:pin`);
  const pin = roomPath?.params?.pin;

  const eventPath = useMatch(`/${Routing.platformModule}/${Routing.platform.event}/:pin`);
  const eventPin = eventPath?.params?.pin;

  useEffect(() => {
    const loginCookie = getLoginCookie(searchParams, cookies, setSearchParams, setCookie);
    
    if (loginCookie) {
      localStorage.setItem('terms_and_conditions', 'true');
      setUserFromToken(loginCookie, dispatch);
      return;
    }

    if (pin) {
      localStorage.setItem(LocalStorage.roomPin, pin);
    }

    if (eventPin) {
      localStorage.setItem(LocalStorage.eventPin, eventPin);
    }

    navigate(`/${Routing.login}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pin]);

  return <>{props.children}</>;
};
