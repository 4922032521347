import { CircularProgress, TextField } from "@mui/material";
import { PlatformButton } from "components";
import GameTitle from "components/game-title/game-title";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { useController } from "./use-controller";

interface Props {
  onClose: () => void;
}
export const OrganizationManagement = ({ onClose }: Props) => {
  const {
    isLoading,
    organizationAlternatives,
    updateAlternative,
    deleteAlternative,
    updateAlternatives,
    addAlternative,
  } = useController();
  const { t } = useTranslation();

  const updateList = async () => {
    await updateAlternatives();
    onClose();
  };

  let actions = (
    <PlatformButton label={t("slots_management.subdomains_management")} onClick={updateList} />
  );
  let content = (
    <>
      {organizationAlternatives.map((organizationAlternative, index) => (
        <div key={`organization_${index}`} className={Styles.inputContainer}>
          <TextField
            className={Styles.input}
            label={`${t("event.priority_host_email")} ${index + 1}`}
            variant="outlined"
            value={organizationAlternative.domain}
            onChange={updateAlternative(index)}
          />

          <div
            onClick={deleteAlternative(index)}
            className={`${Styles.deleteButtonContainer} centerContent`}
          >
            <p>X</p>
          </div>
        </div>
      ))}
    </>
  );
  if (isLoading) {
    actions = <CircularProgress />;
    content = (
      <>
        <CircularProgress />
      </>
    );
  }

  return (
    <div>
      <GameTitle gameName={t("slots_management.subdomains_management")} />
      <div className={`${Styles.container}`}>{content}</div>
      <div className="centerContent">
        <div onClick={addAlternative} className={`${Styles.addButtonContainer} centerContent`}>
          <p>+</p>
        </div>
      </div>
      <div>
        <PlatformButton label={t("common.cancel")} onClick={() => onClose()} />
        {actions}
      </div>
    </div>
  );
};
