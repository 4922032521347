import { PlatformTable, PlatformTableRow } from "components";
import { Event } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { eventService } from "services";
import { formatDatetime } from "utils";
import { Actions } from "./actions";
import { CreateAnEvent } from "./create-an-event";
import Styles from "./index.module.css";

const titles = [
  "events.event_name",
  "events.start_date",
  "events.end_date",
  "events.room_type",
  "common.actions",
];

export const EventsList = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState<Array<Event>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadEvents = async () => {
    setIsLoading(true);
    try {
      const response = await eventService.getEvents();
      setEvents(response.data);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = events.map(event => ({
    id: event.eventId,
    columns: [
      {
        id: `event_name_${event.eventId}`,
        content: event.name,
      },
      {
        id: `start_date_${event.eventId}`,
        content: formatDatetime(event.startAt),
      },
      {
        id: `end_date_${event.eventId}`,
        content: formatDatetime(event.expirationAt),
      },
      {
        id: `room_type_${event.eventId}`,
        content: t(`rooms.${event.roomType}`),
      },
      {
        id: `actions_${event.eventId}`,
        content: <Actions loadEvents={loadEvents} event={event} />,
      },
    ],
  }));
  return (
    <>
      <CreateAnEvent loadEvents={loadEvents} />
      <PlatformTable
        className={`neonScroll ${Styles.table}`}
        isLoading={isLoading}
        header={header}
        body={body}
      />
    </>
  );
};
