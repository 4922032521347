export const environment = {
  isPrerender: process.env.IS_PRERENDER,
  landingUrl: process.env.REACT_APP_LANDING_URL || "",
  platformUrl: process.env.REACT_APP_PLATFORM_URL || "https://play.joinnexus.io",
  apiUrl: process.env.REACT_APP_API_URL,
  calendlyLink: "https://calendly.com/hello-joinnexus/15min",
  websocketUrl: process.env.REACT_APP_WEBSOCKET_URL as string,
  analytics: {
    root: `${process.env.REACT_APP_API_URL}/analytics`,
    feelingPerMonth: `${process.env.REACT_APP_API_URL}/analytics/feeling-per-month`,
    protonsInPeriod: `${process.env.REACT_APP_API_URL}/analytics/protons-in-period`,
  },
  login: {
    root: `${process.env.REACT_APP_API_URL}/login`,
    google: `${process.env.REACT_APP_API_URL}/login/google`,
    office365: `${process.env.REACT_APP_API_URL}/login/office365`,
    slack: `${process.env.REACT_APP_API_URL}/login/slack`,
  },
  anonymousFeedback: {
    root: `${process.env.REACT_APP_API_URL}/anonymous-feedbacks`,
    cursor: `${process.env.REACT_APP_API_URL}/anonymous-feedbacks/cursor`,
    count: `${process.env.REACT_APP_API_URL}/anonymous-feedbacks/count`,
  },
  bills: {
    root: `${process.env.REACT_APP_API_URL}/bills`,
  },
  rooms: {
    root: `${process.env.REACT_APP_API_URL}/rooms`,
    event: `${process.env.REACT_APP_API_URL}/rooms/event`,
    events: `${process.env.REACT_APP_API_URL}/rooms/events`,
    room: `${process.env.REACT_APP_API_URL}/rooms/room/event`,
    eventRoomSummary: `${process.env.REACT_APP_API_URL}/rooms/event/room-summary`,
  },
  learningPosts: {
    root: `${process.env.REACT_APP_API_URL}/learning-posts`,
  },
  games: {
    root: `${process.env.REACT_APP_API_URL}/games`,
  },
  languages: {
    root: `${process.env.REACT_APP_API_URL}/languages`,
    translate: `${process.env.REACT_APP_API_URL}/languages/translate`,
  },
  categories: {
    root: `${process.env.REACT_APP_API_URL}/categories`,
  },
  payments: {
    root: `${process.env.REACT_APP_API_URL}/payments`,
    manual: `${process.env.REACT_APP_API_URL}/payments/manual`,
  },
  questions: {
    root: `${process.env.REACT_APP_API_URL}/questions`,
    game: `${process.env.REACT_APP_API_URL}/questions/game`,
  },
  users: {
    root: `${process.env.REACT_APP_API_URL}/users`,
    organization: `${process.env.REACT_APP_API_URL}/users/organization`,
    subscription: `${process.env.REACT_APP_API_URL}/users/subscription`,
    creditCard: `${process.env.REACT_APP_API_URL}/users/credit-card`,
  },
  feelings: {
    root: `${process.env.REACT_APP_API_URL}/feelings`,
  },
  feedback360: {
    root: `${process.env.REACT_APP_API_URL}/feedbacks`,
    questions: `${process.env.REACT_APP_API_URL}/feedbacks/questions`,
    requests: `${process.env.REACT_APP_API_URL}/feedbacks/requests`,
  },
  plans: {
    root: `${process.env.REACT_APP_API_URL}/plans`,
    plans: `${process.env.REACT_APP_API_URL}/plans/plans`,
    slotsConsumption: `${process.env.REACT_APP_API_URL}/plans/slots-consumption`,
    plansConsumption: `${process.env.REACT_APP_API_URL}/plans/consumption`,
    plansActive: `${process.env.REACT_APP_API_URL}/plans/active`,
    renovationPlan: `${process.env.REACT_APP_API_URL}/plans/renovation-plan`,
    plansAsAdmin: `${process.env.REACT_APP_API_URL}/plans/plan-as-admin`,
    userLicenseAssignments: `${process.env.REACT_APP_API_URL}/plans/user-license-assignments`,
    userLicenseAssignment: `${process.env.REACT_APP_API_URL}/plans/user-license-assignment`,
    payPerUsers: `${process.env.REACT_APP_API_URL}/plans/pay-per-users`,
    host: `${process.env.REACT_APP_API_URL}/plans/host`,
    admin: `${process.env.REACT_APP_API_URL}/plans/admin`,
    organizationAlternatives: `${process.env.REACT_APP_API_URL}/plans/organization-alternatives`,
  },
  loginCookieKey: "loginCookie",
  paymentServiceProvider: {
    checkoutUrl: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER_CHECKOUT_URL as string,
    responseUrl: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER_RESPONSE_URL as string,
    confirmationUrl: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER_CONFIRMATION_URL as string,
    apiKey: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER_API_KEY as string,
    accountId: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER_ACCOUNT_ID as string,
    merchantId: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER_MERCHANT_ID as string,
    test: process.env.REACT_APP_PAYMENT_SERVICE_PROVIDER_TEST || "0",
  },
  protons: {
    root: `${process.env.REACT_APP_API_URL}/protons`,
    protonCount: `${process.env.REACT_APP_API_URL}/protons/count`,
    protonTypes: `${process.env.REACT_APP_API_URL}/proton-types`,
  },
};
