import { CircularProgress } from "@mui/material";
import { ChangeLanguageButton, UpdateCurrentPlan, WhatsappButton } from "components";
import { LocalStorage } from "enums";
import { GalacticWallpaper, UserConfigurationButtonWrapper } from "hoc";
import { UserPlan } from "models";
import { useEffect, useState } from "react";
import { planService } from "services";
import { RootState, useShallowEqualSelector } from "store";
import { goToEvent, goToRoom } from "utils";
import { CreateRoom } from "./create-room";
import { FeelingsSection } from "./feelings-section";
import { LearningButton } from "./learning-button";

const RoomsPage = () => {
  const { plan } = useShallowEqualSelector((state: RootState) => ({
    plan: state.user.plan,
  }));
  const [isLoading, setIsLoading] = useState(false);
  const [userPlan, setUserPlan] = useState<UserPlan>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await planService.findUserPlanActive();
        setUserPlan(response.data);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const roomPin = localStorage.getItem(LocalStorage.roomPin);
    const eventPin = localStorage.getItem(LocalStorage.eventPin);
    if (eventPin) {
      localStorage.removeItem(LocalStorage.eventPin);
      localStorage.removeItem(LocalStorage.roomPin);
      goToEvent(eventPin)();
    } else if (roomPin) {
      localStorage.removeItem(LocalStorage.roomPin);
      goToRoom(roomPin)();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content = <UpdateCurrentPlan userPlan={userPlan} />;

  if (isLoading) {
    content = <CircularProgress />;
  }

  if (plan) {
    content = (
      <div>
        <CreateRoom />
        <FeelingsSection />
      </div>
    );
  }

  return (
    <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
      <UserConfigurationButtonWrapper>
        {content}
      </UserConfigurationButtonWrapper>
      <ChangeLanguageButton />
      <LearningButton />
      <WhatsappButton position="left" />
    </GalacticWallpaper>
  );
};

export default RoomsPage;
