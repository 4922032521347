import { CircularProgress } from "@mui/material";
import { PostIt } from "components";
import GameTitle from "components/game-title/game-title";
import { AnonymousFeedback } from "models";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  isLoading: boolean;
  feedbacks: Array<AnonymousFeedback>;
  count: number;
}
export const TopSection = ({ isLoading, feedbacks, count }: Props) => {
  const { t } = useTranslation();
  const shouldShowLoading = isLoading && !feedbacks.length;
  if (shouldShowLoading) {
    return (
      <section className="center">
        <CircularProgress />
      </section>
    );
  }

  if (count === 0) {
    return (
      <section className="center">
        <GameTitle gameName={t("anonymous_feedback.no_anonymous_feedback")} />
      </section>
    );
  }

  return (
    <div className={Styles.container}>
      {feedbacks.map(feedback => (
        <PostIt key={feedback._id} timestamp={feedback.createdAt} description={feedback.feedback} />
      ))}
    </div>
  );
};
