import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import { NexusDateTimePicker, NexusInput, NexusSelect, PlatformButton } from "components";
import GameTitle from "components/game-title/game-title";
import { ButtonType, RoomType } from "enums";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { eventService } from "services";
import * as yup from "yup";
import Styles from "./index.module.css";

const defaultValues = {
  eventName: "",
  roomType: RoomType.COFFEE_TIME,
  startDate: new Date(),
  linkAfterRoom: "",
};

interface Props {
  closeModal: () => void;
  loadEvents: () => Promise<void>;
}

const timeframe = {
  [RoomType.ICEBREAKER]: 15,
  [RoomType.COFFEE_TIME]: 30,
  [RoomType.MEET_AND_CHILL]: 60,
};

export const CreateAnEventForm = ({ closeModal, loadEvents }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const requiredMessage = t("common.is_required");

  const validationSchema = yup.object().shape({
    eventName: yup.string().required(`${t("events.event_name")} ${requiredMessage}`),
    roomType: yup.string().required(`${t("events.room_type")} ${requiredMessage}`),
    startDate: yup
      .date()
      .required(`${t("events.start_date")} ${requiredMessage}`)
      .typeError(`${t("events.start_date")} ${requiredMessage}`),
    linkAfterRoom: yup.string(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const roomTypes = Object.values(RoomType).map(value => ({
    value,
    label: `${t(`rooms.${value}`)} - ${timeframe[value]} ${t("common.minutes")}`,
  }));

  const onSubmit = async (e: {
    eventName: string;
    roomType: RoomType;
    startDate: Date;
    linkAfterRoom: string;
  }) => {
    setIsLoading(true);
    try {
      await eventService.createEvent(e.eventName, e.startDate, e.roomType, e.linkAfterRoom);
      await loadEvents();
      closeModal();
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  let buttons = (
    <>
      <PlatformButton onClick={closeModal} label={t("common.cancel")} />
      <PlatformButton type={ButtonType.SUBMIT} label={t("events.create_an_event")} />
    </>
  );

  if (isLoading) {
    buttons = <CircularProgress />;
  }
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className={Styles.formContainer}
        id={`create-event-form`}
        data-testid={`create-event-form`}
      >
        <GameTitle gameName={t("events.create_an_event")} />
        <div>
          <NexusInput
            label={`${t("events.event_name")}*`}
            variant="outlined"
            name="eventName"
            type="text"
          />
          <NexusSelect
            label={`${t("events.room_type")} *`}
            name="roomType"
            variant="outlined"
            items={roomTypes}
          />
          <NexusDateTimePicker label={`${t("events.start_date")}`} name="startDate" />
          <NexusInput
            label={`${t("events.link_after_room")}`}
            name="linkAfterRoom"
            variant="outlined"
          />
        </div>

        <div className={Styles.buttonContaienr}>{buttons}</div>
      </form>
    </FormProvider>
  );
};
