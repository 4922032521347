import NeonBox from "components/neon-box/neon-box";
import { BottomSection } from "./bottom-section";
import Styles from "./index.module.css";
import { TopSection } from "./top-section";
import { useController } from "./use-controller";

export const Feedbacks = () => {
  const { count, feedbacks, isLoading, setLastFeedback } = useController();

  return (
    <main>
      <NeonBox className={Styles.neonBox}>
        <TopSection feedbacks={feedbacks} isLoading={isLoading} count={count} />
        <BottomSection
          count={count}
          feedbacks={feedbacks}
          isLoading={isLoading}
          setLastFeedback={setLastFeedback}
        />
      </NeonBox>
    </main>
  );
};
