import { YoutubePlayer } from "components";
import { GameInstructions } from "components/game-instructions";
import { useTranslation } from "react-i18next";

const Instructions = () => {
  const { t } = useTranslation();
  return (
    <GameInstructions styles={{ maxHeight: "50vh" }} gameName={t("games.hypotethically")}>
      <YoutubePlayer label="instructions" link="https://youtube.com/embed/FoMeT707A8Q" />
    </GameInstructions>
  );
};

export default Instructions;
