import NoAudioIcon from "assets/icons/platform/mic_off.svg";
import { VideoUserConsumer, VideoUserState } from "contexts";
import { useUsernameFormatted } from "hooks";
import { get } from "lodash";
import { RootState, useShallowEqualSelector } from "store";

interface Props {
  userId: string;
  children?: React.ReactNode;
  videoUserState?: VideoUserState;
}

const InternalStoryTimeUserWrapper = (props: Props) => {
  const { userForStoryTime } = useShallowEqualSelector((state: RootState) => ({
    userForStoryTime: state.room.gameState?.userForStoryTime,
  }));

  const { children, videoUserState, userId } = props;
  const userNameFormatted = useUsernameFormatted(userForStoryTime);

  const noAudioIcon = get(videoUserState, `audio.${userId}`) ? null : (
    <img data-testid="no-audio" alt="no audio" src={NoAudioIcon} />
  );

  return (
    <>
      {children}
      <h3 className="neon">
        {userNameFormatted} {noAudioIcon}
      </h3>
    </>
  );
};

export const StoryTimeUserWrapper = (props: Props) => {
  return (
    <VideoUserConsumer>
      {consumer => {
        return (
          <>
            <InternalStoryTimeUserWrapper
              {...props}
              videoUserState={(consumer as { state: VideoUserState }).state}
            />
          </>
        );
      }}
    </VideoUserConsumer>
  );
};
