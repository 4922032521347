import { PlatformTableRow } from "components";
import { UserLicenseAssignment } from "models";
import { ActionsColumn } from "../actions-column";
import { BooleanColumn } from "../boolean-column";
import { StatusColumn } from "../status-column";
import { UserColumn } from "../user-column";

export const buildRow =
  (loadUsers?: () => void) =>
  (user: UserLicenseAssignment): PlatformTableRow => ({
    id: String(user.toUser.id),
    columns: [
      {
        id: `user_${user.toUser.id}`,
        content: <UserColumn user={user.toUser} />,
      },
      {
        id: `is_host_${user.toUser.id}`,
        content: <BooleanColumn boolean={user.isHost} />,
      },
      {
        id: `is_admin_${user.toUser.id}`,
        content: <BooleanColumn boolean={user.isAdmin} />,
      },
      {
        id: `status_${user.toUser.id}`,
        content: <StatusColumn assignment={user} />,
      },
      {
        id: `actions_${user.toUser.id}`,
        content: <ActionsColumn loadUsers={loadUsers} assignment={user} />,
      },
    ],
  });
