import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Styles from "./index.module.css";
class IReactHookFormTextFieldProps {
  label!: string;
  name!: string;
  className?: string;
  items: Array<{ value: string | number; label: string }> = [];
  defaultValue?: string | number;
  [props: string]: unknown;
}

export const NexusSelect: FC<IReactHookFormTextFieldProps> = ({
  label,
  name,
  items,
  defaultValue,
  ...props
}: IReactHookFormTextFieldProps) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const hasErrors = !!errors[name];
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className={Styles.container}>
          <FormControl>
            <InputLabel id={`${name}-label`}>{label}</InputLabel>
            <Select
              labelId={`${name}-label`}
              label={label}
              variant="outlined"
              className={`${props.className}`}
              error={hasErrors}
              fullWidth
              margin="dense"
              autoComplete="off"
              {...field}
              {...props}
            >
              {items.map(({ value, label: itemLabel }) => (
                <MenuItem key={itemLabel} value={value}>
                  {itemLabel}
                </MenuItem>
              ))}
            </Select>
            {hasErrors ? (
              <FormHelperText error={true}>{errors[name]?.message}</FormHelperText>
            ) : null}
          </FormControl>
        </div>
      )}
    ></Controller>
  );
};
