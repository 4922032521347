import axios from "axios";
import axiosRetry from "axios-retry";
import { getToken } from "./get-token";

const contentType = "application/json";

axios.interceptors.request.use(config => {
  config.timeout = 10 * 1000; 
  return config;
});

axiosRetry(axios, { retries: 3 });

export const post = (url: string, values?: unknown) => {
  return axios.post(url, values, {
    headers: {
      "content-type": contentType,
      Authorization: `${getToken()}`,
    },
  });
};

export const get = (url: string) => {
  return axios.get(url, {
    headers: {
      "content-type": contentType,
      Authorization: `${getToken()}`,
    },
  });
};

export const patch = (url: string, values?: unknown) => {
  return axios.patch(url, values, {
    headers: {
      "content-type": contentType,
      Authorization: `${getToken()}`,
    },
  });
};

export const deleteHttp = (url: string) => {
  return axios.delete(url, {
    headers: {
      "content-type": contentType,
      Authorization: `${getToken()}`,
    },
  });
};
