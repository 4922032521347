import { NexusInput } from "components";
import creditCardType from "credit-card-type";
import { Franchise } from "enums";
import { get, head } from "lodash";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const franchises = {
  "american-express": Franchise.AMEX,
  visa: Franchise.VISA,
  mastercard: Franchise.MASTERCARD,
  "diners-club": Franchise.DINERS,
};

export const CardNumberInput = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  return (
    <NexusInput
      onChange={(event: { target: HTMLInputElement }) => {
        const value = event.target.value;
        const valueWithNumbers = String(value)
          .replaceAll(/-/g, "")
          .replaceAll(/_/g, "")
          .substring(0, 16);
        let valueWithFormat = "";
        let lastIndex = 0;

        for (let i = 0; i < 16; i++) {
          const hasValue = !!valueWithNumbers[i];
          valueWithFormat += valueWithNumbers[i] || "_";
          const shouldAddSeparator = (i + 1) % 4 === 0 && i !== 0 && i !== 15;
          if (hasValue) {
            lastIndex = valueWithFormat.length;
          }
          if (shouldAddSeparator) {
            valueWithFormat += "-";
          }
        }
        setValue("number", valueWithFormat);
        event?.target.setSelectionRange(lastIndex, lastIndex);

        const creditCard = creditCardType(valueWithNumbers);
        const type = get(head(creditCard), "type");
        if (!type) return;
        const typeFormatted = get(franchises, type);
        if (!typeFormatted) return;
        setValue("franchise", typeFormatted);
      }}
      label={`${t("payment_methods.number")}*`}
      name="number"
    />
  );
};
