import { IconButton, Tooltip, } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';

interface Props {
  help: string;
}

export const HelpTooltip = ({help}: Props) => {
  return (
    <Tooltip title={help}>
      <IconButton>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};
