import { PlatformButton, PlatformModal } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FeedbackForm } from "./feedback-form";
import Styles from "./index.module.css";

export const AddFeedback = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={Styles.container}>
        <PlatformButton
          onClick={() => setIsOpen(true)}
          label={t("anonymous_feedback.add_anonymous_feedback")}
        />
      </div>
      <PlatformModal isOpen={isOpen}>
        <FeedbackForm closeModal={() => setIsOpen(false)} />
      </PlatformModal>
    </>
  );
};
