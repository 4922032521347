import { CircularProgress } from "@mui/material";
import { environment } from "environment";
import { useRef, useEffect } from "react";
import { useShallowEqualSelector, RootState } from "store";
import { generateSignature } from "utils";

interface Props {
  accountsToAdd: number;
  newPlanAccounts: number;
}

export const OpenPayU = ({ accountsToAdd, newPlanAccounts }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { email, userId } = useShallowEqualSelector((state: RootState) => ({
    email: state.user.email,
    userId: state.user.id,
  }));

  const currency = "USD";

  useEffect(() => {
    if (!formRef.current) {
      return;
    }
    formRef.current.submit();
  }, []);
  const { checkoutUrl, merchantId, accountId, responseUrl, apiKey, test } =
    environment.paymentServiceProvider;

  const referenceCode = `add_slots_joinnexus_${userId}_${new Date().toISOString()}`;

  const signature = generateSignature(apiKey, merchantId, referenceCode, accountsToAdd, currency);

  const confirmationUrl = environment.payments.manual;

  return (
    <form ref={formRef} method="post" action={checkoutUrl}>
      <input name="merchantId" type="hidden" value={merchantId} />
      <input name="accountId" type="hidden" value={accountId} />
      <input name="description" type="hidden" value={`Add users to plan`} />
      <input name="referenceCode" type="hidden" value={referenceCode} />
      <input name="amount" type="hidden" value={accountsToAdd} />
      <input name="tax" type="hidden" value="0" />
      <input name="taxReturnBase" type="hidden" value="0" />
      <input name="currency" type="hidden" value={currency} />
      <input name="signature" type="hidden" value={signature} />
      <input name="test" type="hidden" value={test} />
      <input name="buyerEmail" type="hidden" value={email} />
      <input name="extra1" type="hidden" value={userId} />
      <input name="extra2" type="hidden" value={accountsToAdd} />
      <input name="extra3" type="hidden" value={newPlanAccounts} />
      {responseUrl && <input name="responseUrl" type="hidden" value={responseUrl} />}
      <input name="confirmationUrl" type="hidden" value={confirmationUrl} />
      <CircularProgress />
    </form>
  );
};
