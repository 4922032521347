import { Bill } from "models";
import { PayBillButton } from "./pay-bill-button";

interface Props {
  bill: Bill;
}

export const TransactionIdColumn = ({ bill }: Props) => {
  if (!bill.transactionId) {
    return <PayBillButton bill={bill} />
  }
  return <label className="allowSelect">{bill.transactionId}</label>;
};
