import IntroductionBackground from "assets/backgrounds/section_1_wallpaper.webp";
import { Header } from "./header";
import Styles from "./index.module.css";
import { Introduction } from "./introduction";
import { Trailer } from "./trailer";

export const Section1 = () => {
  return (
    <section
      data-testid="register-early-access-section"
      className={`fitWallpaper ${Styles.container}`}
      style={{ background: `url(${IntroductionBackground})` }}
    >
      <Header />
      <div className={`${Styles.content} row`}>
        <Introduction />
        <Trailer />
      </div>
    </section>
  );
};
