import { NexusInput } from "components";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";

export const CCVInput = () => {
  const { setValue } = useFormContext();

  return (
    <NexusInput
      onChange={(event: unknown) => {
        const value = get(event, "target.value");
        const valueFormatted = String(value).substring(0, 4);
        setValue("ccv", valueFormatted);
      }}
      label={`CCV*`}
      name="ccv"
      type="number"
      inputProps={{ maxLength: 4 }}
    />
  );
};
