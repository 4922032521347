import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormContext } from "react-hook-form";

interface Props {
  label: string;
  name: string;
}

export const NexusDatePicker = ({ label, name }: Props) => {
  const {
    setValue,
    watch,
  } = useFormContext();

  const value = watch(name);

  return (
    <DatePicker
      value={value}
      onChange={val => setValue(name, val)}
      renderInput={params => {
        return <TextField {...params} label={label} />;
      }}
    />
  );
};
