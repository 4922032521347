import { useState, useEffect } from "react";

import { Proton } from "models/protons.model";
import { ProtonType } from "models/proton-type.model";
import { protonsService } from "services/protons.service";

const limit = 10;

export const useController = () => {
  const [loading, setLoading] = useState(false);
  const [protons, setProtons] = useState<Array<Proton>>([]);
  const [addProton, setAddProton] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [protonsCount, setProtonsCount] = useState<number>(0);
  const [protonTypes, setProtonTypes] = useState<Array<ProtonType>>([]);
  const [selectedType, setSelectedType] = useState(false);

  const loadInitialProtons = async () => {
    setLoading(true);
    try {
      const response = await protonsService.getAllProtons(limit);
      const countResponse = await protonsService.getProtonsCount();
      setProtons(response.data);
      setProtonsCount(countResponse.data);
      setCursor(0);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await protonsService.getProtonTypes();
        const countResponse = await protonsService.getProtonsCount();
        setProtonTypes(response.data);
        setProtonsCount(countResponse.data);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await protonsService.getAllProtons(limit, cursor);
        if (cursor) {
          setProtons(protons.concat(...response.data));
        } else {
          setProtons(response.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor]);

  const hasMoreProtons = protons.length < protonsCount;

  return {
    protons,
    addProton,
    protonTypes,
    limit,
    cursor,
    protonsCount,
    hasMoreProtons,
    selectedType,
    loading,
    setProtons,
    setAddProton,
    setCursor,
    setProtonsCount,
    setSelectedType,
    loadInitialProtons,
  };
};
