import { PlatformTable, PlatformTableRow } from "components";
import { useCurrentLanguage } from "hooks";
import { find } from "lodash";
import { Question } from "models";
import { useTranslation } from "react-i18next";
import { Actions } from "../../../common/actions";
import {
  BuildInitValuesType,
  BuildQuestionFormType,
  BuildValidationsType,
} from "../../../common/types";
import { buildInitValues, buildValidations, onSubmit } from "../utils";

const titles = ["custom_questions.sentence", "common.actions"];

interface Props {
  questions: Array<Question>;
  isLoading: boolean;
  loadQuestions: () => Promise<void>;
  buildQuestionForm: BuildQuestionFormType;
}

export const Table = ({ questions, isLoading, loadQuestions, buildQuestionForm }: Props) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const header = titles.map(title => t(title));
  const body: Array<PlatformTableRow> = questions.map(question => ({
    id: question._id,
    columns: [
      {
        id: `question_${question._id}`,
        content: find(question.questionLanguages, e => e.languageId === currentLanguage)?.label,
      },
      {
        id: `actions_${question._id}`,
        content: (
          <Actions
            buildQuestionForm={buildQuestionForm}
            loadQuestions={loadQuestions}
            question={question}
            buildInitValues={buildInitValues as BuildInitValuesType}
            buildValidations={buildValidations as BuildValidationsType}
            onSubmit={onSubmit}
            suffix="sentence"
          />
        ),
      },
    ],
  }));

  return (
    <PlatformTable className={`neonScroll`} isLoading={isLoading} header={header} body={body} />
  );
};
