import { PlatformButton } from "components";
import { Bill } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SendForm } from "./send-form";

interface Props {
  bill: Bill;
}
export const PayBillButton = ({ bill }: Props) => {
  const [sendForm, setSendForm] = useState(false);
  const { t } = useTranslation();
  if (sendForm) {
    return <SendForm bill={bill} />;
  }
  return <PlatformButton onClick={() => setSendForm(true)} label={t("billing.pay_bill")} />;
};
