import { NexusInput } from "components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

export const QuestionForm = (language: string) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const label = `${t("custom_questions.type")} ${t("custom_questions.the_question")} ${t(
    "custom_questions.in",
  )} ${t(`languages.${language}`).toLowerCase()}`;

  const prefix = `${t("most_likely_to.question_prefix", { lng: language })}`;

  return (
    <div>
      <NexusInput
        className={Styles.input}
        name={`question_${language}`}
        label={label}
        onChange={(event: { target: HTMLInputElement }) => {
          let value = event.target.value;

          if (!value.startsWith(prefix)) {
            value = `${prefix} ${value}`;
          }

          const lastIndex = value.length;
          event?.target.setSelectionRange(lastIndex, lastIndex);
          setValue(`question_${language}`, value);
        }}
      />
    </div>
  );
};
