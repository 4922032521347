import { CircularProgress } from "@mui/material";
import { environment } from "environment";
import { useRef, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useShallowEqualSelector, RootState } from "store";
import { generateSignature } from "utils";

export const OpenPayU = () => {
  const { watch } = useFormContext();
  const formRef = useRef<HTMLFormElement>(null);
  const { email, userId, pricePerUser } = useShallowEqualSelector((state: RootState) => ({
    email: state.user.email,
    userId: state.user.id,
    pricePerUser: state.user.plan?.price as number,
  }));

  const currency = "USD";

  useEffect(() => {
    if (!formRef.current) {
      return;
    }
    formRef.current.submit();
  }, []);

  const values = watch();

  const users = values?.users;

  const price = users * pricePerUser;

  const { checkoutUrl, merchantId, accountId, responseUrl, apiKey, test } =
    environment.paymentServiceProvider;

  const referenceCode = `add_users_joinnexus_${userId}_${new Date().toISOString()}`;

  const signature = generateSignature(apiKey, merchantId, referenceCode, price, currency);

  const confirmationUrl = environment.payments.manual;

  return (
    <form ref={formRef} method="post" action={checkoutUrl}>
      <input name="merchantId" type="hidden" value={merchantId} />
      <input name="accountId" type="hidden" value={accountId} />
      <input name="description" type="hidden" value={`Add users to plan`} />
      <input name="referenceCode" type="hidden" value={referenceCode} />
      <input name="amount" type="hidden" value={price} />
      <input name="tax" type="hidden" value="0" />
      <input name="taxReturnBase" type="hidden" value="0" />
      <input name="currency" type="hidden" value={currency} />
      <input name="signature" type="hidden" value={signature} />
      <input name="test" type="hidden" value={test} />
      <input name="buyerEmail" type="hidden" value={email} />
      <input name="extra1" type="hidden" value={userId} />
      <input name="extra2" type="hidden" value={users} />
      {responseUrl && <input name="responseUrl" type="hidden" value={responseUrl} />}
      <input name="confirmationUrl" type="hidden" value={confirmationUrl} />
      <CircularProgress />
    </form>
  );
};
