import BackIcon from "assets/icons/platform/back.svg";
import { GameButton } from "components";
import { backButtonStyle } from "components/game-instructions/index.style";
import { GalacticColors } from "enums";
import { goToHomeRoom } from "hoc/game-wrapper/utils/go-to-home-room";
import { useUserIsHost } from "hooks/use-user-is-host";
import { useTranslation } from "react-i18next";
import { RootState, useShallowEqualSelector } from "store";
import Styles from './index.module.css';

export const GoBackButton = () => {
  const { pin } = useShallowEqualSelector((state: RootState) => ({
    pin: state.room.pin,
  }));
  const { t } = useTranslation();
  const isHost = useUserIsHost();

  return (
    <div className={Styles.backButton}>
      <GameButton
        onClick={goToHomeRoom(pin as string)}
        style={backButtonStyle}
        backgroundImage={GalacticColors.BLUE}
        imageSrc={BackIcon}
        tooltip={`${t("common.go_back")}`}
        isDisabled={!isHost}
      />
    </div>
  );
};
