import { YoutubePlayer } from "components";
import { GameInstructions } from "components/game-instructions";
import { useTranslation } from "react-i18next";

export const Instructions = () => {
  const { t } = useTranslation();
  return (
    <GameInstructions styles={{ maxHeight: "50vh" }} gameName={t("games.debate")}>
      <YoutubePlayer label="instructions" link="https://youtube.com/embed/g-ersnbR_1I" />
    </GameInstructions>
  );
};
