import { CircularProgress } from "@mui/material";
import { Action } from "@reduxjs/toolkit";
import { GameButton } from "components";
import { GalacticColors } from "enums";
import { useTranslation } from "react-i18next";
import { ActionType } from "../../reducer";
import Styles from './index.module.css';

interface Props {
  isLoading: boolean;
  dispatch: React.Dispatch<Action<ActionType>>;
}
export const ButtonsContainer = ({ isLoading, dispatch }: Props) => {
  const { t } = useTranslation();
  
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div className={Styles.buttonContainer}>
      <GameButton
        dataTestId="create-room-button"
        onClick={() => dispatch({type: ActionType.OPEN_CREATE_ROOM_DIALOG})}
        backgroundImage={GalacticColors.BLUE}
        label={t("rooms.create_a_room")}
        className={Styles.button}
      />
    </div>
  );
};
