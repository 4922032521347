import { UserPlan } from "models";
import { useState, useEffect } from "react";
import { planService } from "services";

export const useUserPlan = () => {
  const [userPlan, setUserPlan] = useState<UserPlan | undefined>();
  useEffect(() => {
    (async () => {
      const response = await planService.findUserPlanAsAdmin();
      setUserPlan(response.data);
    })();
  }, []);
  return userPlan;
};
