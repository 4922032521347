import { ChangeLanguageButton, WhatsappButton } from "../../components";
import { News } from "./sections/news";
import { OurClients } from "./sections/our-clients";
import { Section1 } from "./sections/section1";
import { Section2 } from "./sections/section2";
import { Section3 } from "./sections/section3";
import { Section5 } from "./sections/section5";
import { Section6 } from "./sections/section6";
import { Section7 } from "./sections/section7";

export const LandingPage = () => {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section3 />
      <OurClients />
      <Section5 />
      <Section6 />
      <News />
      <Section7/>
      <WhatsappButton position="left" />
      <div>
        <ChangeLanguageButton />
      </div>
    </>
  );
};
