import { MenuItem } from "@mui/material";
import { PlatformModal } from "components";
import { Question } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { QuestionModal } from "../../question-modal";
import {
  BuildInitValuesType,
  BuildQuestionFormType,
  BuildValidationsType,
  OnSubmitType,
} from "../../types";

interface Props {
  question: Question;
  loadQuestions: () => Promise<void>;
  buildInitValues: BuildInitValuesType;
  buildQuestionForm: BuildQuestionFormType;
  buildValidations: BuildValidationsType;
  onSubmit: OnSubmitType;
  suffix: string;
}
export const EditQuestionAction = ({ loadQuestions, ...questionModalProps }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const afterCompleteForm = async () => {
    await loadQuestions();
    setIsOpen(false);
  };

  const closeModal = () => setIsOpen(false);

  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>{t("custom_categories.edit")}</MenuItem>
      <PlatformModal isOpen={isOpen}>
        <QuestionModal
          closeModal={closeModal}
          afterCompleteForm={afterCompleteForm}
          {...questionModalProps}
        />
      </PlatformModal>
    </>
  );
};
