import { UserDetail } from "models";

export const buildUsersDetailsWithoutSupport = (
  usersDetails: Record<string, UserDetail>,
): Record<string, UserDetail> => {
  const usersDetailsWithoutSupport: Record<string, UserDetail> = {};

  for (const [key, value] of Object.entries(usersDetails)) {
    if (!value?.isSupport) {
      usersDetailsWithoutSupport[key] = { ...value };
    }
  }

  return usersDetailsWithoutSupport;
};
