import { Slider } from "@mui/material";
import rocketImage from "assets/icons/features/rocket.webp";
import { PlatformBox, PlatformButton } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
import { pricingPlans } from "consts";

const openWhatsapp = (message: string) => () => {
  const url = `https://api.whatsapp.com/send?phone=573125256655&text=${message}`;
  window?.open(url, "_blank")?.focus();
};

const features = [
  "access_to_all_activities",
  "an_administrator",
  "unlimited_hosts",
  "sso",
  "feelings_thermometer",
  "anonymous_feedback",
  "badge_system",
  "analytics",
];

export const Plans = () => {
  const { t } = useTranslation();

  const [usersPlan, setUsersPlan] = useState(200);

  return (
    <div className={Styles.pricingContainer}>
      <PlatformBox className={`${Styles.plan}`}>
        <div className={Styles.planName}>{t("landing.pricing.gamification")}</div>
        <div>
          <label className={Styles.planPrice}>{usersPlan}</label>
          <label>USD</label>
          <Slider
            min={pricingPlans[0].value}
            max={pricingPlans[pricingPlans.length - 1].value}
            step={null}
            value={usersPlan}
            onChange={(_, value) => setUsersPlan(value as number)}
            valueLabelDisplay="off"
            marks={pricingPlans}
          />
        </div>
        <div className={Styles.planMode}>
          {t("landing.pricing.until_n_users").replace("{}", usersPlan.toString())}
        </div>
        <ul className={Styles.planFeatures}>
          {features.map(feature => (
            <li key={feature}>
              <img className={Styles.rocket} alt="rocket" src={rocketImage} />{" "}
              {t(`landing.pricing.features.${feature}`)}
            </li>
          ))}
        </ul>
        <div>
          <PlatformButton
            label={t("landing.pricing.contact_us")}
            onClick={openWhatsapp(t("landing.whatsapp_message"))}
          />
        </div>
      </PlatformBox>
    </div>
  );
};
