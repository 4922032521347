import { UserLicenseAssignment } from "models";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  users?: Array<UserLicenseAssignment>;
  totalHosts?: number;
}

export const HostCount = ({ totalHosts, users }: Props) => {
  const { t } = useTranslation();
  if (!users) {
    return null;
  }
  const total = users.filter(u => u.isHost).length;
  return (
    <div>
      <label className={Styles.title}>{t("user_plan_management.active_hosts")}</label>
      <label className={Styles.subtitle}>
        {total} / {totalHosts || '∞'}
      </label>
    </div>
  );
};
