import { RoomType } from "enums";

export const TRANSLATION_ES = {
  landing: {
    login: "Ingresar",
    sign_up: "Me interesa",
    whatsapp_button: "¿Preguntas? ¡Escribenos!",
    whatsapp_message: "Hola, me gustaria saber mas de joinnexus",
    title: "Espacios de integración para equipos remotos",
    register_for_early_access: "Regístrese para acceso anticipado",
    contact_us: "Agenda una reunión para más información",
    book_for_more_information: "Agenda para más información",
    book_a_meeting_with_us: "Agenda una reunión con nuestro equipo",
    book_a_meeting: "Agenda una reunión",
    subtitle_1: "Las mejores",
    subtitle_1_1: "experiencias en grupo",
    subtitle_1_2: "actividades de integración",
    subtitle_1_3: "fiestas virtuales",
    thank_you_for_your_interest: "Gracias por su interés, nos pondremos en contacto.",
    focus_on_human_interaction: "¿Qué es Joinnexus?",
    focus_on_human_interaction_message: `Las mejores actividades para equipos remotos y eventos virtuales`,
    future: "El futuro es remoto",
    terms_and_conditions: "Términos y condiciones",
    privacy_policy: "Política de privacidad",
    disclaimer: {
      part_1: "He leído y entendido las ",
      part_2: "condiciones",
      part_3: " y ",
      part_4: "políticas de privacidad",
      please_accept: "Por favor acepta nuestros términos y políticas",
    },
    news: {
      title: "Novedades de Joinnexus",
      subtitle:
        "Manténgase al día sobre las noticias, aprenda las prácticas recomendadas y mucho más.",
    },
    audience: {
      title: "Herramientas de cultura",
      options: {
        personal: {
          title: "Termómetro de emociones",
          message: "Comprueba cómo están tus colaboradores y actúa en función de los resultados",
        },
        eventsCreator: {
          title: "Feedback anónimo",
          message:
            "Permite que tus colaboradores se expresen libremente, envíen sugerencias o felicitaciones",
        },
        office: {
          title: "Reconocimiento público",
          message: "Tablas de resultados por insignias e informes de resultados",
        },
        communities: {
          title: "Feedback 360",
          message: "Mide el desempeño  y las competencias de tus colaboradores.",
        },
      },
    },
    statistics: {
      statistics_1:
        "millones de estadounidenses podrían estar trabajando de forma remota para 2025.",
      statistics_2:
        "de los trabajadores remotos encuentran que la colaboración y la soledad son los mayores retos para trabajar de forma remota.",
      statistics_3: "millones de nómadas digitales en todo el mundo.",
      statistics_4:
        "las instituciones educativas planean continuar con algunas o todas las ofertas remotas después de la pandemia.",
      statistics_5: "millones de usuarios han jugado nuestras actividades.",
    },
    pricing: {
      gamification: "Gamificación",
      title: "Planes y precios",
      premium: "Premium",
      contact_us: "Contactanos",
      until_n_users: "Hasta {} usuarios",
      per_user_per_day: "Por usuario por día",
      per_user_per_month: "Por usuario por mes",
      features: {
        access_to_all_activities: "Acceso a todas las actividades",
        an_administrator: "1 administrador",
        unlimited_hosts: "Anfitriones ilimitados",
        sso: "Inicio de sesión único SSO",
        profile_settings: "Configuración de perfiles",
        feelings_thermometer: "Termómetro de emociones",
        anonymous_feedback: "Feedback anónimo",
        events: "Eventos",
        custom_subdomain: "Subdominio personalizado",
        badge_system: "Reconocimiento",
        analytics: "Analíticas para administradores",
        unlimited_admins_and_hosts: "Administradores y anfitriones ilimitados",
      },
    },
    all_the_best_features: "¿Por qué Nexus?",
    features: {
      when_to_use: {
        label: "¿Cuando usarlo?",
        options: {
          option_1: {
            title: "Momentos agradables",
            message: "Organiza un descanso durante una semana de duro trabajo con tu equipo",
          },
          option_2: {
            title: "Inducción",
            message:
              "Conserve los nuevos talentos. Con Nexus es más fácil incorporar nuevos miembros a un equipo",
          },
          option_3: {
            title: "Actividades de trabajo en equipo",
            message:
              "Juegos enfocados en aumentar la interacción y crear conversaciones entre los miembros de la sala",
          },
          option_4: {
            title: "Networking",
            message:
              "Deje de usar herramientas formales y aburridas para establecer contactos entre sus compañeros",
          },
          option_5: {
            title: "Cumpleaños",
            message:
              "¿Qué tal una fiesta de cumpleaños virtual?. En Nexus puedes encontrar los mejores juegos para fiestas virtuales",
          },
          option_6: {
            title: "Ceremonias de premiación para los empleados",
            message:
              "Cada premio merece una celebración. Con Nexus tienes la oportunidad de celebrar con los que están lejos",
          },
        },
      },
      features: {
        label: "Características",
        options: {
          option_1: {
            title: "Premios",
            message:
              "Nexus premia las habilidades, siendo las habilidades blandas las principales. Nexus también está pensado para todos los públicos",
          },
          option_2: {
            title: "Personalizado",
            message:
              "Puedes utilizar nuestros juegos y adaptarlos según las preferencias de tu equipo o tu evento",
          },
          option_3: {
            title: "Juegos originales",
            message:
              "10 juegos exclusivos diseñados para jugar virtualmente. Enfocado en mejorar la interacción humana",
          },
          option_4: {
            title: "Tipos de usuarios",
            message:
              "Otorga los permisos que estimes necesarios para cada uno de los miembros de tu equipo / evento",
          },
          option_5: {
            title: "Diferentes idiomas",
            message: "Ahora, cada internauta puede elegir entre ingles, español y portugues",
          },
          option_6: {
            title: "Integraciones de terceros",
            message:
              "Integraciones con Microsoft Teams, Slack y Google. Exponemos API pública para que puedas integrar tu HRMS con Nexus",
          },
        },
      },
    },
    contact_form: {
      label: "¿Está interesado?",
      affair: "Asunto",
      message: "Mensaje",
      organization: "Organización",
      send: "Enviar el formulario",
    },
    faq: "Preguntas frecuentes",
  },
  bill: {
    pay_pending_bills: "Pagar ordenes pendientes",
    you_have_no_pending_bills: `No tienes ordenes pendientes`,
  },
  custom_categories: {
    category: "Categoría",
    add_questions: "Añadir preguntas",
    edit: "Editar",
    deactivate: "Desactivar",
    add_category: "Añadir categoría",
    edit_category: "Editar categoría",
    type_the_category_name_in: "Escribe el nombre de la categoría en",
    translate_from: "Traducir a partir del",
    the_names_of_the_category_were_translated_correctly:
      "Los nombres de la categoría se tradujeron correctamente.",
    select_the_languages_you_want_us_to_help_you_with_the_translation:
      "¿Quieres hacer una traducción manual o automática?",
    manual: "Manual",
    automatic: "Automática",
    missing_fields_to_translate_check_the_following_translations:
      "Faltan campos por traducir, revisa las siguientes traducciones",
    select_a_game: "Selecciona un juego",
  },
  custom_questions: {
    question: "Pregunta",
    add_question: "Añadir pregunta",
    edit_question: "Editar pregunta",
    type: "Escribe",
    in: "en",
    the_question: "la pregunta",
    add_topic: "Añadir tema",
    edit_topic: "Editar tema",
    the_topic: "el tema",
    topic: "Tema",
    add_word: "Añadir palabra",
    edit_word: "Editar palabra",
    the_word: "la palabra",
    word: "Palabra",
    add_sentence: "Añadir afirmación",
    edit_sentence: "Editar afirmación",
    the_sentence: "la afirmación",
    sentence: "Afirmación",
    right_option: "la opción correcta",
    wrong_option: "la opción incorrecta",
  },
  common: {
    update: "Actualizar",
    from: "De",
    to: "Para",
    no_data: "Sin datos",
    filter_by_name: "Filtrar usuarios por nombre",
    load_more: "Cargar más",
    use_one_recommended_browsers: "Por favor, utiliza uno de los navegadores recomendados",
    host: "Host",
    name: "Nombre",
    email: "Correo electrónico",
    is_required: "es requerido",
    enter_a_valid_email: "Ingrese un correo electrónico valido",
    all_rights_reserved: "Todos los derechos reservados",
    instructions: "Instrucciones",
    categories: "Categorías",
    lets_start_the_game: `Empecemos la fiesta`,
    waiting_for: "Esperando a que",
    votes: "vote",
    vote: "voten",
    option: "Opción",
    users: "Jugadores",
    results: "Resultados",
    voting_results: "Resultados de la votación",
    to_start_the_game: "inicie la partida",
    lets_start: "Vamos a iniciar",
    continue: "Continuar",
    to_continue: "continue",
    to_start_story_time: "inicie",
    vote_for: "Votar por",
    select_an_user_please: "Seleccione un jugador, por favor",
    lets_continue_with_next_question: `Sigamos con la siguiente pregunta`,
    select_at_least_1_category: "Elije al menos una categoría por favor",
    please_allow_us_to_access_your_camera_and_microphone:
      "Permítenos acceder a tu cámara y micrófono, por favor",
    retry: "Volver a intentar",
    user_configuration: "Configuración de usuario",
    go_back: "Volver",
    yes: "Si",
    no: "No",
    no_messages: "No hay mensajes",
    time_remain: "Tiempo restante",
    winners: "Ganadores",
    player: "Jugador",
    invite_your_teammates_to_the_party: "Invita al resto del equipo a la fiesta",
    share_link: "Compartir link",
    link_copied_to_the_clipboard:
      "Link copiado al portapapeles. Compartelo con el resto del equipo.",
    select_max_bombs_amount: "Seleccione la cantidad máxima de bombas",
    show_results: "Mostrar resultados",
    only_the_host_can_select_a_category: "Solo host puede seleccionar una categoría",
    its_your_turn: "Es tu turno, echa un vistazo a las instrucciones",
    select_max_stars_amount: "Seleccione la cantidad máxima de estrellas",
    you_need: "Necesitas",
    more_players: "jugadores mas",
    more_player: "jugador mas",
    cancel: "Cancelar",
    accept: "Aceptar",
    activate: "Activar",
    inactivate: "Desactivar",
    close: "Cerrar",
    id: "ID",
    save: "Guardar",
    minutes: "minutos",
    the_field: "El campo",
    must_be_greater_than_or_equal_to: "debe ser igual o mayor a",
    date: "Fecha",
    user: "Usuario",
    room_type: "Tipo de sala",
    actions: "Acciones",
    game: "Juego",
    learn_more: "Ver ahora",
    surrender: "Rendirme",
    skip_player: "Saltar jugador",
    export_xlsx: "Exportar a excel",
    feeling: "Sentimiento",
    send: "Enviar",
  },
  errors: {
    this_is_the_email_you_used: "Usaste el siguiente correo para ingresar el cual no es valido:",
    please_use_an_email_from_the_enterprise: "Por favor, haz uso de un correo empresarial.",
    feature_only_supported_by_the_enterprise_plan:
      "Para crear eventos, necesitas el plan Enterprise. Comunicate con tu administrador.",
    check_your_internet_connection: "Revise su conexión a internet",
    user_has_an_active_room:
      "Tienes una sala existente funcionando. ¿Te gustaría eliminarla y crear una nueva?",
    another_event_is_running:
      "Tienes un evento existente funcionando. ¿Te gustaría eliminarlo y crear uno nuevo?",
    pay_as_you_go_limit_has_reached:
      "El limite máximo ha sido alcanzado. Recarga la página para actualizar el campo de consumo.",
    credit_card_generation_error:
      "Ha habido un error probando tu tarjeta. Revisa los datos ingreso e intenta de nuevo",
    contact_joinnexus_team: "Por favor, contacta al equipo de joinnexus",
    you_must_use_your_own_payment_method: "Por favor, usa un método de pago tuyo",
    you_must_have_standard_plan: "Debes tener un plan premium",
    payment_was_not_successful:
      "Hubo un error en el pago, por favor revisa los fondos del método de pago seleccionado",
    no_active_plan: "No tienes un plan activo, por favor contacta a tu administrador",
    event_has_not_started:
      "El evento aún no ha comenzado. Espera la hora de inicio del evento para ingresar",
    event_has_ended: "El evento ya finalizó",
  },
  login: {
    sign_in_with: "Ingresar con",
    sign_up_with: "Registrarse con",
    logout: "Cerrar sesión",
    go_to_login: 'Ir a iniciar sesión',
  },
  controls: {
    mute: "Silenciar",
    unmute: "Activar sonido",
    turn_on_camera: "Prender cámara",
    turn_off_camera: "Apagar cámara",
    show: "Mostrar cámara",
    hide: "Esconder cámara",
  },
  rooms: {
    please_upgrade_your_account_to_create_a_room:
      "Actualice su plan de cuenta para poder crear una sala",
    create_a_room: "Crear una sala",
    owner_deleted_your_room: "El dueño de la sala la eliminó",
    you_do_not_have_host_permissions:
      "No tienes permisos de anfitrión. Contacta al administrador para solicitarlos.",
    faq: "Preguntas frecuentes",
    create_a_new_room: "Crear una nueva sala",
    [RoomType.ICEBREAKER]: "Rompehielos",
    [RoomType.COFFEE_TIME]: "Hora de café",
    [RoomType.MEET_AND_CHILL]: "Actividad de integración",
    notiy_room_close: "La sala expirará en 5 minutos",
    close_room: "La sala ha expirado",
    link_after_room: "Link a redireccionar al finalizar la sala",
  },
  events: {
    create_an_event: "Crear un evento",
    go_event: "Ir al evento",
    room_id: "ID de la sala",
    event_name: "Nombre",
    start_date: "Fecha de inicio",
    end_date: "Fecha de fin",
    room_type: "Tipo de evento",
    delete_event: "Cerrar evento",
    edit_event: "Editar evento",
    host: "Anfitrión",
    are_you_sure_you_want_to_delete: "¿Estás seguro de que quieres cerrar el evento?",
    link_after_room: "Link a redireccionar al finalizar el evento",
  },
  event: {
    members: "Asistentes",
    people_in_room: "Personas en la sala",
    share_event_link: "Compartir enlace del evento",
    people_in_event: "Personas en el evento",
    settings: "Configuración del evento",
    max_users_per_room: "Número máximo de usuarios por sala",
    event_rooms: "Salas del evento",
    event_updated: "Evento actualizado",
    expiration_date: "Fin del evento",
    strict_mode: "Modo estricto",
    max_users_per_room_help: "Aquí indicas cuántos usuarios máximo debe haber por sala.",
    expiration_date_help: "En esta fecha, el evento se eliminará automáticamente.",
    priority_host_email: "Correo del anfitrión prioritario",
    strict_mode_help:
      "Cuando está habilitado, los usuarios no pueden ingresar a las salas que están llenas, incluso si usa el enlace directo de la sala.\n Cuando está deshabilitado, los usuarios se dividen en salas de acuerdo con el número máximo de usuarios por sala, pero si tienen el enlace a la sala, incluso si está llena, pueden ingresar.",
  },
  room: {
    choose_a_game: "Escoge un juego",
    waiting_for: "Esperando a que",
    to_select_a_game: "seleccione un juego",
    loading_account: "Cargando cuenta",
    ask_to_your_manager: "Estas ingresando con esta cuenta",
    ask_to_your_manager_suffix:
      ", la cual no cuenta con un plan activo. Prueba con tu correo corporativo o contacta con el/la administrador de Joinnexus",
    try_again: "Vuelve a intentar cargar el plan",
    go_room: "Ir a la sala",
    is_full: "La sala se encuentra llena sin embargo, puedes ingresar a otra sala en el evento.",
    go_to_another_room: "Ir a otra sala",
    request_host: "Solicitar ser anfitrión",
    are_you_sure_you_want_to_host_the_room: "¿Estás seguro que deseas ser el anfitrión de la sala?",
    final_score_table: "Clasificación final",
    leave_the_room: "Salir de la sala",
  },
  games: {
    never_have_i_ever: "Yo nunca",
    most_likely_to: "¿Quién es más probable?",
    a_lie: "Dos verdades y una mentira",
    charades: "Charadas",
    hypotethically: "Hipotéticamente",
    debate: "Debate",
    think_fast: "Piensa rápido",
    who_am_i: "¿Quien soy?",
    deep_questions: "Preguntas profundas",
    trivia: "Trivia",
  },
  instructions: {
    never_have_i_ever: {
      paragraph1:
        "El mítico juego de YO NUNCA. De acuerdo con las estadísticas, más del 99% de la población conoce este juego (sí no lo conoces seguramente creciste en una cueva o tienes más de 80 años). Este juego tiene solo 2 reglas básicas, pero son INVIOLABLES. La primera es decir la verdad, solo la verdad y nada más que la verdad. Y la segunda es divertirse hasta mas no poder. A continuación, un resumen de cómo funciona el 'YO NUNCA DE NEXUS':",
      paragraph2: "Lo primero que debes hacer es escoger la categoría.",
      paragraph3:
        "Una vez dentro del juego, Nexus irá realizando preguntas de 'YO NUNCA', las cuales aparecerán en pantalla.",
      paragraph4:
        "Si has hecho lo que aparece en pantalla, oprimes el botón 'SI LO HE HECHO'. De lo contrario oprimes 'NO LO HE HECHO'.",
      paragraph5:
        "Una vez todos los participantes de la sala han respondido, Nexus escogerá a algunos para que compartan su historia con el grupo.",
      paragraph6:
        "Las personas seleccionadas, tendrán la opción de compartir su historia con el grupo. Sí has sido seleccionado, adelante cuéntanos tu historia.",
      paragraph7:
        "Nexus va a revelar tus más oscuros secretos ... Es broma, relájate y Nexus se encargará del resto.",
    },
    most_likely_to: {
      paragraph1:
        "Sí alguna vez has salido de fiesta, seguramente conoces este juego. Nexus trae a la virtualidad el juego más popular de una forma que jamás has jugado.",
      paragraph2: "Lo primero que debe hacer el host de la partida es escoger la categoría.",
      paragraph3:
        "Una vez dentro del juego, Nexus irá realizando preguntas acerca de 'Quien es más probable que realice una acción'.",
      paragraph4:
        "En cada una de esas preguntas debes votar por quien consideres sea el más probable en realizar esa acción.",
      paragraph5:
        "Una vez todos lo miembros de la sala han votado, Nexus indicará quien ha sido el participante a quien el grupo ha escogido.",
      paragraph6:
        "El participante elegido, deberá contar a los demás miembros del grupo por qué cree que ha sido el más votado.",
      paragraph7:
        "¿Quieres saber qué opinión tienen los demás sobre ti? Prueba este juego y te sorprenderás.",
    },
    a_lie: {
      paragraph1:
        "¿Qué tan buen mentiroso eres? En este juego, Nexus premia tus habilidades para mentir e influir sobre las respuestas de los demás.",
      paragraph2:
        "Al iniciar, Nexus escogerá a un participante, el cual tendrá que contar 3 historias o afirmaciones diferentes acerca de su vida. IMPORTANTE : Una de esas historias debe ser falsa!. ",
      paragraph3: "En este juego existen 2 roles:",
      paragraph4: `Es el participante que debe contar las 3 historias. Sí es tu turno, debes contar tus historias o afirmaciones y seleccionar cual de esas 3 es la mentira. (De acuerdo con el orden con el que las contaste).`,
      paragraph5:
        "Es el participante que tiene la responsabilidad de escoger cual de las 3 historias es mentira. Sí no es tu turno de contar las historias, eres un oyente. Tu deber es escuchar (obvio :p) las 3 historias de quien es el relator y a partir de allí, escoges cual de las historias es la falsa.",
      paragraph6: "Sí eres relator y nadie identifica tu historia falsa, eres un gran mentiroso.",
      paragraph7:
        "Sí eres oyente y logras identificar cuál historia es la falsa, deberías considerar volverte detective.",
    },
    charades: {
      paragraph1: `Mucho se ha hablado del lenguaje verbal y la facilidad que este nos da para expresar nuestras ideas, pero ¿Qué pasaria si tuviesemos que comunicarnos sin hacer sonidos? Vamos a explorar que tan buenos somos para expresarnos sin palabras con charadas.`,
      paragraph2: `Un jugador es escogido para actuar una palabra y los demas deberan adivinarla. Una vez el jugador ha leido la palabra, hace click en 'Continuar'.`,
      paragraph3: `Los demas jugadores tendran 1 minuto para adivinar la palabra escribiendola en el chat.`,
      paragraph4: `Pasado 1 minuto, se mostrarán los jugadores que adivinaron la palabra.`,
      paragraph5: `Vamos a ver qué tan bueno es el lenguaje no verbal de esta sala.`,
    },
    hypotethically: {
      paragraph1:
        "Hay quienes dicen que para conocer a una persona basta con ver lo que hace, o en este caso, lo que haría. Con este juego, Nexus entra en el terreno de los hipotéticos.",
      paragraph2:
        "Para empezar, el host de la partida debe escoger una categoría. Una vez dentro, Nexus seleccionará aleatoriamente a un jugador cada ronda para que responda una pregunta hipotética que se mostrará en pantalla.",
      paragraph3:
        "La idea es que la persona responda y justifique su respuesta y los demás miembros de la sala pueden comentarla.",
      paragraph4: "Luego de responder, oprimes en el botón “SIGUIENTE”. ",
    },
    think_fast: {
      paragraph1: `Esta vez, Nexus pondrá a prueba tus habilidades. En piensa rápido, no gana el que más sabe, sino el que responde más rápido.`,
      paragraph2: `En cada ronda, Nexus da un tema. Cada internauta debe responder al tema dado por Nexus en menos de 10 segundos. En la pantalla se indicará quién toma el turno y quién es el siguiente.`,
      paragraph3: `Cada vez que sea tu turno, debes responder ( en voz alta) y una vez has respondido debes dar clic en el botón "ya dije la palabra"`,
      paragraph4: `De esta forma, el turno pasa al siguiente internauta hasta que uno no pueda responder. Cada vez que no puedas responder, estarás acumulando "Bombas Radiactivas", que son tóxicas en altas dosis. El juego finaliza cuando un usuario alcanza un número determinado de bombas radiactivas (puedes ajustarlo manualmente en la barra de abajo) Nexus otorgará premios a los que menos hayan perdido (bombas acumuladas).`,
      paragraph5: `Demuestra tu agilidad mental y recuerda que siempre puedes mejorar…`,
    },
    debate: {
      paragraph1: `IOS o Android? Facebook o Twitter? Playa o Montaña?`,
      paragraph2: `Para gustos, los colores. En Nexus somos consientes que en la diferencia está el placer y hemos decidido llevar los debates a un siguiente nivel.`,
      paragraph3: `Cada ronda, Nexus mostrará en pantalla una afirmación, la cual los internautas deberán indicar si están de acuerdo o no.`,
      paragraph4: `Una vez todos los internautas escojan sus respuestas (De acuerdo o No), Nexus escogerá a 2 internautas. Uno que respondió “De acuerdo” y otro que respondió “En desacuerdo” para que compartan sus puntos de vista e intenten convencer a los demás de que su postura es la mejor.`,
      paragraph5: `La idea del debate es conocer mejor las preferencias de cada uno de los internautas y divertirse mientras se realiza un intercambio de ideas.`,
    },
    who_am_i: {
      paragraph1: `Cada ronda Nexus seleccionará a un internauta que tendrá que adivinar un personaje únicamente haciendo preguntas a otros internautas. Las preguntas deben formularse de la siguiente manera: ¿Soy ____? Ejemplo: ¿Soy un político contemporáneo? Cuando creas que sabes la respuesta, debes escribirla en el recuadro que aparecerá debajo de tu cámara. Prueba todo lo que puedas ya que tienes que adivinar en tan solo 1 minuto.`,
      paragraph2: `Si no es tu turno, debes estar atento a las preguntas que te haga el internauta que toma el turno. Solo debe responder las preguntas con SI o NO. Ejemplo: ¿Soy un político contemporáneo? Respuesta: NO.`,
      paragraph3: `Podrás ver el personaje ya que aparecerá en la pantalla del internauta que saque el turno.`,
      paragraph4: `Consejo: Si presionas el botón con el signo de la lupa, podrás ver preguntas típicas que pueden serte útiles.`,
      paragraph5: `Al final de cada partida, Nexus premiará a los internautas con las mejores puntuaciones.`,
    },
    deep_questions: {
      paragraph1: `Sin duda, con “Preguntas profundas” tendrás la oportunidad de conocer a los demás internautas de la sala de una manera genuina. Respuestas a las preguntas que nadie se atrevería a formular.`,
      paragraph2: `Cada ronda, Nexus escogerá a un internauta para que responda una pregunta. El internauta escogido, aparecerá en una pantalla más grande que los demás internautas.`,
      paragraph3: `Sí es tu turno de responder y ya lo has hecho, debes dar click en el botón "Siguiente", para pasar el turno a alguien más.`,
      paragraph4: `Mientras tanto, los demás internautas que están escuchando la respuesta a la pregunta, tendrán la posibilidad de reaccionar a la respuesta con los siguientes sentimientos.`,
      paragraph5: `Al final de la partida, Nexus premiará a los internautas de acuerdo con las reacciones que obtuvieron.`,
      paragraph6: `En Nexus no gana el mejor, gana el equipo`,
    },
    trivia: {
      paragraph1: `En Nexus, creemos que todos los seres humanos somos expertos en algo, así no lo sepamos. Por esta razón hemos creado esta Trivia que recorre todos los temas, desde rock clásico de los 80s, pasando por arte renacentista hasta personajes de videojuegos populares.`,
      paragraph2: `Cada ronda, Nexus mostrará en pantalla una pregunta con 4 opciones de respuesta. Los usuarios deben responder a las preguntas escogiendo la opción que cada uno considere correcta.`,
      paragraph3: `Al final de cada partida, Nexus premiará a los internautas con mejores puntajes. En este juego buscamos mostrarte y mostrar al grupo en que campos eres un experto.`,
      paragraph4: `El juego termina cuando alguno de los internautas acumula cierta cantidad de estrellas (puedes modificar este numero en la barra inferior de esta pantalla).`,
      paragraph5: `Recuerda que no hay perdedores, solo expertos en otros campos del conocimiento.`,
    },
  },
  who_am_i: {
    type_your_guess: "Escribe tu respuesta",
    clue_1: "¿Soy humano?",
    clue_2: "¿Soy un animal?",
    clue_3: "¿Soy joven?",
    clue_4: "¿Soy un personaje histórico?",
    clue_5: "¿Me encuentro vivo?",
    its_your_turn:
      "Es tu turno. Haz preguntas de sí/no a otros jugadores y escribe el nombre del personaje que crees que eres en el cuadro de abajo.",
  },
  most_likely_to: {
    question_prefix: "¿Quién es más probable que",
    storyTimePrefix: "Cuentanos por qué crees que los demás te escogieron",
    storyTimeSuffix: "nos va a decir por qué cree que lo/la elegiste",
  },
  never_have_i_ever: {
    prefix: "Nunca",
  },
  think_fast: {
    say_a_word_about: "Di una palabra relacionada con",
    i_already_said_the_word: "Ya dije la palabra",
    break_time: "Descanso",
  },
  a_lie: {
    listener: "Oyente",
    storyteller: "Relator",
    is_going_to_tell_us_two_truths_and_a_lie: `nos va a decir 2 verdades y 1 mentira. Tienes que adivinar cuál es la mentira y hacer clic en la opción.`,
    first: "La primera historia",
    second: "La segunda historia",
    third: "La tercera historia",
    is_the_lie: "es la mentira",
    you_have_to_tell_to_the_other_players_two_truths_and_a_lie:
      "Tienes que decirles a los otros jugadores 2 verdades y 1 mentira. Después de eso, selecciona cual opción es la mentira.",
    choose_the_lie: "escogen la mentira",
    chooses_the_lie: "escoga la mentira",
  },
  charades: {
    you_have_been_chosen_to_act_out_this_word: "Has sido elegido para actuar esta palabra",
    has_been_chosen_to_act_out_a_word:
      "ha sido seleccionado para representar una palabra, préstale atención y adivina la palabra",
    you_have_to_guess_the_word_that: "Tienes que adivinar la palabra que",
    is_acting_out: "esta actuando",
    you_have_to_act_out_this_word: "Tienes que actuar esta palabra",
    type_the_word_that: "Escribe la palabra que",
    awesome_you_discovered_the_word: "Asombroso, lograste descubrir la palabra",
    word: "Palabra",
    nobody_guessed_the_word: "Nadie adivinó la palabra. La palabra era",
    think_your_performance_and: "Piensa cómo representar la palabra y",
    click_on_the_button: "haz clic en el botón para iniciar",
    secret_word_was: "La palabra secreta era",
  },
  hypotethically: {
    prefix: "¿Qué harías si",
    storyTimePrefix: "Cuentanos: ¿Que harías si",
    storyTimeSuffix: "va a decirnos qué haría si",
  },
  deep_questions: {
    storyTimePrefix: "Cuéntanos: ",
    storyTimeSuffix: "va a contarnos",
  },
  debate: {
    agree: "De acuerdo",
    disagree: "En desacuerdo",
    all_users_agreed_with: "Todos los jugadores estuvieron de acuerdo con",
    all_users_disagreed_with: "Todos los jugadores estuvieron de desacuerdo con",
  },
  story_time: {
    lets_start_story_time: `Empecemos a compartir nuestras historias`,
    story_time_instructions: `Nexus ha seleccionado jugadores para que nos compartan sus historias ¿Están listos?`,
    hey_lets_tell_us_your_story_with: `Hey, cuentanos tu historia con`,
    is_going_to_tell_us_his_her_story_with: `va a contarnos su historia con`,
    lets_listen_the_next_story: `Pasar turno`,
    to_start_story_time: "inicie el momento de compartir historias",
  },
  account: {
    user_information: "Información del usuario",
    plan: "Plan",
    update_user_information: "Actualizar información del usuario",
    bills: "Ordenes",
    pay_as_you_go_plan: "Pago por uso",
  },
  languages: {
    en: "Inglés",
    es: "Español",
    pt: "Portugués",
    change_language: "Cambiar idioma",
  },
  messages: {
    try_to_move_your_camera: "Tu puedes mover tu cámara, pruebalo",
    your_feedback_is_important_to_us:
      "Tu opinión es importante para nosotros, ¿podría dárnosla haciendo clic en este mensaje? No te tomará mas de 5 minutos",
  },
  user_plan_management: {
    title: "Administrador de usuarios",
    is_host: "¿Es anfitrión?",
    is_admin: "¿Es administrador?",
    set_as_host: "Añadir a los anfitriones",
    remove_from_hosts: "Quitar de los anfitriones",
    set_as_admin: "Añadir a los administradores",
    remove_from_admins: "Quitar de los administradores",
    user: "Usuario",
    status: "Estado",
    actions: "Acciones",
    add_user: "Agregar usuario",
    active_users: "Usuarios activos",
    active_hosts: "Anfitriones activos",
    active_admins: "Administradores activos",
    write_the_email_of_the_user_you_will_add:
      "Escribe el correo electrónico y el nombre del usuario que deseas añadir",
    active: "Activo",
    inactive: "Inactivo",
    the_premium_user: "El usuario premium",
    has_been_added: "ha sido agregado satisfactoriamente a joinnexus.",
    surely_this_calls_for_a_celebration: "Sin duda, esto amerita una celebración.",
    warning_1:
      "Recuerde que sí el usuario ha ingresado al menos una vez a la plataforma durante el actual periodo facturado, no podrá agregar otro usuario en su lugar hasta el próximo periodo de facturación. En caso contrario, podrá agregar otro usuario al instante",
    warning_2: "¿Estás seguro que quieres desactivar el acceso de",
    filter: "Filtrar por nombre o email",
    add_users_to_plan: "Añadir usuarios al plan",
    users_to_buy_for_the_plan: "Cantidad de cuentas a comprar",
    accounts_added_successfully: "Cuentas añadidas correctamente",
    payment_by_payu: "Pago por payu",
  },
  billing: {
    you_have_reach_the_limit_of_active_users:
      "Has alcanzado el límite de usuarios activos. Puedes adquirir una nueva licencia para agregar un nuevo usuario.",
    you_have_reach_the_limit_of_hosts:
      "Has alcanzado el límite de usuarios anfitriones, si necesitas más usuarios anfitriones, comunícate con el equipo de Joinnexus.",
    you_have_reach_the_limit_of_admin:
      "Has alcanzado el límite de administradores. Puedes adquirir una nueva licencia para agregar un nuevo usuario.",
    you_have_reach_the_limit_of_users_in_your_billing_period:
      "Has alcanzado el límite de usuarios en su período de facturación. Puedes adquirir una nueva licencia para agregar este nuevo usuario.",
    payment_date: "Fecha de pago",
    amount: "Cantidad",
    currency: "Moneda",
    accounts_amount: "Cantidad de cuentas",
    payment_method: "Método de pago",
    billing_information: "Información de facturación",
    expiration_date: "Fecha de expiración",
    transaction_id: "Transacción de payU",
    pay_bill: "Pagar orden",
  },
  menu: {
    profile: "Perfil",
    users: "Usuarios",
    bills: "Facturación",
    logout: "Cerrar sesión",
    pay_as_you_go: "Tu consumo",
    payment_methods: "Métodos de pago",
    custom_categories: "Categorías personalizadas",
    custom_questions: "Preguntas personalizadas",
    anonymous_feedback: "Sugerencias anónimas",
    learning_center: "Centro de aprendizaje",
    protons_center: "Centro de reconocimiento",
    events: "Eventos",
    analytics: "Analítica",
    feedback_360: "Feedback 360",
  },
  analytics: {
    metric: "Métrica",
    feeling_per_month: "Sentimientos por mes",
    month_year: "Mes y año",
    protons_in_period: "Reconocimientos en un período de tiempo",
  },
  feedback_360: {
    self_assessment_score: "Puntuación de autoevaluación",
    peers_score: "Puntuación de los compañeros",
    start_feedback_360_process: "Comenzar feedback 360",
    send_feedback_360: "Enviar feedback 360",
    give_feedback_360: "Dar feedback",
    feedback_requests: "Solicitudes de feedback",
    feedback_received: "Feedback recibido",
    no_feedback_requests: "No hay solicitudes de feedback",
    no_feedbacks: "No hay feedbacks",
    open_feedback: "Abrir feedback",
    waiting_for_feedback: "Esperando por feedback",
    feedback_results: "Resultados",
    answer_the_following_questions_regarding_the_performance_of:
      "Responde las siguientes preguntas sobre el desempeño de",
    answer_the_following_questions_for_yourself: "Responde las siguientes preguntas para ti mismo",
    any_comments_you_want_to_add: "¿Algún comentario que quieras añadir?",
    comments: "Comentarios de la pregunta",
    feedback_suffix:
      "Para obtener una visión más precisa de tu desempeño, agrega el correo electrónico de quien deseas solicitar feedback sobre tu trabajo en los roles a continuación.",
    feedback_suffix_2: "Si no tienes a nadie para un rol, deja la casilla en blanco.",
    subordinate: "Correo de un subordinado",
    peer: "Correo de un colega",
    supervisor: "Correo de un supervisor",
    add_a_rating_please: "Agregue una calificación, por favor",
    at_least_somebody_else:
      "Añade al menos una persona más (subordinado, colega o supervisor) para recibir su feedback.",
    error_in_form: "Hay un error en el formulario, por favor revisa",
  },
  anonymous_feedback: {
    no_anonymous_feedback: "No tienes sugerencias anónimas",
    add_anonymous_feedback: "Dar sugerencia anónima",
    feedback: "Sugerencia anónima",
    who_is_going_to_receive_your_feedback: "¿Quién va a recibir la sugerencia anónima?",
    write_your_feedback: "Escribe tu sugerencia anónima",
    send_feedback: "Enviar sugerencia anónima",
  },
  proton_center: {
    proton_center: "Centro de reconocimiento",
    no_protons: "Todavía no has dado reconocimientos",
    select_user_title: "Selecciona a quién le darás el reconocimiento",
    give_proton_title: "Selecciona el reconocimiento y deja un mensaje increíble!",
    to: "Para:",
    load_more: "Cargar más",
    why_are_you_sending_this_proton:
      "¿Por qué quieres enviar este reconocimiento? Cuéntanos la historia",
    send_proton: "Enviar reconocimiento",
    proton: "Reconocimiento",
    no_proton: "Sin reconocimiento",
    protons_count: "Conteo de reconocimientos",
  },
  faq: {
    how_create_new_room: "¿Cómo crear una sala?",
    how_add_new_users: "¿Cómo agregar nuevos usuarios?",
    what_are_admins: "¿Qué son los administradores?",
    what_are_hosts: "¿Qué son los anfitriones?",
  },
  pay_as_you_go: {
    plan_limit: "Ingresos máximos a la plataforma",
    consumption: "Accesos hasta el momento",
    plan_updated: "Plan actualizado",
    entrances: "Ingresos",
    room_name: "Nombre de sala",
    room_owner: "Dueño de sala",
    room_created_at: "Fecha de ingreso a sala",
    month_and_year: "Período de consumo (Mes/Año)",
    pay_as_you_go_consumption: "consumo_del_plan",
  },
  payment_methods: {
    add_credit_card: "Añadir tarjeta de crédito",
    delete_credit_card: "Borrar tarjeta de crédito",
    credit_card_added_successfully: "Tarjeta de crédito añadida correctamente",
    credit_card_deleted_successfully: "Tarjeta de crédito borrada correctamente",
    identificationNumber: "Número de identificación",
    franchise: "Franquicia",
    number: "Número de tarjeta",
    expirationMonth: "Mes de expiración",
    expirationYear: "Año de expiración",
    are_you_sure_you_want_to_delete_the_credit_card:
      "¿Es seguro que deseas eliminar la tarjeta de credito",
    shippingAddress: "Dirección",
    shippingCity: "Ciudad",
    shippingState: "Departamento",
    shippingCountry: "País",
    postalCode: "Código postal",
    phone: "Teléfono",
  },
  learning_center: {
    filter_posts: "Filtrar publicaciones",
  },
  feelings: {
    tell_us_how_do_you_feel_today: "¿Cómo te sientes hoy?",
    overwhelmed: "Abrumad@",
    sad: "Triste",
    neutral: "Neutral",
    fine: "Bien",
    excited: "Emocionad@",
  },
  slots_management: {
    current_plan: "Plan actual",
    renovation_plan: "Plan a renovar",
    slots: "cuentas",
    available_slots: "Cuentas disponibles",
    update_renovation_plan: "Actualizar plan a renovar",
    update_current_plan: "Actualizar plan actual",
    subdomains_management: "Administración de dominios",
  },
  months: {
    jan: "Enero",
    feb: "Febrero",
    mar: "Marzo",
    apr: "Abril",
    may: "Mayo",
    jun: "Junio",
    jul: "Julio",
    aug: "Agosto",
    sep: "Septiembre",
    oct: "Octubre",
    nov: "Noviembre",
    dec: "Diciembre",
  },
  countries: {
    AF: "Afganistán",
    AX: "Islas Aland",
    AL: "Albania",
    DZ: "Argelia",
    AS: "Samoa Americana",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antártida",
    AG: "Antigua y Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Bahréin",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Bielorrusia",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BT: "Bután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botsuana",
    BV: "Isla Bouvet",
    BR: "Brasil",
    IO: "Territorio Británico del Océano Índico",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canada",
    CV: "Cabo Verde",
    KY: "Islas Caimán",
    CF: "República Centroafricana",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Isla de Navidad",
    CC: "Islas Cocos (Keeling)",
    CO: "Colombia",
    KM: "Comoras",
    CG: "Congo",
    CD: "República Democrática del Congo",
    CK: "Islas Cook",
    CR: "Costa Rica",
    CI: "Costa de Marfil",
    HR: "Croacia",
    CU: "Cuba",
    CY: "Chipre",
    CZ: "República Checa",
    DK: "Dinamarca",
    DJ: "Yibuti",
    DM: "Dominica",
    DO: "República Dominicana",
    EC: "Ecuador",
    EG: "Egipto",
    SV: "El Salvador",
    GQ: "Guinea Ecuatorial",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Etiopía",
    FK: "Islas Falkland (Malvinas)",
    FO: "Islas Feroe",
    FJ: "Fiji",
    FI: "Finlandia",
    FR: "Francia",
    GF: "Guayana Francesa",
    PF: "Polinesia Francesa",
    TF: "Territorios Australes Franceses",
    GA: "Gabón",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Alemania",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grecia",
    GL: "Groenlandia",
    GD: "Granada",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Biss au",
    GY: "Guyana",
    HT: "Haití",
    HM: "Isla Heard e Islas Mcdonald",
    VA: "Santa Sede (Estado de la Ciudad del Vaticano)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungría",
    IS: "Islandia",
    IN: "India",
    ID: "Indonesia",
    IR: "Irán",
    IQ: "Irak",
    IE: "Irlanda",
    IM: "Isla de Man",
    IL: "Israel",
    IT: "Italia",
    JM: "Jamaica",
    JP: "Japón",
    JE: "Jersey",
    JO: "Jordania",
    KZ: "Kazajstán",
    KE: "Kenia",
    KI: "Kiribati",
    KR: "Corea",
    KP: "Corea del Norte",
    KW: "Kuwait",
    KG: "Kirguistán",
    LA: "República Democrática Popular Lao",
    LV: "Letonia",
    LB: "Líbano",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Jamahiriya Árabe Libia",
    LI: "Liechtenstein",
    LT: "Lituania",
    LU: "Luxemburgo",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malasia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Islas Marshall",
    MQ: "Martinica",
    MR: "Mauritania",
    MU: "Mauricio",
    YT: "Mayotte",
    MX: "México",
    FM: "Micronesia",
    MD: "Moldavia",
    MC: "Mónaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Marruecos",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Países Bajos",
    AN: "Antillas Holandesas",
    NC: "Nueva Caledonia",
    NZ: "Nueva Zelanda",
    NI: "Nicaragua",
    NE: "Níger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Isla Norfolk",
    MP: "Islas Marianas del Norte",
    NO: "Noruega",
    OM: "Omán",
    PK: "Pakistán",
    PW: "Palau",
    PS: "Territorio Palestino",
    PA: "Panamá",
    PG: "Papua Nueva Guinea",
    PY: "Paraguay",
    PE: "Perú",
    PH: "Filipinas",
    PN: "Pitcairn",
    PL: "Polonia",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunión",
    RO: "Rumanía",
    RU: "Federación Rusa",
    RW: "Ruanda",
    BL: "San Bartolomé",
    SH: "Sain t Helena",
    KN: "San Cristóbal y Nieves",
    LC: "Santa Lucía",
    MF: "San Martín",
    PM: "San Pedro y Miquelón",
    VC: "San Vicente y las Granadinas",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Santo Tomé y Príncipe",
    SA: "Arabia Saudita",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leona",
    SG: "Singapur",
    SK: "Eslovaquia",
    SI: "Eslovenia",
    SB: "Islas Salomón",
    SO: "Somalia",
    ZA: "Sudáfrica",
    GS: "Islas Georgias del Sur y Sandwich",
    ES: "España",
    LK: "Sri Lanka",
    SD: "Sudán",
    SR: "Surinam",
    SJ: "Svalbard y Jan Mayen",
    SZ: "Swazilandia",
    SE: "Suecia",
    CH: "Suiza",
    SY: "República Árabe Siria",
    TW: "Taiwán",
    TJ: "Tayikistán",
    TZ: "Tanzania",
    TH: "Tailandia",
    TL: "Timor- Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad y Tobago",
    TN: "Túnez",
    TR: "Turquía",
    TM: "Turkmenistán",
    TC: "Islas Turcas y Caicos",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ucrania",
    AE: "Emiratos Árabes Unidos",
    GB: "Reino Unido",
    US: "Estados Unidos",
    UM: "Islas periféricas de los Estados Unidos",
    UY: "Uruguay",
    UZ: "Uzbekistán",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Islas Vírgenes Gran Bretaña",
    VI: "Islas Vírgenes EE. UU.",
    WF: "Wallis y Futuna",
    EH: "Sáhara Occidental",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabue",
  },
};
