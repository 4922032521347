import { Feeling } from "enums";
import { environment } from "environment";
import { post } from "utils";

class FeelingService {
  addFeeling(feeling: Feeling) {
    post(environment.feelings.root, {
      feeling,
    });
  }
}

export const feelingService = new FeelingService();
