import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactEcharts, { EChartsOption } from "echarts-for-react";
import { Feeling } from "enums";

interface Props {
  isLoading: boolean;
  data: Array<never>;
}

export const FeelingsChart = ({ isLoading, data }: Props) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data.length) {
    return (
      <div className="center">
        <label className="neon">{t("common.no_data")}</label>
      </div>
    );
  }

  const values = new Map(
    data.map(({ _id, totalCount }: { _id: Feeling; totalCount: number }) => [_id, totalCount]),
  );
  const option: EChartsOption = {
    backgroundColor: "transparent",
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: t("common.feeling"),
        type: "pie",
        radius: "50%",
        data: [
          {
            value: values.get(Feeling.OVERWHELMED) || 0,
            name: t("feelings.overwhelmed"),
            itemStyle: { color: "#F44336" },
          },
          {
            value: values.get(Feeling.SAD) || 0,
            name: t("feelings.sad"),
            itemStyle: { color: "#FF9800" },
          },
          {
            value: values.get(Feeling.NEUTRAL) || 0,
            name: t("feelings.neutral"),
            itemStyle: { color: "#FFEE58" },
          },
          {
            value: values.get(Feeling.FINE) || 0,
            name: t("feelings.fine"),
            itemStyle: { color: "#4CAF50" },
          },
          {
            value: values.get(Feeling.EXCITED) || 0,
            name: t("feelings.excited"),
            itemStyle: { color: "#3F51B5" },
          },
        ],
        label: {
          color: "white",
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return <ReactEcharts option={option} theme="dark" />;
};
