import { RoomType } from "enums";

export const TRANSLATION_PT = {
  landing: {
    login: "Entrar",
    sign_up: "Inscrever-se",
    whatsapp_button: "Você tem perguntas? Escreva para nós!",
    whatsapp_message: "Olá, gostaria de saber mais sobre o joinnexus",
    title: "Espaços de integração para equipes remotas",
    register_for_early_access: "Cadastre-se para acesso antecipado",
    contact_us: "Marque uma reunião para mais informações",
    book_for_more_information: "Reserve para mais informações",
    book_a_meeting_with_us: "Reserve uma reunião conosco",
    book_a_meeting: "Marque uma reunião",
    subtitle_1: "Las melhores",
    subtitle_1_1: "experiências em grupo",
    subtitle_1_2: "atividades de integração",
    subtitle_1_3: "festas virtuais",
    thank_you_for_your_interest: "Obrigado pelo seu interesse, entraremos em contato.",
    focus_on_human_interaction: "O que é o Joinnexus?",
    focus_on_human_interaction_message: `As melhores atividades para equipes remotas e eventos virtuais`,
    future: "O futuro é remoto",
    terms_and_conditions: "Termos e Condições",
    privacy_policy: "Política de Privacidade",
    disclaimer: {
      part_1: "Ele leu e entendeu as ",
      part_2: "condições",
      part_3: " e ",
      part_4: "políticas de privacidade",
      please_accept: "Aceite nossos termos e políticas",
    },
    news: {
      title: "Novidades na Joinnexus",
      subtitle: "Receba novidades, conheça as melhores práticas e muito mais.",
    },
    audience: {
      title: "Ferramentas de cultura",
      options: {
        personal: {
          title: "Termômetro da emoção",
          message: "Verifique como estão seus colaboradores e aja de acordo com os resultados",
        },
        eventsCreator: {
          title: "Feedback anônimo",
          message:
            "Permita que seus colaboradores se expressem livremente, envie sugestões ou parabéns",
        },
        office: {
          title: "Reconhecimento público",
          message: "Tabelas de resultados por badges e relatórios de resultados",
        },
        communities: {
          title: "Feedback 360",
          message: "Meça o desempenho e as habilidades de seus colaboradores.",
        },
      },
    },
    statistics: {
      statistics_1: "milhões de americanos poderão trabalhar remotamente em 2025.",
      statistics_2:
        "dos trabalhadores remotos consideram a colaboração e a solidão as maiores desafios em trabalhar remotamente.",
      statistics_3: "milhões de nômades digitais em todo o mundo.",
      statistics_4:
        "as instituições educacionais planejam continuar com algumas ou todas as ofertas remotas após a pandemia.",
      statistics_5: "milhões de usuários jogaram nossas atividades.",
    },
    pricing: {
      gamification: "Gamificação",
      title: "Planos e preços",
      premium: "Por mês",
      contact_us: "Contate-nos",
      until_n_users: "Até {} usuários",
      per_user_per_day: "Por usuário por dia",
      per_user_per_month: "Por usuário por mês",
      features: {
        access_to_all_activities: "Acesso a todas as atividades",
        an_administrator: "1 administrador",
        unlimited_hosts: "Anfitriões ilimitados",
        sso: "Início da sessão única SSO",
        profile_settings: "Configurações de perfil",
        feelings_thermometer: "Pulso de emoções",
        anonymous_feedback: "Feedback anónimo",
        events: "Eventos",
        custom_subdomain: "Subdomínio personalizado",
        badge_system: "Reconhecimento",
        analytics: "Analytics para administradores",
        unlimited_admins_and_hosts: "Administradores e anfitriões ilimitados",
      },
    },
    all_the_best_features: "Todos os melhores recursos",
    features: {
      when_to_use: {
        label: "Quando usar?",
        options: {
          option_1: {
            title: "Momentos legais",
            message: "Organize uma pausa durante uma semana de trabalho duro com sua equipe",
          },
          option_2: {
            title: "Indução",
            message:
              "Fique com o novo talento. Com o Nexus é mais fácil incorporar novos membros a uma equipe",
          },
          option_3: {
            title: "Time construindo atividades",
            message:
              "Jogos focados em aumentar a interação e criar conversas entre os membros da sala",
          },
          option_4: {
            title: "Networking",
            message: "Pare de usar ferramentas formais e chatas para interagir com seus colegas",
          },
          option_5: {
            title: "Aniversário",
            message:
              "Que tal uma festa de aniversário virtual? No Nexus você encontra os melhores jogos para festas virtuais",
          },
          option_6: {
            title: "Cerimônias de premiação de funcionários",
            message:
              "Todo prêmio merece uma comemoração. Com o Nexus você tem a oportunidade de comemorar com quem está longe",
          },
        },
      },
      features: {
        label: "Caracteristicas",
        options: {
          option_1: {
            title: "Prêmios",
            message:
              "O Nexus recompensa as habilidades, sendo as habilidades sociais as principais. O Nexus também foi projetado para todos os públicos",
          },
          option_2: {
            title: "Personalizado",
            message:
              "Você pode usar nossos jogos e adaptá-los de acordo com as preferências de sua equipe ou seu evento",
          },
          option_3: {
            title: "Jogos originais",
            message:
              "10 jogos exclusivos projetados para jogar virtualmente. Focada em melhorar a interação humana",
          },
          option_4: {
            title: "Tipos de usuário",
            message:
              "Conceda as permissões que julgar necessárias para cada um dos membros de sua equipe/evento",
          },
          option_5: {
            title: "Idiomas diferentes",
            message: "Agora, cada internauta pode escolher entre inglês, espanhol e português",
          },
          option_6: {
            title: "Integrações de terceiros",
            message:
              "Integrações com Microsoft Teams, Slack e Google. Expomos a API pública para que você possa integrar seu HRMS com o Nexus",
          },
        },
      },
    },
    contact_form: {
      label: "Está interessado?",
      caso: "Caso",
      message: "Mensagem",
      organization: "organização",
      send: "Enviar formulário",
    },
    faq: "Perguntas frequentes",
  },
  bill: {
    pay_pending_bills: "Pagar ordem pendentes",
    you_have_no_pending_bills: `Você não tem ordens pendentes`,
  },
  custom_categories: {
    category: "Categoria",
    add_questions: "Adicionar perguntas",
    edit: "Editar",
    deactivate: "Desativar",
    add_category: "Adicionar categoria",
    edit_category: "Editar categoria",
    type_the_category_name_in: "Digite o nome da categoria em",
    translate_from: "Traduzir a partir de",
    the_names_of_the_category_were_translated_correctly:
      "Os nomes da categoria foram traduzidos corretamente.",
    select_the_languages_you_want_us_to_help_you_with_the_translation:
      "Você deseja fazer uma tradução manual ou automática?",
    manual: "Manual",
    automatic: "Automática",
    missing_fields_to_translate_check_the_following_translations:
      "Campos a serem traduzidos, verifique as seguintes traduções",
    select_a_game: "Selecione um jogo",
  },
  custom_questions: {
    question: "Pergunta",
    add_question: "Adicionar pergunta",
    edit_question: "Editar pergunta",
    type: "Digite",
    in: "em",
    the_question: "a pergunta",
    add_word: "Adicionar palavra",
    edit_word: "Editar palavra",
    the_word: "a palavra",
    word: "Palavra",
    add_sentence: "Adicionar declaração",
    edit_sentence: "Editar declaração",
    the_sentence: "a declaração",
    sentence: "Declaração",
    right_option: "a opção certa",
    wrong_option: "a opção errada",
  },
  common: {
    update: "Atualizar",
    from: "De",
    to: "Para",
    no_data: "Sem dados",
    filter_by_name: "Filtrar usuários por nome",
    load_more: "Carregue mais",
    use_one_recommended_browsers: "Por favor, use um dos navegadores recomendados",
    host: "Host",
    name: "Nome",
    email: "Correio eletrônico",
    is_required: "é obrigatório",
    enter_a_valid_email: "Entre com um email válido",
    all_rights_reserved: "Todos os direitos reservados",
    instructions: "Instruções",
    categories: "Categorias",
    lets_start_the_game: `Vamos começar a festa`,
    waiting_for: "Esperando que",
    votes: "votar",
    vote: "votarem",
    option: "Opção",
    users: "Jogadores",
    results: "Resultados",
    voting_results: "Resultados da votação",
    to_start_the_game: "comece o jogo",
    lets_start: "Vamos começar",
    continue: "Prosseguir",
    to_continue: "continue",
    vote_for: "Vote em",
    select_an_user_please: "Selecione um usuário, por favor",
    lets_continue_with_next_question: `Vamos continuar com a próxima pergunta`,
    select_at_least_1_category: "Escolha pelo menos uma categoria por favor",
    please_allow_us_to_access_your_camera_and_microphone:
      "Permita-nos acessar sua câmera e microfone, por favor",
    retry: "Tentar novamente",
    user_configuration: "Configuração do usuário",
    go_back: "Voltar",
    yes: "Sim",
    no: "Non",
    no_messages: "Sem mensagens",
    time_remain: "Tempo restante",
    winners: "Vencedores",
    player: "Vencedor",
    invite_your_teammates_to_the_party: "Convide seus colegas de trabalho para a festa",
    share_link: "Compartilhar link",
    link_copied_to_the_clipboard:
      "Link copiado para a área de transferência. Compartilhe com seus colegas de trabalho.",
    select_max_bombs_amount: "Selecione a quantidade máxima de bombas",
    show_results: "Mostrar resultados",
    only_the_host_can_select_a_category: "Apenas host pode selecionar uma categoria",
    its_your_turn: "É a sua vez, dê uma olhada nas instruções",
    select_max_stars_amount: "Selecione a quantidade máxima de estrelas",
    you_need: "você precisa de mais",
    more_players: "jogadores",
    more_player: "jogador",
    cancel: "Cancelar",
    accept: "Aceitar",
    activate: "Ativar",
    inactivate: "Inativar",
    close: "Fechar",
    id: "ID",
    save: "Salvar",
    minutes: "minutos",
    the_field: "O campo",
    must_be_greater_than_or_equal_to: "deve ser maior ou igual a",
    date: "Data",
    user: "Utilizador",
    room_type: "Tipo de sala",
    actions: "Ações",
    game: "Jogo",
    learn_more: "Saber mais",
    surrender: "Desistir",
    skip_player: "Pular jogador",
    export_xlsx: "Exportar para Excel",
    feeling: "Sentimento",
    send: "Enviar",
  },
  errors: {
    this_is_the_email_you_used: "Você usou o seguinte e-mail para entrar, que não é válido:",
    please_use_an_email_from_the_enterprise: "Por favor, use um e-mail comercial.",
    feature_only_supported_by_the_enterprise_plan:
      "Para criar eventos, você precisa do plano Enterprise. Entre em contato com seu administrador.",
    check_your_internet_connection: "Verifique sua conexão com a internet",
    user_has_an_active_room:
      "Você tem uma sala existente em execução. Deseja excluí-la e criar uma nova?",
    another_event_is_running:
      "Você tem um evento existente em execução. Deseja excluí-lo e criar um novo?",
    pay_as_you_go_limit_has_reached:
      "O limite máximo foi atingido. Recarregue a página para atualizar o campo de consumo.",
    credit_card_generation_error:
      "Ocorreu um erro ao testar seu cartão. Verifique seus detalhes de login e tente novamente",
    contact_joinnexus_team: "Entre em contato com a equipe joinnexus",
    you_must_use_your_own_payment_method:
      "Por favor, use um método de pagamento de sua preferência",
    you_must_have_standard_plan: "Você deve ter um plano premium",
    payment_was_not_successful:
      "Ocorreu um erro no pagamento, verifique os fundos do método de pagamento selecionado",
    no_active_plan: "Você não tem um plano ativo, entre em contato com seu administrador",
    event_has_not_started:
      "O evento ainda não começou. Aguarde o horário de início do evento para entrar.",
    event_has_ended: "O evento terminou",
  },
  login: {
    sign_in_with: "Entrar com",
    sign_up_with: "Inscreva-se com",
    logout: "Sair",
    go_to_login: 'Ir para login',
  },
  controls: {
    mute: "Silêncio",
    unmute: "Ativar som",
    turn_on_camera: "Ligue a câmera",
    turn_off_camera: "Desligue a câmera",
    show: "Mostrar câmera",
    hide: "Ocultar a câmera",
  },
  rooms: {
    please_upgrade_your_account_to_create_a_room:
      "Por favor, atualize seu plano de conta para criar um quarto",
    create_a_room: "Crie uma sala",
    owner_deleted_your_room: "O proprietário da sala a excluiu",
    you_do_not_have_host_permissions:
      "Você não tem permissões de host. Entre em contato com o administrador para solicitá-los.",
    faq: "Perguntas frequentes",
    create_a_new_room: "Criar uma nova sala",
    [RoomType.ICEBREAKER]: "Quebra-gelos",
    [RoomType.COFFEE_TIME]: "Hora do café",
    [RoomType.MEET_AND_CHILL]: "Conhecer e relaxar",
    notiy_room_close: "A sala expirará em 5 minutos",
    close_room: "A sala expirou",
    link_after_room: "Link para redirecionar após o término da sala",
  },
  events: {
    create_an_event: "Criar um evento",
    go_event: "Ir para o evento",
    room_id: "ID da sala",
    event_name: "Nome",
    start_date: "Data de início",
    end_date: "Data de término",
    room_type: "Tipo de evento",
    delete_event: "Fechar evento",
    edit_event: "Editar evento",
    host: "Anfitrião",
    are_you_sure_you_want_to_delete: "Tem certeza de que deseja fechar o evento?",
    link_after_room: "Link para redirecionar após o término do evento",
  },
  event: {
    members: "Participantes",
    people_in_room: "Pessoas na sala",
    share_event_link: "Compartilhar link do evento",
    people_in_event: "Pessoas no evento",
    settings: "Configurações do evento",
    max_users_per_room: "Quantidade máxima de pessoas por quarto",
    event_rooms: "Salas de eventos",
    event_updated: "Evento atualizado",
    expiration_date: "Fim do evento",
    strict_mode: "Modo estrito",
    max_users_per_room_help: "Aqui você indica quantos máximos usuários deve haver por sala.",
    expiration_date_help: "Nesta data, o evento será excluído automaticamente.",
    priority_host_email: "Correio Prioritário do Host",
    strict_mode_help:
      "Quando ativado, os usuários não podem entrar em salas cheias, mesmo que você use o link direto da sala. Quando desabilitado, os usuários são divididos em salas de acordo com o número máximo de usuários por sala, mas se tiverem o link para a sala, mesmo que esteja cheia, podem entrar.",
  },
  room: {
    choose_a_game: "Escolha um jogo",
    waiting_for: "Esperando por",
    to_select_a_game: "selecione um jogo",
    loading_account: "Conta de carregamento",
    ask_to_your_manager: "Você está usando esta conta para ingressar no nexus",
    ask_to_your_manager_suffix:
      ", que não tem um plano ativo. Tente seu e-mail corporativo ou entre em contato com o administrador do Joinnexus",
    try_again: "Tente novamente para carregar o plano",
    go_room: "Vá para a sala",
    is_full: "A sala está cheia, mas você pode entrar em outra sala no evento.",
    go_to_another_room: "Vai para outra sala",
    request_host: "Solicitar função de host",
    are_you_sure_you_want_to_host_the_room: "Tem certeza de que deseja hospedar a sala?",
    link_after_room: "Link para redirecionar no final da sala",
    final_score_table: "Classificação final",
    leave_the_room: "Saia da sala",
  },
  games: {
    never_have_i_ever: "Eu nunca",
    most_likely_to: "Quem é mais provável?",
    a_lie: "Duas verdades e uma mentira",
    charades: "Jogo de charadas",
    hypotethically: "Hipoteticamente",
    debate: "Debate",
    think_fast: "Pensa rápido",
    who_am_i: "Quem sou?",
    deep_questions: "Perguntas profundas",
    trivia: "Trivia",
  },
  instructions: {
    never_have_i_ever: {
      paragraph1:
        "O jogo mítico EU NUNCA. Segundo as estatísticas, mais de 99% da população conhece este jogo (se você não conhece, provavelmente cresceu em uma caverna ou tem mais de 80 anos). Este jogo tem apenas 2 regras básicas, mas são INVIOLÁVEIS. A primeira é dizer a verdade, apenas a verdade e nada mais que a verdade. E a segunda é se divertir até não poder mais. Aqui está um resumo de como 'Eu Nunca' do Nexus funciona:",
      paragraph2: "A primeira coisa que você deve fazer é escolher a categoria.",
      paragraph3:
        "Uma vez dentro do jogo, o Nexus fará perguntas 'EU NUNCA', que aparecerão na tela.",
      paragraph4:
        "Se você fez o que aparece na tela, pressione o botão 'EU FIZ ISSO'. Caso contrário, você pressiona 'NÃO FIZ ISSO'.",
      paragraph5:
        "Depois que todos na sala responderem, o Nexus escolherá alguns para compartilhar sua história com o grupo.",
      paragraph6:
        "As pessoas selecionadas terão a opção de compartilhar sua história com o grupo. Se você foi selecionado, vá em frente e conte-nos sua história.",
      paragraph7:
        "Nexus vai revelar seus segredos mais obscuros... Brincadeirinha, relaxe e Nexus cuidará do resto.",
    },
    most_likely_to: {
      paragraph1:
        "Se você já foi a uma festa, com certeza conhece esse jogo. Nexus traz o jogo mais popular para a virtualidade de uma forma que você nunca jogou antes.",
      paragraph2: "A primeira coisa que o host do jogo deve fazer é escolher a categoria.",
      paragraph3:
        "Uma vez dentro do jogo, o Nexus fará perguntas sobre 'Quem tem maior probabilidade de realizar uma ação'.",
      paragraph4:
        "Em cada uma dessas perguntas você deve votar em quem você considera mais provável para realizar aquela ação.",
      paragraph5:
        "Uma vez que todos os membros da sala tenham votado, o Nexus indicará quem foi o participante que o grupo escolheu.",
      paragraph6:
        "O participante escolhido deve dizer aos demais membros do grupo porque acha que foi o mais votado.",
      paragraph7:
        "Quer saber o que as outras pessoas pensam de você? Experimente este jogo e você ficará surpreso.",
    },
    a_lie: {
      paragraph1:
        "Quão bom mentiroso você é? Neste jogo, o Nexus recompensa sua capacidade de mentir e influenciar as respostas dos outros.",
      paragraph2:
        "No início, o Nexus escolherá um participante, que terá que contar 3 histórias ou declarações diferentes sobre sua vida. IMPORTANTE: Uma dessas histórias deve ser falsa!",
      paragraph3: "Neste jogo existem 2 funções:",
      paragraph4: `É o participante que deve contar as 3 histórias. Se for a sua vez, você deve contar suas histórias ou afirmações e selecionar qual dessas 3 é a mentira. (De acordo com a ordem em que você os contou).`,
      paragraph5:
        "É o participante que tem a responsabilidade de escolher qual das 3 histórias é mentira. Se não for a sua vez de contar as histórias, você é um ouvinte. Seu dever é ouvir (obviamente :p) as 3 histórias de quem é o narrador e a partir daí você escolhe qual das histórias é a falsa.",
      paragraph6:
        "Se você é um relator e ninguém identifica sua história falsa, você é um grande mentiroso.",
      paragraph7:
        "Se você é um ouvinte e consegue identificar qual história é falsa, considere se tornar um detetive.",
    },
    charades: {
      paragraph1: `Muito se tem falado sobre a linguagem verbal e a facilidade que ela nos dá para expressar nossas ideias, mas e se tivéssemos que nos comunicar sem emitir sons? Vamos explorar como somos bons em nos expressar sem palavras com charadas.`,
      paragraph2: `Um jogador é escolhido para representar uma palavra e os outros devem adivinhar. Uma vez que o jogador tenha lido a palavra, ele clica em 'Continuar'.`,
      paragraph3: `Os outros jogadores terão 1 minuto para adivinhar a palavra digitando-a no chat.`,
      paragraph4: `Após 1 minuto, os jogadores que adivinharam a palavra serão exibidos..`,
      paragraph5: `Vamos ver o quão boa é a linguagem não-verbal nesta sala.`,
    },
    hypotethically: {
      paragraph1:
        "Há quem diga que para conhecer uma pessoa basta ver o que ela faz, ou neste caso, o que ela faria. Com este jogo, o Nexus entra no reino do hipotético.",
      paragraph2:
        "Para começar, o apresentador do jogo deve escolher uma categoria. Uma vez dentro, o Nexus selecionará aleatoriamente um jogador a cada rodada para responder a uma pergunta hipotética que será exibida na tela.",
      paragraph3:
        "A ideia é que a pessoa responda e justifique sua resposta e os demais membros da sala possam comentar.",
      paragraph4: "Após responder, clique no botão “PRÓXIMO”.",
    },
    think_fast: {
      paragraph1: `Desta vez, o Nexus testará suas habilidades. Em pensar rápido, não é quem mais sabe quem ganha, mas quem responde mais rápido.`,
      paragraph2: `Em cada rodada, o Nexus dá um tema. Cada usuário da Internet deve responder ao tópico dado pelo Nexus em menos de 10 segundos. A tela indicará quem faz a vez e quem é o próximo.`,
      paragraph3: `Toda vez que for a sua vez, você deve responder (em voz alta) e depois de responder você deve clicar no botão "Eu já disse a palavra".`,
      paragraph4: `Dessa forma, a vez passa para o próximo usuário da Internet até que ele não consiga responder. Toda vez que você não puder responder, estará acumulando "Bombas Radioativas", que são tóxicas em altas doses. O jogo termina quando um usuário atinge um certo número de bombas radioativas (você pode ajustá-lo manualmente na barra abaixo).`,
      paragraph5: `Mostre sua agilidade mental e lembre-se que você sempre pode melhorar...`,
    },
    debate: {
      paragraph1: `iOS ou Android? Facebook ou Twitter? Praia ou montanha?`,
      paragraph2: `Para os gostos, as cores. Na Nexus, sabemos que o prazer está na diferença e decidimos levar os debates para o próximo nível.`,
      paragraph3: `A cada rodada, o Nexus exibirá uma declaração, que os internautas devem indicar se concordam ou discordam.`,
      paragraph4: `Assim que todos os usuários da Internet escolherem suas respostas (Concordo ou Não), o Nexus escolherá 2 usuários da Internet. Um que respondeu "De acordo" e outro que respondeu "Em desacordo" para compartilhar seus pontos de vista e tentar convencer os outros de que sua posição é a melhor.`,
      paragraph5: `A ideia do debate é entender melhor as preferências de cada internauta e se divertir trocando ideias.`,
    },
    who_am_i: {
      paragraph1: `Cada rodada do Nexus selecionará um internauta que terá que adivinhar um personagem apenas fazendo perguntas a outros internautas. As perguntas devem ser formuladas da seguinte forma: Eu sou ____? Exemplo: Sou um político contemporâneo? Quando achar que sabe a resposta, deve escrevê-la na caixa que aparecerá sob sua câmera. Tente o máximo que puder, pois você tem que adivinhar em apenas 1 minuto.`,
      paragraph2: `Se não for a sua vez, você deve estar atento às perguntas que o internauta que faz a vez lhe faz. Você só deve responder as perguntas com SIM ou NÃO. Exemplo: Sou um político contemporâneo? Resposta: NÃO.`,
      paragraph3: `Você poderá ver o personagem como ele aparecerá na tela do internauta que fizer o turno.`,
      paragraph4: `Dica: Se você pressionar o botão com o sinal da lupa, poderá ver perguntas típicas que podem ser úteis para você.`,
      paragraph5: `Ao final de cada jogo, o Nexus premiará os internautas com as melhores pontuações.`,
    },
    deep_questions: {
      paragraph1: `Sem dúvida, com "Deep Questions" você terá a oportunidade de conhecer os outros internautas da sala de uma forma genuína. Respostas para perguntas que ninguém ousaria fazer.`,
      paragraph2: `A cada rodada, o Nexus escolherá um usuário para responder a uma pergunta. O internauta escolhido aparecerá em uma tela maior que os demais internautas.`,
      paragraph3: `Se for a sua vez de responder e você já o fez, você deve clicar no botão "Próximo", para passar a vez para outra pessoa.`,
      paragraph4: `Enquanto isso, os demais internautas que estiverem ouvindo a resposta da pergunta terão a possibilidade de reagir à resposta com os seguintes sentimentos.`,
      paragraph5: `Ao final da partida, o Nexus recompensará os internautas de acordo com as reações que obtiverem.`,
      paragraph6: `No Nexus o melhor não vence, a equipe vence`,
    },
    trivia: {
      paragraph1: `Na Nexus, acreditamos que todos os seres humanos são especialistas em alguma coisa, mesmo que não saibamos. Por esta razão, criamos este Trivia que abrange desde o rock clássico dos anos 80, passando pela arte renascentista até personagens populares de videogame.`,
      paragraph2: `A cada rodada, o Nexus exibirá uma pergunta com 4 opções de resposta. Os usuários devem responder às questões escolhendo a opção que cada um considera correta.`,
      paragraph3: `Ao final de cada jogo, o Nexus premiará os internautas com as melhores pontuações. Neste jogo procuramos mostrar a você e ao grupo em quais campos você é especialista.`,
      paragraph4: `O jogo termina quando um dos internautas acumula uma certa quantidade de estrelas (você pode modificar esse número na barra inferior desta tela).`,
      paragraph5: `Lembre-se que não há perdedores, apenas especialistas em outras áreas do conhecimento.`,
    },
  },
  who_am_i: {
    type_your_guess: "Escreva sua resposta",
    clue_1: "Eu sou humano?",
    clue_2: "Eu sou um animal?",
    clue_3: "Sou jovem?",
    clue_4: "Sou um personagem histórico?",
    clue_5: "Estou vivo?",
    its_your_turn:
      "É sua vez. Faça perguntas sim/não a outros jogadores e digite o nome do personagem que você acha que é na caixa abaixo",
  },
  most_likely_to: {
    question_prefix: "Quem é mais provável de",
    storyTimePrefix: "Diga-nos por que você acha que os outros escolheram você",
    storyTimeSuffix: "vai nos dizer por que ele/ela acha que você o escolheu",
  },
  never_have_i_ever: {
    prefix: "Eu nunca",
  },
  think_fast: {
    say_a_word_about: "Diga uma palavra relacionada a",
    i_already_said_the_word: "Eu já disse a palavra",
    break_time: "Intervalo",
  },
  a_lie: {
    listener: "Ouvinte",
    storyteller: "relator",
    is_going_to_tell_us_two_truths_and_a_lie: `vai nos dizer 2 verdades e 1 mentira. Você tem que adivinhar qual é a mentira e clicar na opção`,
    first: "A primeira história",
    second: "A segunda história",
    third: "A terceira história",
    is_the_lie: "é a mentira",
    you_have_to_tell_to_the_other_players_two_truths_and_a_lie:
      "Você tem que dizer aos outros jogadores 2 verdades e 1 mentira. Depois disso, selecione qual opção é a mentira.",
    choose_the_lie: "escolhem a mentira",
    chooses_the_lie: "escolhe a mentira",
  },
  charades: {
    you_have_been_chosen_to_act_out_this_word: "Tem sido elegido para atuar esta palavra",
    has_been_chosen_to_act_out_a_word:
      "foi selecionado para representar uma palavra, preste atenção e adivinhe a palavra",
    you_have_to_guess_the_word_that: "Você tem que adivinhar a palavra que",
    is_acting_out: "está agindo",
    you_have_to_act_out_this_word: "Você tem que agir esta palavra",
    type_the_word_that: "Digite a palavra que",
    awesome_you_discovered_the_word: "Incrível, você conseguiu descobrir a palavra",
    word: "Palavra",
    nobody_guessed_the_word: "Ninguém adivinhou a palavra. a palavra era",
    think_your_performance_and: "Pense em como representar a palavra e",
    click_on_the_button: "clique no botão para começar",
    secret_word_was: "A palavra secreta era",
  },
  hypotethically: {
    prefix: "O que você faria se",
    storyTimePrefix: "Conte-nos: O que você faria se",
    storyTimeSuffix: "vai nos dizer o que você faria se",
  },
  deep_questions: {
    storyTimePrefix: "Nos digam: ",
    storyTimeSuffix: "vai nos dizer",
  },
  debate: {
    agree: "De acordo",
    disagree: "Em desacordo",
    all_users_agreed_with: "Todos os jogadores concordaram",
    all_users_disagreed_with: "Todos os jogadores discordaram",
  },
  story_time: {
    lets_start_story_time: `Vamos começar a hora da história`,
    story_time_instructions: `A Nexus selecionou jogadores para compartilhar suas histórias conosco. Você está pronto?`,
    hey_lets_tell_us_your_story_with: `Ei, conte-nos sua história com`,
    is_going_to_tell_us_his_her_story_with: `vai nos contar sua história com`,
    lets_listen_the_next_story: `Vamos ouvir a seguinte história`,
    to_start_story_time: "Passar a vez",
  },
  account: {
    user_information: "Informação do usuário",
    plan: "Plano",
    update_user_information: "Atualizar informações do usuário",
    bills: "Notas",
    pay_as_you_go_plan: "Conforme o uso",
  },
  languages: {
    en: "Inglês",
    es: "Espanhol",
    pt: "Português",
    change_language: "Mudar idioma",
  },
  messages: {
    try_to_move_your_camera: "Você pode mover sua câmera, experimente",
    your_feedback_is_important_to_us:
      "Sua opinião é importante para nós, você poderia nos dar clicando nesta mensagem? Não levará mais de 5 minutos",
  },
  user_plan_management: {
    title: "Gerenciador de usuários",
    is_host: "É um anfitrião/anfitriã?",
    is_admin: "Você é um administrador/administrador?",
    set_as_host: "Adicionar aos anfitriões",
    remove_from_hosts: "Remover dos anfitriões",
    set_as_admin: "Adicionar aos administradores",
    remove_from_admins: "Remover dos administradores",
    user: "Usuário",
    status: "Estado",
    actions: "Ações",
    add_user: "Adicionar usuário",
    active_users: "Usuários ativos",
    active_hosts: "Anfitriões ativos",
    active_admins: "Administradores ativos",
    write_the_email_of_the_user_you_will_add:
      "Digite o e-mail e o nome de usuário que você deseja adicionar",
    active: "Ativo",
    inactive: "Inativo",
    the_premium_user: "O usuário premium",
    has_been_added: "foi adicionado com sucesso ao joinnexus.",
    surely_this_calls_for_a_celebration: "Certamente isso exige uma celebração.",
    warning_1:
      "Lembre-se que se o usuário entrou na plataforma pelo menos uma vez durante o período de cobrança atual, ele não poderá adicionar outro usuário em seu lugar até o próximo período de cobrança. Caso contrário, você poderá adicionar outro usuário instantaneamente",
    warning_2: "Tem certeza de que deseja desativar o acesso a",
    filter: "Filtrar por nome ou e-mail",
    add_users_to_plan: "Adicionar usuários ao plano",
    users_to_buy_for_the_plan: "Número de contas para comprar",
    accounts_added_successfully: "Contas adicionadas com sucesso",
    payment_by_payu: "Pagamento por payu",
  },
  billing: {
    you_have_reach_the_limit_of_active_users:
      "Você atingiu o limite de usuários ativos. Você pode comprar uma nova licença para adicionar um novo usuário.",
    you_have_reach_the_limit_of_admin:
      "Você atingiu o limite de administrador. Você pode comprar uma nova licença para adicionar um novo usuário.",
    you_have_reach_the_limit_of_hosts:
      "O limite de usuários de host foi atingido, se você precisar de mais usuários de host, entre em contato com a equipe Joinnexus",
    you_have_reach_the_limit_of_users_in_your_billing_period:
      "Você atingiu o limite de usuários em seu período de cobrança. Você pode comprar uma nova licença para adicionar este novo usuário.",
    payment_date: "Data de pagamento",
    amount: "Quantidade",
    currency: "Moeda",
    accounts_amount: "Número de contas",
    payment_method: "Forma de pagamento",
    billing_information: "Informações de pagamento",
    expiration_date: "Expiration date",
    transaction_id: "Transação do PayU",
    pay_bill: "Pagar ordem",
  },
  menu: {
    profile: "Perfil",
    users: "Usuários",
    bills: "Cobrança",
    logout: "Fechar Sessão",
    pay_as_you_go: "Seu consumo",
    payment_methods: "Métodos de pagamento",
    custom_categories: "Categorias personalizadas",
    custom_questions: "Perguntas personalizadas",
    anonymous_feedback: "Sugestões anônimas",
    learning_center: "Centro de Aprendizagem",
    protons_center: "Centro de reconhecimento",
    events: "Eventos",
    analytics: "Análise",
    feedback_360: "Feedback 360",
  },
  analytics: {
    metric: "Métricas",
    feeling_per_month: "Sentimentos por mês",
    month_year: "Mês & ano",
    protons_in_period: "Reconhecimentos em um período de tempo",
  },
  feedback_360: {
    self_assessment_score: "Pontuação de autoavaliação",
    peers_score: "Pontuação dos colegas",
    start_feedback_360_process: "Iniciar feedback 360",
    send_feedback_360: "Enviar feedback 360",
    give_feedback_360: "Dê feedback",
    feedback_requests: "Pedidos de feedback",
    feedback_received: "Feedback recebido",
    no_feedback_requests: "Nenhum pedido de feedback",
    no_feedbacks: "Não há feedback",
    open_feedback: "Feedback aberto",
    waiting_for_feedback: "Aguardando o feedback",
    feedback_results: "Resultados",
    answer_the_following_questions_regarding_the_performance_of:
      "Responda às seguintes perguntas sobre o desempenho de",
    answer_the_following_questions_for_yourself: "Responda as seguintes perguntas para si mesmo",
    any_comments_you_want_to_add: "Alguns comentários que gostaria de adicionar?",
    comments: "Comentários da pergunta",
    feedback_suffix:
      "Para ter uma visão mais precisa do seu desempenho, adicione o e-mail de quem você deseja solicitar feedback sobre o seu trabalho nas funções abaixo.",
    feedback_suffix_2: "Se você não tiver ninguém para uma função, deixe a caixa em branco.",
    subordinate: "Correio de um subordinado",
    peer: "Correio de um colega",
    supervisor: "Correio de um supervisor",
    add_a_rating_please: "Adicione uma classificação por favor",
    at_least_somebody_else:
      "Adicione pelo menos mais uma pessoa (subordinado, colega ou supervisor) para receber seu feedback.",
    error_in_form: "Há um erro no formulário, por favor revise",
  },
  anonymous_feedback: {
    no_anonymous_feedback: "Você não tem Sugestões anônimas",
    add_anonymous_feedback: "Dar sugestão anônima",
    feedback: "Sugestão anônima",
    who_is_going_to_receive_your_feedback: "Quem vai receber seu feedback?",
    write_your_feedback: "Escreva sua sugestão anônima",
    send_feedback: "Enviar sugestão anônima",
  },
  proton_center: {
    proton_center: "Centro de reconhecimentos",
    no_protons: "Você não deu reconhecimentos",
    select_user_title: "Selecione para quem você dará o reconhecimento",
    give_proton_title: "Selecione o reconhecimento e deixe uma mensagem bacana",
    to: "Para:",
    load_more: "Carregue mais",
    why_are_you_sending_this_proton:
      "Por que você quer enviar este reconhecimento? conte-nos a história",
    send_proton: "Enviar reconhecimento",
    proton: "Reconhecimento",
    no_proton: "Sem reconhecimento",
    protons_count: "Contagem de reconhecimentos",
  },
  faq: {
    how_create_new_room: "Como criar uma sala?",
    how_add_new_users: "Como adicionar novos usuários?",
    what_are_admins: "O que são administradores?",
    what_are_hosts: "Quais são os anfitriões?",
  },
  pay_as_you_go: {
    plan_limit: "Máximo de acessos à plataforma",
    consumption: "Acessos até agora",
    plan_updated: "Plano atualizado",
    entrances: "Entrances",
    room_name: "Nome da sala",
    room_owner: "Dono do quarto",
    room_created_at: "Data de entrada na sala",
    month_and_year: "Período de consumo (Mês/Ano)",
    pay_as_you_go_consumption: "uso_do_plano",
  },
  payment_methods: {
    add_credit_card: "Adicionar cartão de crédito",
    delete_credit_card: "Excluir cartão de crédito",
    credit_card_added_successfully: "Cartão de crédito adicionado com sucesso",
    credit_card_deleted_successfully: "Cartão de crédito deletado com sucesso",
    identificationNumber: "Número de identificação",
    franchise: "Franquia",
    number: "Número do cartão de crédito",
    expirationMonth: "Mês de vencimento",
    expirationYear: "Ano de vencimento",
    are_you_sure_you_want_to_delete_the_credit_card:
      "Você tem certeza de que deseja excluir o cartão de crédito",
    shippingAddress: "Endereço",
    shippingCity: "Cidade",
    shippingState: "Estado",
    shippingCountry: "País",
    postalCode: "Código postal",
    phone: "Número de telefone",
  },
  learning_center: {
    filter_posts: "Filtrar postagens",
  },
  feelings: {
    tell_us_how_do_you_feel_today: "Como você se sente hoje?",
    overwhelmed: "Sobrecarregado",
    sad: "Triste",
    neutral: "Neutro",
    fine: "Bem",
    excited: "Excitado",
  },
  slots_management: {
    current_plan: "Plano atual",
    renovation_plan: "Plano para renovar",
    slots: "contas",
    available_slots: "Contas disponíveis",
    update_renovation_plan: "Atualizar plano para renovar",
    update_current_plan: "Atualizar plano atual",
    subdomains_management: "Gerenciamento de domínios",
  },
  months: {
    jan: "Janeiro",
    feb: "Fevereiro",
    mar: "Março",
    apr: "Abril",
    may: "Maio",
    jun: "Junho",
    jul: "Julho",
    aug: "Agosto",
    sep: "Setembro",
    oct: "Outubro",
    nov: "Novembro",
    dec: "Dezembro",
  },
  countries: {
    AF: "Afeganistão",
    AX: "Ilhas Aland",
    AL: "Albânia",
    DZ: "Argélia",
    AS: "Samoa Americana",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antártica",
    AG: "Antígua e Barbuda",
    AR: "Argentina",
    AM: "Armênia",
    AW: "Aruba",
    AU: "Austrália",
    AT: "Áustria",
    AZ: "Azerbaijão",
    BS: "Bahamas",
    BH: "Bahrein",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Bielorrússia",
    BE: "Bélgica",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermudas",
    BT: "Butão",
    BO: "Bolívia",
    BA: "Bósnia e Herzegovina",
    BW: "Botswana",
    BV: "Ilha Bouvet",
    BR: "Brasil",
    IO: "Território Britânico do Oceano Índico",
    BN: "Brunei Darussalam",
    BG: "Bulgária",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboja",
    CM: "Camarões",
    CA: "Canadá",
    CV: "Cabo Verde",
    KY: "Ilhas Cayman",
    CF: "República Centro-Africana",
    TD: "Chade",
    CL: "Chile",
    CN: "China",
    CX: "Ilha Christmas",
    CC: "Ilhas Cocos (Keeling)",
    CO: "Colômbia",
    KM: "Comores",
    CG: "Congo",
    CD: "República Democrática do Congo",
    CK: "Ilhas Cook",
    CR: "Costa Rica",
    CI: "Costa do Marfim",
    HR: "Croácia",
    CU: "Cuba",
    CY: "Chipre",
    CZ: "República Tcheca",
    DK: "Dinamarca",
    DJ: "Djibuti",
    DM: "Dominica",
    DO: "República Dominicana",
    EC: "Equador",
    EG: "Egito",
    SV: "El Salvador",
    GQ: "Guiné Equatorial",
    ER: "Eritreia",
    EE: "Estônia",
    ET: "Etiópia",
    FK: "Ilhas Malvinas (Malvinas)",
    FO: "Ilhas Faroe",
    FJ: "Fiji",
    FI: "Finlândia",
    FR: "França",
    GF: "Guiana Francesa",
    PF: "Polinésia Francesa",
    TF: "Territórios Franceses do Sul",
    GA: "Gabão",
    GM: "Gâmbia",
    GE: "Geórgia",
    DE: "Alemanha",
    GH: "Gana",
    GI: "Gibraltar",
    GR: "Grécia",
    GL: "Groenlândia",
    GD: "Grande ada",
    GP: "Guadalupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guiné",
    GW: "Guiné-Biss au",
    GY: "Guiana",
    HT: "Haiti",
    HM: "Ilha Heard e Ilhas Mcdonald",
    VA: "Santa Sé (Estado da Cidade do Vaticano)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungria",
    IS: "Islândia",
    IN: "Índia",
    ID: "Indonésia",
    IR: "Irã",
    IQ: "Iraque",
    IE: "Irlanda",
    IM: "Ilha de Man",
    IL: "Israel",
    IT: "Itália",
    JM: "Jamaica",
    JP: "Japão",
    JE: "Jersey",
    JO: "Jordânia",
    KZ: "Cazaquistão",
    KE: "Quênia",
    KI: "Kiribati",
    KR: "Coréia",
    KP: "Coréia do Norte",
    KW: "Kuwait",
    KG: "Quirguistão",
    LA: "Laos PDR",
    LV: "Letônia",
    LB: "Líbano",
    LS: "Lesoto",
    LR: "Libéria",
    LY: "Jamahiriya Árabe Líbia",
    LI: "Liechtenstein",
    LT: "Lituânia",
    LU: "Luxemburgo",
    MO: "Macau",
    MK: "Macedônia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malásia",
    MV: "Maldivas",
    ML: "Mali",
    MT: "Malta",
    MH: "Ilhas Marshall",
    MQ: "Martinica",
    MR: "Mauritânia",
    MU: "Maurício",
    YT: "Mayotte",
    MX: "México",
    FM: "Micronésia",
    MD: "Moldávia",
    MC: "Mônaco",
    MN: "Mongólia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Marrocos",
    MZ: "Moçambique",
    MM: "Myanmar",
    NA: "Namíbia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Holanda",
    AN: "Antilhas Holandesas",
    NC: "Nova Caledônia",
    NZ: "Nova Zelândia",
    NI: "Nicarágua",
    NE: "Níger",
    NG: "Nigéria",
    NU: "Niue",
    NF: "Ilha Norfolk",
    MP: "Ilhas Marianas do Norte",
    NO: "Noruega",
    OM: "Omã",
    PK: "Paquistão",
    PW: "Palau",
    PS: "Território Palestino",
    PA: "Panamá",
    PG: "Papua Novo Guiné",
    PY: "Paraguai",
    PE: "Peru",
    PH: "Filipinas",
    PN: "Pitcairn",
    PL: "Polônia",
    PT: "Portugal",
    PR: "Porto Rico",
    QA: "Catar",
    RE: "Reunião",
    RO: "Romênia",
    RU: "Federação Russa",
    RW: "Ruanda",
    BL: "São Bartolomeu",
    SH: "Santa Helena",
    KN: "São Cristóvão e Nevis",
    LC: "Santa Lúcia",
    MF: "São Martinho",
    PM: "São Pedro e Miquelon",
    VC: "São Vicente e Granadinas",
    WS: "Samoa",
    SM: "São Marino",
    ST: "São Tomé e Príncipe",
    SA: "Arábia Saudita Arábia",
    SN: "Senegal",
    RS: "Sérvia",
    SC: "Seychelles",
    SL: "Serra Leoa",
    SG: "Singapura",
    SK: "Eslováquia",
    SI: "Eslovênia",
    SB: "Ilhas Salomão",
    SO: "Somália",
    ZA: "África do Sul",
    GS: "Geórgia do Sul e Ilhas Sandwich",
    ES: "Espanha",
    LK: "Sri Lanka",
    SD: "Sudão",
    SR: "Suriname",
    SJ: "Svalbard e Jan Mayen",
    SZ: "Suazilândia",
    SE: "Suécia",
    CH: "Suíça",
    SY: "República Árabe Síria",
    TW: "Taiwan",
    TJ: "Tadjiquistão",
    TZ: "Tanzânia",
    TH: "Tailândia",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad e Tobago",
    TN: "Tunísia",
    TR: "Turquia",
    TM: "Turquemenistão",
    TC: "Ilhas Turks e Caicos",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ucrânia",
    AE: "Emirados Árabes Unidos",
    GB: "Reino Estados Unidos",
    US: "Estados Unidos",
    UM: "Ilhas Exteriores dos EUA",
    UY: "Uruguai",
    UZ: "Uzbequistão",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnã",
    VG: "Ilhas Virgens Britânicas",
    VI: "Ilhas Virgens dos EUA",
    WF: "Wallis e Futuna",
    EH: "Saara Ocidental",
    YE: "Iêmen",
    ZM: "Zâmbia",
    ZW: "Zimbábue",
  },
};
