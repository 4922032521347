import { size, trim } from "lodash";
import { User } from "models";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { userService } from "services";
import { RootState, useShallowEqualSelector } from "store";
import { normalize } from "utils";

export const useController = () => {
  const [users, setUsers] = useState<Array<User>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUserId } = useShallowEqualSelector((state: RootState) => ({
    currentUserId: state.user.id,
  }));

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await userService.findFromOrganization();
        setUsers(response.data);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const { watch } = useFormContext();

  const { userSearch } = watch();

  const userSearchClean = normalize(userSearch).toLowerCase();

  const filterUsers = useMemo(() => {
    const isCurrentUser = (user: User) => user.id === currentUserId;
    const userNameIncludes = (user: User) =>
      normalize(user.name.toLowerCase()).includes(userSearchClean);
    const hasFilter = size(trim(userSearchClean));
    if (!hasFilter) {
      return users.filter(user => !isCurrentUser(user));
    }

    return users.filter(user => userNameIncludes(user) && !isCurrentUser(user));
  }, [users, userSearchClean, currentUserId]);

  return {
    filterUsers,
    isLoading,
  };
};
