import { RoomType } from "enums";

export const TRANSLATION_EN = {
  landing: {
    login: "Login",
    sign_up: "Sign up",
    whatsapp_button: "Do you have questions? Write us!",
    whatsapp_message: "Hello, I would like to know more about joinnexus",
    title: "Meet & Chill spaces for remote teams",
    register_for_early_access: "Register for early access",
    contact_us: "Book a meeting for more information",
    book_for_more_information: "Book for more information",
    book_a_meeting_with_us: "Book a meeting with us",
    book_a_meeting: "Book a meeting",
    subtitle_1: "The best",
    subtitle_1_1: "team building activities",
    subtitle_1_2: "happy hours",
    subtitle_1_3: "virtual parties",
    thank_you_for_your_interest: "Thank you for your interest, We will contact you.",
    focus_on_human_interaction: `What is Joinnexus?`,
    focus_on_human_interaction_message: `The best team building activities for remote teams and virtual events`,
    future: "Future is remote",
    terms_and_conditions: "Terms and conditions",
    privacy_policy: "Privacy policy",
    disclaimer: {
      part_1: "I have read and understood the ",
      part_2: "conditions",
      part_3: " and ",
      part_4: "privacy policies",
      please_accept: "Please accept our terms and policies",
    },
    news: {
      title: "What's New at Joinnexus",
      subtitle: "Stay up to date on news, learn best practices, and more.",
    },
    audience: {
      title: "Emotion thermometer",
      options: {
        personal: {
          title: "Personal",
          message: "Check how your collaborators are doing and act on the results",
        },
        eventsCreator: {
          title: "Anonymous feedback",
          message: "Allow your collaborators to express themselves freely, send suggestions or congratulations",
        },
        office: {
          title: "Public recognition",
          message: "Results tables by badges and results reports",
        },
        communities: {
          title: "Feedback 360",
          message:
            "Measure the performance and skills of your collaborators.",
        },
      },
    },
    statistics: {
      statistics_1: "million of Americans could be working remotely by 2025.",
      statistics_2:
        "of remote workers find collaboration and loneliness to be the biggest challeges of working remotely.",
      statistics_3: "million of digital nomads around the world.",
      statistics_4:
        "educational institutions plan to continue some or all of the remote offerings after the pandemic.",
      statistics_5: "million users have played our games.",
    },
    pricing: {
      gamification: 'Gamification',
      title: "Plans & Pricing",
      premium: "Monthly",
      contact_us: "Contact us",
      until_n_users: 'Up to {} users',
      per_user_per_day: "Per user per day",
      per_user_per_month: "Per user per month",
      features: {
        access_to_all_activities: 'Access to all activities',
        an_administrator: "1 administrator",
        unlimited_hosts: "Unlimited hosts",
        sso: "SSO Single Sign On",
        profile_settings: "Profile settings",
        feelings_thermometer: 'Pulse of emotions',
        anonymous_feedback: 'Comentários anônimos',
        events: "Events",
        custom_subdomain: "Custom subdomain",
        badge_system: "Recognition",
        analytics: 'Analytics for administrators',
        unlimited_admins_and_hosts: "Unlimited admins and hosts",
      },
    },
    all_the_best_features: "OK, but why Nexus?",
    features: {
      when_to_use: {
        label: "When to use it?",
        options: {
          option_1: {
            title: "Happy hours",
            message: "Organize a break during a week of hard work with your work team",
          },
          option_2: {
            title: "Onboarding",
            message:
              "Retain new talent. With Nexus it is easier to incorporate new members into a team",
          },
          option_3: {
            title: "Team Building Activities",
            message:
              "Games focused on increasing interaction and creating conversations between members of the room",
          },
          option_4: {
            title: "Networking",
            message: "Stop using formal and boring tools to network among your attendees",
          },
          option_5: {
            title: " Birthday celebrations",
            message:
              "How about a birthday virtual party?. In Nexus you can find the best icebreakers for virtual parties",
          },
          option_6: {
            title: "Employee award ceremonies",
            message:
              "Every award deserves a celebration. With Nexus you have a chance to celebrate with those who are far away",
          },
        },
      },
      features: {
        label: "Features",
        options: {
          option_1: {
            title: "Awards",
            message:
              "In games, Nexus rewards several skills, with soft skills being the main ones. Nexus is also designed for all audiences",
          },
          option_2: {
            title: "Customize",
            message:
              "You can use our games and adapt them according to the preferences of your team or your event",
          },
          option_3: {
            title: "Best and original games",
            message:
              "10 exclusive games designed to be played virtually. Focused on improving human interaction",
          },
          option_4: {
            title: "Users permissions",
            message:
              "Grant the permissions you dream necessary for each of the members of your teams/events",
          },
          option_5: {
            title: "Different languages",
            message: "Currently, each internaut can choose between English, Spanish and Portuguese",
          },
          option_6: {
            title: "Third party integrations",
            message:
              "Integrations with Microsoft Teams, Slack, and Google. We expose public API so you can integrate your HRMS with Nexus",
          },
        },
      },
    },
    contact_form: {
      label: "Are you \ninterested?",
      affair: "Affair",
      message: "Message",
      organization: "Organization",
      send: "Send form",
    },
    faq: "Frequently asked questions",
  },
  bill: {
    pay_pending_bills: "Pay pending orders",
    you_have_no_pending_bills: `You don't have outstanding orders`,
  },
  custom_categories: {
    category: "Category",
    add_questions: "Add questions",
    edit: "Edit",
    deactivate: "Deactivate",
    add_category: "Add category",
    edit_category: "Edit category",
    type_the_category_name_in: "Type the category name in",
    translate_from: "Translate from",
    the_names_of_the_category_were_translated_correctly:
      "The names of the category were translated correctly.",
    select_the_languages_you_want_us_to_help_you_with_the_translation:
      "Do you want to do a manual or automatic translation?",
    manual: "Manual",
    automatic: "Automatic",
    missing_fields_to_translate_check_the_following_translations:
      "Missing fields to translate, check the following translations",
    select_a_game: "Select a game",
  },
  custom_questions: {
    question: "Question",
    add_question: "Add question",
    edit_question: "Edit question",
    type: "Type",
    in: "in",
    the_question: "the question",
    add_word: "Add word",
    edit_word: "Edit word",
    the_word: "the word",
    word: "Word",
    add_sentence: "Add sentence",
    edit_sentence: "Edit sentence",
    the_sentence: "the sentence",
    sentence: "Sentence",
    right_option: "the right option",
    wrong_option: "the wrong option",
  },
  common: {
    update: 'Update',
    from: "From",
    to: "To",
    no_data: "No data",
    filter_by_name: "Filter users by name",
    load_more: "Load more",
    use_one_recommended_browsers: "Please, use one of the recommended browsers",
    host: "Host",
    name: "Name",
    email: "E-mail",
    is_required: "is required",
    enter_a_valid_email: "Enter a valid email",
    all_rights_reserved: "All rights reserved",
    instructions: "Instructions",
    categories: "Categories",
    lets_start_the_game: `Let's start the party`,
    waiting_for: "Waiting for",
    votes: "to vote",
    vote: "to vote",
    option: "Option",
    users: "Players",
    results: "Results",
    voting_results: "Voting results",
    to_start_the_game: "to start the game",
    lets_start: "Let's start",
    continue: "Continue",
    to_continue: "to continue",
    vote_for: "Vote for",
    select_an_user_please: "Select a user, please",
    lets_continue_with_next_question: `Let's continue with next question`,
    select_at_least_1_category: "Choose at least one category",
    please_allow_us_to_access_your_camera_and_microphone:
      "Allow us to access your camera and microphone, please",
    retry: "Try again",
    user_configuration: "User configuration",
    go_back: "Go back",
    yes: "Yes",
    no: "No",
    no_messages: "No messages",
    time_remain: "Time remain",
    winners: "Winners",
    player: "Player",
    invite_your_teammates_to_the_party: "Invite your teammates to the party",
    share_link: "Share link",
    link_copied_to_the_clipboard: "Link copied to the clipboard. Share it with your teammates.",
    select_max_bombs_amount: "Select max bombs' amount",
    show_results: "Show results",
    only_the_host_can_select_a_category: "Only host can select a category",
    its_your_turn: "It's your turn, take a look at the instructions",
    select_max_stars_amount: "Select max stars' amount",
    you_need: "You need",
    more_players: "more players",
    more_player: "more player",
    cancel: "Cancel",
    accept: "Accept",
    activate: "Activate",
    inactivate: "Inactivate",
    close: "Close",
    id: "ID",
    save: "Save",
    minutes: "minutes",
    the_field: "The field",
    must_be_greater_than_or_equal_to: "must be greater than or equal to",
    date: "Date",
    user: "User",
    room_type: "Room type",
    actions: "Actions",
    game: "Game",
    learn_more: "Learn more",
    surrender: "Surrender",
    skip_player: "Skip player",
    export_xlsx: "Export to excel",
    feeling: "Feeling",
    send: "Send",
  },
  errors: {
    this_is_the_email_you_used: "You used the following email to enter which is not valid:",
    please_use_an_email_from_the_enterprise: "Please use a business email.",
    feature_only_supported_by_the_enterprise_plan:
      "To create events, you need the Enterprise plan. Contact your administrator.",
    check_your_internet_connection: "Check your internet connection",
    user_has_an_active_room:
      "You have an existing room running. Would you like to delete it and create a new one?",
    another_event_is_running:
      "You have an existing event running. Would you like to delete it and create a new one?",
    pay_as_you_go_limit_has_reached:
      "The maximum limit has been reached. Reload the page to update the consumption field.",
    credit_card_generation_error:
      "There has been an error testing your card. Check your login details and try again",
    contact_joinnexus_team: "Please contact the joinnexus team",
    you_must_use_your_own_payment_method: "Please use a payment method of yours",
    you_must_have_standard_plan: "You must have a premium plan",
    payment_was_not_successful:
      "There was an error in the payment, please check the funds of the selected payment method",
    no_active_plan: "You do not have an active plan, please contact your administrator",
    event_has_not_started:
      "The event has not started yet. Please wait for the event start time to enter.",
    event_has_ended: "The event has ended.",
  },
  login: {
    sign_in_with: "Sign in with",
    sign_up_with: "Sign up with",
    logout: "Logout",
    go_to_login: 'Go to login',
  },
  controls: {
    mute: "Mute",
    unmute: "Unmute",
    turn_on_camera: "Turn on camera",
    turn_off_camera: "Turn off camera",
    show: "Show",
    hide: "Hide",
  },
  rooms: {
    please_upgrade_your_account_to_create_a_room:
      "Please, upgrade your account plan to create a room",
    create_a_room: "Create a room",
    owner_deleted_your_room: `The room's owner deleted it`,
    you_do_not_have_host_permissions:
      "You do not have host permissions. Contact the administrator to request them.",
    faq: "Frequently asked questions",
    create_a_new_room: "Create a new room",
    [RoomType.ICEBREAKER]: "Icebreakers",
    [RoomType.COFFEE_TIME]: "Coffee time",
    [RoomType.MEET_AND_CHILL]: "Meet & chill",
    notiy_room_close: "The room will expire in 5 minutes",
    close_room: "The room has expired",
    link_after_room: "Link to redirect after room ends",
  },
  events: {
    create_an_event: "Create an event",
    go_event: "Go to the event",
    room_id: "Room ID",
    event_name: "Name",
    start_date: "Start Date",
    end_date: "End Date",
    room_type: "Event Type",
    delete_event: "Close event",
    edit_event: "Edit event",
    host: "Host",
    are_you_sure_you_want_to_delete: "Are you sure you want to close the event?",
    link_after_room: "Link to redirect after event ends",
  },
  event: {
    members: "Attendees",
    people_in_room: "People in the room",
    share_event_link: "Share event link",
    people_in_event: "People at the event",
    settings: "Event Settings",
    max_users_per_room: "Maximum amount of people per room",
    event_rooms: "Event rooms",
    event_updated: "Event updated",
    expiration_date: "End of the event",
    strict_mode: "Strict mode",
    max_users_per_room_help: "Here you indicate how many maximum users there should be per room.",
    expiration_date_help: "On this date, the event will be automatically deleted.",
    priority_host_email: "Priority host email",
    strict_mode_help:
      "When it is enable, users cannot enter rooms that are full even if they use the room's direct link. When it is disabled, users are divided into rooms according to the maximum number of users per room but if they have the link to the room, even if it is full, they can join.",
  },
  room: {
    choose_a_game: "Choose a game",
    waiting_for: "Waiting for",
    to_select_a_game: "to select a game",
    loading_account: "Loading account",
    ask_to_your_manager: "You are using this account to join nexus",
    ask_to_your_manager_suffix:
      ", which does not have an active plan. Try your corporate email or contact the Joinnexus administrator",
    try_again: "Try again to load the plan",
    go_room: "Go to room",
    is_full: "The room is full however you can enter another room in the event.",
    go_to_another_room: "Go to another room",
    request_host: "Request host role",
    are_you_sure_you_want_to_host_the_room: "Are you sure you want to host the room?",
    link_after_room: "Link to redirect at the end of the room",
    final_score_table: "Final classification",
    leave_the_room: "Leave the room",
  },
  games: {
    never_have_i_ever: "Never have i ever",
    most_likely_to: "Most likely to",
    a_lie: "Two truths and a lie",
    charades: "Charades",
    hypotethically: "Hypotethically",
    debate: "Debate",
    think_fast: "Think fast",
    who_am_i: "Who am I",
    deep_questions: "Deep questions",
    trivia: "Trivia",
  },
  instructions: {
    never_have_i_ever: {
      paragraph1:
        "The mythical NEVER HAVE I EVER game. According to statistics, more than 99% of the population knows this game (if you don't know it, you probably grew up in a cave or are over 80 years old). This game has only 2 basic rules, but they are INVIOLABLE. The first is to tell the truth, only the truth and nothing but the truth. And the second is to have fun until you can't do it anymore. Here's a summary of how 'NEVER HAVE I EVER' Nexus version works:",
      paragraph2: "The first thing you should do is choose the category.",
      paragraph3:
        "Once inside the game, Nexus will ask questions, which will appear on the screen.",
      paragraph4:
        "If you have done what appears on the screen, press the 'YES I HAVE DONE IT' button. Otherwise you press 'I HAVE NOT DONE IT'.",
      paragraph5:
        "Once everyone in the room has responded, Nexus will choose a few to share their story with the group.",
      paragraph6:
        "Selected people will have the option to share their story with the group. If you have been selected, go ahead and tell us your story.",
      paragraph7:
        "Nexus is going to reveal your darkest secrets... Just kidding, relax and Nexus will take care of the rest.",
    },
    most_likely_to: {
      paragraph1:
        "If you have ever gone out to a party, surely you know this game. Nexus brings the most popular game to virtuality in a way you've never played before.",
      paragraph2: "The first thing the game host should do is choose the category.",
      paragraph3:
        "Once inside the game, Nexus will ask questions about 'Who is most likely to perform an action'.",
      paragraph4:
        "In each of these questions you must vote for who you consider to be the most likely to perform that action.",
      paragraph5:
        "Once all the members of the room have voted, Nexus will indicate who has been the participant whom the group has chosen.",
      paragraph6:
        "The chosen participant must tell the other members of the group why he thinks he has been the most voted.",
      paragraph7:
        "Do you want to know what other people think about you? Try this game and you will be surprised.",
    },
    a_lie: {
      paragraph1:
        "How good of a liar are you? In this game, Nexus rewards your ability to lie and influence the responses of others.",
      paragraph2:
        "At the beginning, Nexus will choose a participant, who will have to tell 3 different stories or statements about his life. IMPORTANT: One of those stories must be false!",
      paragraph3: "In this game there are 2 roles:",
      paragraph4: `It is the participant who must tell the 3 stories. If it is your turn, you must tell your stories or affirmations and select which of those 3 is the lie. (According to the order in which you counted them).`,
      paragraph5:
        "It is the participant who has the responsibility to choose which of the 3 stories is a lie. If it is not your turn to tell the stories, you are a listener. Your duty is to listen (obviously :p) to the 3 stories of who is the narrator and from there, you choose which of the stories is the false one.",
      paragraph6:
        "If you are a storyteller and nobody identifies your false story, you are a great liar.",
      paragraph7:
        "If you are a listener and can identify which story is false, you should consider becoming a detective.",
    },
    charades: {
      paragraph1: `A lot has been said about the verbal language and how it helps us to express our ideas, but what would happen if we had to communicate without making any sounds? Let's explore how good we are to express ourselves without words with charades.`,
      paragraph2: `One player is chosen to act out a word and the others must guess it. Once the player has read the word, he clicks 'Continue'.`,
      paragraph3: `The other players will have 1 minute to guess the word by typing it in the chat.`,
      paragraph4: `After 1 minute, the players who guessed the word will be displayed.`,
      paragraph5: `Let's see how good the non-verbal language is in this room.`,
    },
    hypotethically: {
      paragraph1:
        "There are those who say that to know a person it is enough to see what they do, or in this case, what they would do. With this game, Nexus explores the hypothetical field.",
      paragraph2:
        "First of all, the host of the game must choose a category. Once chosen, Nexus will randomly select one player each round to answer a hypothetical question that will be displayed on screen.",
      paragraph3:
        "The idea is that the person answers and justifies his answer and the other members of the room can comment on it.",
      paragraph4: "After answering, click on the “NEXT” button.",
    },
    think_fast: {
      paragraph1: `This time, Nexus will put your skills to the test. In think fast, the one who knows the most does not win, but the one who responds the fastest.`,
      paragraph2: `Each round, Nexus gives out a theme. Each Internet user must respond to the topic given by Nexus in less than 10 seconds. On the screen, it will indicate who takes the turn and who is next.`,
      paragraph3: `Every time it is your turn, you must answer (out loud) and once you have answered you must click on the button "I already said the word".`,
      paragraph4: `In this way, the turn passes to the next Internet user until one cannot respond. Every time you cannot respond, you will be accumulating "Radioactive Bombs", which are toxic in high doses. The game ends when a user reaches a certain number of radioactive bombs (you can adjust it manually in the bar below) . Nexus will award prizes to those who have lost the least (accumulated bombs).`,
      paragraph5: `Show your mental agility and remember that you can always improve…`,
    },
    debate: {
      paragraph1: `iOS or Android? Facebook or Twitter? Beach or mountain?`,
      paragraph2: `For tastes, the colors. At Nexus we are aware that pleasure lies in the difference and we have decided to take the debates to the next level.`,
      paragraph3: `Each round, Nexus will display a statement, which Internet users must indicate if they agree or disagree.`,
      paragraph4: `Once all Internet users choose their answers (Agree or No), Nexus will choose 2 Internet users. One who answered “Agree” and another who answered “Disagree to share their points of view and try to convince others that their position is the best.`,
      paragraph5: `The idea of the debate is to better understand the preferences of each Internet user and have fun while exchanging ideas.`,
    },
    who_am_i: {
      paragraph1: `Each round Nexus will select an Internet user who will have to guess a character only by asking other Internet users questions. The questions should be formulated as follows: Am I ____? Example: Am I a contemporary politician? When you think you know the answer, you must write it in the box that will appear under your camera. Try as much as possible since you have to guess in just 1 minute.`,
      paragraph2: `If it is not your turn, you must be attentive to the questions asked by the Internet user who takes the turn. You should only answer the questions with YES or NO. Example: Am I a contemporary politician? Answer: NO.`,
      paragraph3: `You will be able to see the character since it will appear on the screen of the Internet user who takes the turn.`,
      paragraph4: `Tip: If you press the button with the magnifying glass sign, you will be able to see typical questions that may be useful to you.`,
      paragraph5: `At the end of each game, Nexus will reward the Internet users with the best scores.`,
    },
    deep_questions: {
      paragraph1: `Without a doubt, with "Deep Questions" you will have the opportunity to get to know the other Internet users in the room in a genuine way. Answers to questions that no one would dare to ask.`,
      paragraph2: `Each round, Nexus will choose a user to answer a question. The chosen Internet user will appear on a larger screen than the other Internet users.`,
      paragraph3: `If it is your turn to answer and you have already done so, you must click on the "Next" button, to pass the turn to someone else.`,
      paragraph4: `Meanwhile, the other netizens who are listening to the answer to the question will have the possibility to react to the answer with the following feelings.`,
      paragraph5: `At the end of the match, Nexus will reward netizens according to the reactions they got.`,
      paragraph6: `In Nexus the best does not win, the team wins`,
    },
    trivia: {
      paragraph1: `At Nexus, we believe that all human beings are experts at something, even if we don't know it. For this reason, we have created this Trivia that covers everything from the classic rock of the 80s, through Renaissance art to popular video game characters.`,
      paragraph2: `Each round, Nexus will display a question with 4 answer options. Users must answer the questions by choosing the option that each one considers correct.`,
      paragraph3: `At the end of each game, Nexus will reward the Internet users with the best scores. In this game, we seek to show you and the group in which fields you are an expert.`,
      paragraph4: `The game ends when one of the Internet users accumulates a certain amount of stars (you can modify this number in the bottom bar of this screen).`,
      paragraph5: `Remember that there are no losers, only experts in other fields of knowledge.`,
    },
  },
  who_am_i: {
    type_your_guess: "Type your guess",
    clue_1: "Am I human?",
    clue_2: "Am I an animal?",
    clue_3: "Am I young?",
    clue_4: "Am I a historical figure?",
    clue_5: "Am I alive now?",
    its_your_turn:
      "It's your turn. Ask yes/no questions to other players and type the name of the character you guess you are in the box down below.",
  },
  most_likely_to: {
    question_prefix: "Who is most likely to",
    storyTimePrefix: "Tell us why do you think other players selected you?",
    storyTimeSuffix: "is going to tell us why he/she thinks that you chose him/her",
  },
  never_have_i_ever: {
    prefix: "Never have I ever",
  },
  think_fast: {
    say_a_word_about: "Say a word related with",
    i_already_said_the_word: "I already said the word",
    break_time: "Break time",
  },
  a_lie: {
    storyteller: "Storyteller",
    listener: "Listener",
    is_going_to_tell_us_two_truths_and_a_lie: `is going to tell us 2 truths and 1 lie. You have to guess what's the lie and click on the option.`,
    first: "The first story",
    second: "The second story",
    third: "The third story",
    is_the_lie: "is the lie",
    you_have_to_tell_to_the_other_players_two_truths_and_a_lie:
      "You have to tell the other players 2 truths and 1 lie. After that, select which option is the lie.",
    choose_the_lie: "choose the lie",
    chooses_the_lie: "chooses the lie",
  },
  charades: {
    you_have_been_chosen_to_act_out_this_word: "You have been chosen to act out this word",
    has_been_chosen_to_act_out_a_word:
      "has been chosen to act out a word, pay attention to him/her and type the word",
    you_have_to_guess_the_word_that: "You have to guess the word that",
    is_acting_out: "is acting out",
    you_have_to_act_out_this_word: "You have to act out this word",
    type_the_word_that: "Type the word that",
    awesome_you_discovered_the_word: "Awesome, You discovered the word",
    word: "Word",
    nobody_guessed_the_word: "Nobody guessed the word. It was",
    think_your_performance_and: "Think how to act out the word and",
    click_on_the_button: "click the button to start",
    secret_word_was: "The secret word was",
  },
  hypotethically: {
    prefix: "What would you do if",
    storyTimePrefix: "Tell us: What would you do if",
    storyTimeSuffix: "is going to tell us what She/He would do If",
  },
  deep_questions: {
    storyTimePrefix: "Tell us: ",
    storyTimeSuffix: "is going to tell us",
  },
  debate: {
    agree: "Agree",
    disagree: "Disagree",
    all_users_agreed_with: "All players agreed with",
    all_users_disagreed_with: "All players disagreed with",
  },
  story_time: {
    lets_start_story_time: `Let's start story time`,
    story_time_instructions: `Nexus has selected players to share their stories with us. Are you ready?`,
    hey_lets_tell_us_your_story_with: `Hey, let's tell us your story with`,
    is_going_to_tell_us_his_her_story_with: `is going to tell us his/her story with`,
    to_start_story_time: "to start story time",
    lets_listen_the_next_story: `Pass turn`,
  },
  account: {
    user_information: "User information",
    plan: "Plan",
    update_user_information: "Update user information",
    bills: "Orders",
    pay_as_you_go_plan: "Pay-as-you-go plan",
  },
  languages: {
    en: "English",
    es: "Spanish",
    pt: "Portuguese",
    change_language: "Change language",
  },
  messages: {
    try_to_move_your_camera: "You can move your camera, let's try",
    your_feedback_is_important_to_us:
      "Your feedback is important to us, Could you give it to us by clicking this message? It won't take more than 5 minutes.",
  },
  user_plan_management: {
    title: "User manager",
    is_host: "Is host?",
    is_admin: "Is admin?",
    set_as_host: "Set as host",
    remove_from_hosts: "Remove from hosts",
    set_as_admin: "Set as administrator",
    remove_from_admins: "Remove from administrator",
    user: "User",
    status: "Status",
    actions: "Actions",
    add_user: "Add user",
    active_users: "Active users",
    active_hosts: "Active hosts",
    active_admins: "Active administrators",
    write_the_email_of_the_user_you_will_add:
      "Write the email and name of the user you want to add",
    active: "Active",
    inactive: "Inactive",
    the_premium_user: "The premium user",
    has_been_added: "has been successfully added to joinnexus.",
    surely_this_calls_for_a_celebration: "Surely this calls for a celebration.",
    warning_1:
      "Remember that if the user has entered the platform at least once during the current billing period, they will not be able to add another user in their place until the next billing period. If not, you will be able to add another user instantly",
    warning_2: "Are you sure you want to disable access to",
    filter: "Filter by name or email",
    add_users_to_plan: "Add users to plan",
    users_to_buy_for_the_plan: "Number of accounts to buy",
    accounts_added_successfully: "Accounts added successfully",
    payment_by_payu: "Payment by payu",
  },
  billing: {
    you_have_reach_the_limit_of_active_users:
      "You have reached the limit of active users. You can acquire a new license to add this new user.",
    you_have_reach_the_limit_of_admin:
      "You have reached the limit of hosts. You can acquire a new license to add this new user.",
    you_have_reach_the_limit_of_hosts:
      "The limit of host users has been reached, if you need more host users, please contact Joinnexus team",
    you_have_reach_the_limit_of_users_in_your_billing_period:
      "You have reached the limit of users in your billing period. You can acquire a new license to add this new user.",
    payment_date: "Payment date",
    amount: "Amount",
    currency: "Currency",
    accounts_amount: "Accounts amount",
    payment_method: "Payment method",
    billing_information: "Billing information",
    expiration_date: "Expiration date",
    transaction_id: "PayU Transaction",
    pay_bill: "Pay order",
  },
  menu: {
    profile: "Profile",
    users: "Users",
    bills: "Billing",
    logout: "Logout",
    pay_as_you_go: "Your consumption",
    payment_methods: "Payment methods",
    custom_categories: "Custom categories",
    custom_questions: "Custom questions",
    anonymous_feedback: "Anonymous feedback",
    learning_center: "Learning center",
    protons_center: "Recognition center",
    events: "Events",
    analytics: "Analytics",
    feedback_360: "Feedback 360",
  },
  analytics: {
    metric: "Metric",
    feeling_per_month: "Feelings per month",
    month_year: "Month & year",
    protons_in_period: "Recognitions in a period of time",
  },
  feedback_360: {
    self_assessment_score: 'Self-assessment score',
    peers_score: "Peers' score",
    start_feedback_360_process: "Start feedback 360",
    send_feedback_360: "Send feedback 360",
    give_feedback_360: "Give feedback",
    feedback_requests: "Feedback requests",
    feedback_received: "Feedback received",
    no_feedback_requests: 'No feedback requests',
    no_feedbacks: 'There are no feedbacks',
    open_feedback: "Open feedback",
    waiting_for_feedback: "Waiting for feedback",
    feedback_results: 'Results',
    answer_the_following_questions_regarding_the_performance_of:
      "Answer the following questions regarding the performance of",
    answer_the_following_questions_for_yourself: "Answer the following questions for yourself",
    any_comments_you_want_to_add: "Any comments you want to add?",
    comments: 'Comments of question',
    feedback_suffix:
      "To get a more accurate view of your performance, add the email of whom you want to request feedback on your work in the roles below.",
    feedback_suffix_2: "If you don't have anyone for a role, leave the box blank.",
    subordinate: "Email of a subordinate",
    peer: "Email of a peer",
    supervisor: "Email of a supervisor",
    add_a_rating_please: "Add a rating please",
    at_least_somebody_else:
      "Add at least one more person (subordinate, peer or supervisor) to receive your feedback.",
    error_in_form: "There is an error in the form, please review",
  },
  anonymous_feedback: {
    no_anonymous_feedback: "You do not have anonymous feedback",
    add_anonymous_feedback: "Give anonymous feedback",
    feedback: "Anonymous feedback",
    who_is_going_to_receive_your_feedback: "Who's going to receive your anonymous feedback?",
    write_your_feedback: "Write your anonymous feedback",
    send_feedback: "Send anonymous feedback",
  },
  proton_center: {
    proton_center: "Recognition Center",
    no_protons: "You have not given recognitions",
    select_user_title: "Select whom gets the recognition",
    give_proton_title: "Select the recognition and give them a cool message!",
    to: "To:",
    load_more: "Load more",
    why_are_you_sending_this_proton: "Why do you want to send this recognition? Tell us the story",
    send_proton: "Send recognition",
    proton: "Recognition",
    no_proton: "No recognition",
    protons_count: "Recognitions count",
  },
  faq: {
    how_create_new_room: "How to create a room?",
    how_add_new_users: "How to add new users?",
    what_are_admins: "What are administrators?",
    what_are_hosts: "What are the hosts?",
  },
  pay_as_you_go: {
    plan_limit: "Maximum joins to the platform",
    consumption: "Access until this moment",
    plan_updated: "Plan updated",
    entrances: "Entrances",
    room_name: "Room name",
    room_owner: "Room owner",
    room_created_at: "Room join date",
    month_and_year: "Consumption period (Month/Year)",
    pay_as_you_go_consumption: "plan_usage",
  },
  payment_methods: {
    add_credit_card: "Add credit card",
    delete_credit_card: "Delete credit card",
    credit_card_added_successfully: "Credit card added successfully",
    credit_card_deleted_successfully: "Credit card deleted successfully",
    identificationNumber: "Identification number",
    franchise: "Franchise",
    number: "Credit Card Number",
    expirationMonth: "Expiration month",
    expirationYear: "Expiration year",
    are_you_sure_you_want_to_delete_the_credit_card:
      "Are you sure you want to delete the credit card",
    shippingAddress: "Address",
    shippingCity: "City",
    shippingState: "State",
    shippingCountry: "Country",
    postalCode: "Postal code",
    phone: "Phone",
  },
  learning_center: {
    filter_posts: "Filter posts",
  },
  slots_management: {
    current_plan: "Current plan",
    renovation_plan: "Renovation plan",
    slots: "slots",
    available_slots: "Available Slots",
    update_renovation_plan: 'Update renovation plan',
    update_current_plan: 'Update current plan',
    subdomains_management: 'Domains management',
  },
  months: {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  },
  feelings: {
    tell_us_how_do_you_feel_today: "How do you feel today?",
    overwhelmed: "Overwhelmed",
    sad: "Sad",
    neutral: "Neutral",
    fine: "Fine",
    excited: "Excited",
  },
  countries: {
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "Samoa Americana",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguila",
    AQ: "Antartida",
    AG: "Antigua y Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaiyán",
    BS: "Bahamas",
    BH: "Bahréin",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Bielorrusia",
    BE: "Bélgica",
    BZ: "Belice",
    BJ: "Benín",
    BM: "Bermudas",
    BT: "Bután",
    BO: "Bolivia",
    BA: "Bosnia y Herzegovina",
    BW: "Botsuana",
    BV: "Isla Bouvet",
    BR: "Brasil",
    IO: "Territorio Británico del Océano Índico",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Camboya",
    CM: "Camerún",
    CA: "Canada",
    CV: "Cabo Verde",
    KY: "Islas Caimán",
    CF: "República Centroafricana",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Isla de Navidad",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, Democratic Republic",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: 'Cote D"Ivoire',
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island & Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic Of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle Of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KR: "Korea",
    KP: "North Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: 'Lao People"s Democratic Republic',
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States Of",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena",
    KN: "Saint Kitts And Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre And Miquelon",
    VC: "Saint Vincent And Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome And Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia And Sandwich Isl.",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard And Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad And Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks And Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis And Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
  },
};
