/* eslint-disable react/jsx-no-target-blank */
import { Checkbox } from "@mui/material";
import AlternativeIcon from "assets/images/alternative_icon.webp";
import { ChangeLanguageButton } from "components";
import Logo from "components/logo/logo";
import SocialLoginButton from "components/social-login-button/social-login-button";
import { SocialMedia } from "enums/social-media";
import { GalacticWallpaper } from "hoc";
import { sanitizeLanguage } from "lang/i18";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "./login.page.module.css";
import { useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const lang = sanitizeLanguage(i18n.language);
  const [isChecked, setIsChecked] = useState(false);

  const hasAccepted = localStorage.getItem("terms_and_conditions");

  console.log();

  const label = searchParams.get("signUp") ? "login.sign_up_with" : "login.sign_in_with";

  let disclaimer = null;

  if (!hasAccepted) {
    disclaimer = (
      <h6 className={Styles.disclaimer}>
        <Checkbox value={isChecked} onChange={e => setIsChecked(!!e.target.value)} />
        <label>
          {t("landing.disclaimer.part_1")}
          <a target="_blank" href={`https://joinnexus.io/legal/${lang}/terms-and-conditions.pdf`}>
            {t("landing.disclaimer.part_2")}
          </a>
          {t("landing.disclaimer.part_3")}
          <a target="_blank" href={`https://joinnexus.io/legal/${lang}/privacy.pdf`}>
            {t("landing.disclaimer.part_4")}
          </a>
        </label>
      </h6>
    );
  }

  const validationReady = !!hasAccepted || (!hasAccepted && isChecked);

  return (
    <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
      <div className={`${Styles.content}`}>
        <Logo></Logo>
        <img
          className={`maxWidth100vw ${Styles.centerIcon}`}
          src={AlternativeIcon}
          alt="Alternative icon"
        />
        <h2 className="neon">{t(label)}</h2>
        <div className={`centerContent ${Styles.socialMediaButtonsContainer}`}>
          <SocialLoginButton
            validationPending={!validationReady}
            socialMedia={SocialMedia.GOOGLE}
          ></SocialLoginButton>
          <SocialLoginButton
            validationPending={!validationReady}
            socialMedia={SocialMedia.SLACK}
          ></SocialLoginButton>
          <SocialLoginButton
            validationPending={!validationReady}
            socialMedia={SocialMedia.OFFICE365}
          ></SocialLoginButton>
        </div>
        {disclaimer}
      </div>
      <ChangeLanguageButton />
    </GalacticWallpaper>
  );
};

export default LoginPage;
