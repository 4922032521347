import { NexusSelect } from "components";
import { times } from "lodash";
import { useTranslation } from "react-i18next";

const year = new Date().getFullYear() - 2000;

const options = times(15).map((_, index) => ({
  value: year + index,
  label: String(year + index + 2000),
}));

export const YearInput = () => {
  const { t } = useTranslation();
  return (
    <NexusSelect
      items={options}
      label={`${t("payment_methods.expirationYear")}*`}
      name="expirationYear"
      type="number"
      maxLength={2}
    />
  );
};
