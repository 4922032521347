import FaqIcon from "assets/icons/platform/faq.svg";
import { GameButton } from "components";
import { GalacticColors } from "enums/galactic-backgrounds";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routing } from "routing";
import { RootState, useShallowEqualSelector } from "store";
import Styles from "./index.module.css";

export const LearningButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { email } = useShallowEqualSelector((state: RootState) => ({
    email: state.user.email,
  }));
  const isEpam = email && email.includes("epam");
  if (isEpam) {
    return null;
  }
  const learningUrl = `/${Routing.platformModule}/${Routing.platform.learningCenter}`;
  return (
    <>
      <div className={Styles.learningButtonContainer}>
        <GameButton
          imageSrc={FaqIcon}
          backgroundImage={GalacticColors.BLUE}
          isRound={true}
          tooltip={t("menu.learning_center")}
          onClick={() => navigate(learningUrl)}
        />
      </div>
    </>
  );
};
