import { yupResolver } from "@hookform/resolvers/yup";
import { RoomType } from "enums";
import { get } from "lodash";
import { Room } from "models";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Routing } from "routing";
import { roomService } from "services";
import * as yup from "yup";
import { Props } from ".";
import { ActionType } from "../../reducer";

const timeframe = {
  [RoomType.ICEBREAKER]: 15,
  [RoomType.COFFEE_TIME]: 30,
  [RoomType.MEET_AND_CHILL]: 60,
};

export const useController = ({ dispatch }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const roomTypes = Object.values(RoomType).map(value => ({
    value,
    label: `${t(`rooms.${value}`)} - ${timeframe[value]} ${t("common.minutes")}`,
  }));

  const requiredMessage = t("common.is_required");

  const validationSchema = yup.object().shape({
    name: yup.string().required(`${t("common.name")} ${requiredMessage}`),
    type: yup.string().required(`${t("common.room_type")} ${requiredMessage}`),
    linkAfterRoom: yup.string(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      type: RoomType.COFFEE_TIME,
      linkAfterRoom: "",
    },
  });

  const createNewRoom = async (name: string, type: string, linkAfterRoom: string) => {
    dispatch({ type: ActionType.IS_LOADING });
    try {
      const response = await roomService.createRoom(name, type, linkAfterRoom);
      const room: Room = response.data;
      window.location.replace(`/${Routing.platformModule}/${Routing.platform.room}/${room.pin}`);
    } catch (e: unknown) {
      const message = get(e, "response.data.message");
      switch (message) {
        case "user_has_an_active_room":
          dispatch({ type: ActionType.OPEN_ROOM_ERROR_DIALOG });
          break;
        default:
          toast.error(t(message));
          break;
      }
    } finally {
      dispatch({ type: ActionType.IS_NOT_LOADING });
    }
  };

  const onSubmit = async (values: unknown) => {
    const { name, type, linkAfterRoom } = values as {
      name: string;
      type: string;
      linkAfterRoom: string;
    };
    try {
      setIsLoading(true);
      await createNewRoom(name, type, linkAfterRoom);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    methods,
    roomTypes,
    onSubmit,
  };
};
