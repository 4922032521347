import PlanetBackground from "assets/backgrounds/section_news_wallpaper.webp";
import { useCurrentLanguage } from "hooks";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { Card } from "./card";
import { news } from "./contants";
import Styles from "./index.module.css";

export const News = () => {
  const currentLanguage = useCurrentLanguage();
  const { t } = useTranslation();
  return (
    <section className={`fitWallpaper`} style={{ background: `url(${PlanetBackground})` }}>
      <div>
        <p className={Styles.title}>{t("landing.news.title")}</p>
        <p className={Styles.subtitle}>{t("landing.news.subtitle")}</p>
      </div>
      <div className={`centerContent`}>
        <div className={`neonScroll ${Styles.container}`}>
          {news.map(({ image, languages }) => (
            <div key={`${image}`}>
              <Card
                image={image}
                title={get(languages, `${currentLanguage}.title`)}
                subtitle={get(languages, `${currentLanguage}.subtitle`)}
                url={get(languages, `${currentLanguage}.url`)}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
