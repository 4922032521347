import NeonBox from "components/neon-box/neon-box";
import { GalacticWallpaper } from "hoc";
import Styles from "./index.module.css";

export const PrivacyPolicy = () => {
  return (
    <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
      <NeonBox className={Styles.container}>
        <h1 className="neon">Privacy policy</h1>
        <p className="s2">Last updated September 01, 2022</p>
        <p>
          This privacy notice for Internaut S.A.S (&quot;<b>Company</b>,&quot; &quot;<b>we</b>
          ,&quot; &quot;<b>us</b>,&quot; or &quot;<b>our</b>&quot;), describes how and why we might
          collect, store, use, and/or share (&quot;<b>process</b>&quot;) your information when you
          use our services (&quot;<b>Services</b>&quot;), such as when you:
        </p>

        <p className="s3">
          Visit our website at{" "}
          <a href="https://joinnexus.io/" target="_blank" rel="noreferrer">
            https://joinnexus.io
          </a>
          <span>
            , or any website of ours that links to this privacy notice. Engage with us in other
            related ways, including any sales, marketing, or events
          </span>
        </p>
        <p className="s3">
          Questions or concerns? Reading this privacy notice will help you understand your privacy
          rights and choices. If you do not agree with our policies and practices, please do not use
          our Services. If you still have any questions or concerns, please contact us at{" "}
          <a href="mailto:contact@internaut.io" target="_blank" rel="noreferrer">
            contact@internaut.io
          </a>
          <span>.</span>
        </p>
        <h2>SUMMARY OF KEY POINTS</h2>
        <p>
          This summary provides key points from our privacy notice, but you can find out more
          details about any of these topics by clicking the link following each key point or by
          using our table of contents below to find the section you are looking for.
        </p>
        <b>What personal information do we process? </b>
        <span className="p">
          When you visit, use, or navigate our Services, we may process personal information
          depending on how you interact with Internaut S.A.S and the Services, your choices, and the
          products and features you use.
        </span>
        <br />
        <br />
        <b>Do we process any sensitive personal information?</b>
        <span className="p">We do not process sensitive personal information.</span>

        <br />
        <br />
        <b>Do we receive any information from third parties?</b>
        <span className="p">We do not receive any information from third parties.</span>

        <br />
        <br />
        <b> How do we process your information?</b>
        <span className="p">
          We process your information to provide, improve, and administer our Services, communicate
          with you, for security and fraud prevention, and comply with the law. We may also process
          your information for other purposes with your consent. We process your information only
          when we have a valid legal reason to do so.
        </span>

        <br />
        <br />
        <b>In what situations and with which types of parties do we share personal information?</b>
        <span className="p">
          We may share information in specific situations and with specific categories of third
          parties.
        </span>

        <br />
        <br />
        <b>How do we keep your information safe?</b>
        <span className="p">
          We have organizational and technical processes and procedures in place to protect your
          personal information. However, no electronic transmission over the internet or information
          storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will not be able to
          defeat our security and improperly collect, access, steal, or modify your information.
        </span>

        <br />
        <br />
        <b>What are your rights?</b>
        <span className="p">
          Depending on where you are located geographically, the applicable privacy law may mean you
          have certain rights regarding your personal information.
        </span>

        <br />
        <br />
        <b> How do you exercise your rights?</b>
        <span className="p">
          The easiest way to exercise your rights is by contacting us. We will consider and act upon
          any request in accordance with applicable data protection laws.
        </span>

        <h1>Table of contents</h1>

        <ol id="l1">
          <li data-list-text="1.">
            <p>
              <a href="#bookmark1">WHAT INFORMATION DO WE COLLECT?</a>
            </p>
          </li>
          <li data-list-text="2.">
            <p>
              <a href="#bookmark2">HOW DO WE PROCESS YOUR INFORMATION?</a>
            </p>
          </li>
          <li data-list-text="3.">
            <p>
              <a href="#bookmark3">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</a>
            </p>
          </li>
          <li data-list-text="4.">
            <p>
              <a href="#bookmark4">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
            </p>
          </li>
          <li data-list-text="5.">
            <p>
              <a href="#bookmark5">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
            </p>
          </li>
          <li data-list-text="6.">
            <p>
              <a href="#bookmark6">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
            </p>
          </li>
          <li data-list-text="7.">
            <p>
              <a href="#bookmark7">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a>
            </p>
          </li>
          <li data-list-text="8.">
            <p>
              <a href="#bookmark8">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </p>
          </li>
          <li data-list-text="9.">
            <p>
              <a href="#bookmark9">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </p>
          </li>
          <li data-list-text="10.">
            <p>
              <a href="#bookmark10">DO WE COLLECT INFORMATION FROM MINORS?</a>
            </p>
          </li>
          <li data-list-text="11.">
            <p>
              <a href="#bookmark11">WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </p>
          </li>
          <li data-list-text="12.">
            <p>
              <a href="#bookmark12">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </p>
          </li>
          <li data-list-text="13.">
            <p>
              <a href="#bookmark13">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
            </p>
          </li>
          <li data-list-text="14.">
            <p>
              <a href="#bookmark14">DO WE MAKE UPDATES TO THIS NOTICE?</a>
            </p>
          </li>
          <li data-list-text="15.">
            <p>
              <a href="#bookmark15">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
            </p>
          </li>
          <li data-list-text="16.">
            <p>
              <a href="#bookmark16">
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
              </a>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol id="l2">
          <li data-list-text="1.">
            <h2 id="bookmark1">WHAT INFORMATION DO WE COLLECT?</h2>
            <b>The personal information you disclose to us</b>
            <br /> <br />
            <b className="s5">In Short: </b>
            <span className="s6">We collect personal information that you provide to us.</span>
            <p>
              We collect personal information that you voluntarily provide to us when you register
              on the Services, express an interest in obtaining information about us or our products
              and Services, when you participate in activities on the Services, or otherwise when
              you contact us.
            </p>
            <b>Personal Information Provided by You.</b>
            <span className="p">
              The personal information that we collect depends on the context of your interactions
              with us and the Services, the choices you make, and the products and features you use.
              The personal information we collect may include the following:
            </span>
            <ul>
              <li>names</li>
              <li>email addresses</li>
            </ul>
            <br />
            <b>Sensitive Information. </b>
            <span className="p">We do not process sensitive information.</span>
            <br /> <br />
            <b>Payment Data. </b>
            <span>
              We may collect data necessary to process your payment if you make purchases, such as
              your payment instrument number (such as a credit card number), and the security code
              associated with your payment instrument. All payment data is stored by payu. You may
              find their privacy notice link(s) here:{" "}
              <a href="https://payu.in/privacy-policy" rel="noreferrer" target="_blank">
                https://payu.in/privacy-policy
              </a>
              .
            </span>
            <br /> <br />
            <b>Social Media Login Data. </b>
            <span>
              We may provide you with the option to register with us using your existing social
              media account details, like your Facebook, Twitter, or other social media account. If
              you choose to register in this way, we will collect the information described in the
              section called
            </span>
            <b> &quot;HOW DO WE HANDLE YOUR SOCIAL LOGINS?&quot; </b>
            below.
            <p>
              All personal information that you provide to us must be true, complete, and accurate,
              and you must notify us of any changes to such personal information.
            </p>
            <b>Information automatically collected</b>
            <br /> <br />
            <b>In Short: </b>
            <span>
              Some information — such as your Internet Protocol (IP) address and/or browser and
              device characteristics — is collected automatically when you visit our Services.
            </span>
            <p>
              We automatically collect certain information when you visit, use, or navigate the
              Services. This information does not reveal your specific identity (like your name or
              contact information) but may include device and usage information, such as your IP
              address, browser, and device characteristics, operating system, language preferences,
              referring URLs, device name, country, location, information about how and when you use
              our Services, and other technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our internal analytics
              and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies and similar
              technologies. The information we collect includes:
            </p>
            <ul>
              <li>
                <b>Log and Usage Data. </b>
                <span className="p">
                  Log and usage data are service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access or use our Services
                  and which we record in log files. Depending on how you interact with us, this log
                  data may include your IP address, device information, browser type, and settings
                  and information about your activity in the services (such as the date/time stamps
                  associated with your usage, pages, and files viewed, searches, and other actions
                  you take such as which features you use), device event information (such as system
                  activity, error reports (sometimes called &quot;crash dumps&quot;), and hardware
                  settings).
                </span>
              </li>
              <li>
                <b>Device Data.</b>
                <span>
                  We collect device data such as information about your computer, phone, tablet, or
                  other devices you use to access the Services. Depending on the device used, this
                  device data may include information such as your IP address (or proxy server),
                  device and application identification numbers, location, browser type, hardware
                  model, Internet service provider and/or mobile carrier, operating system, and
                  system configuration information.
                </span>
              </li>
              <li>
                <b>Location Data.</b>
                <span className="p">
                  We collect location data such as information about your device&#39;s location,
                  which can be either precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the Services. For
                  example, we may use GPS and other technologies to collect geolocation data that
                  tells us your current location (based on your IP address). You can opt-out of
                  allowing us to collect this information either by refusing access to the
                  information or by disabling your Location setting on your device. However, if you
                  choose to opt-out, you may not be able to use certain aspects of the Services.
                </span>
              </li>
            </ul>
          </li>
          <li data-list-text="2.">
            <h2 id="bookmark2">HOW DO WE PROCESS YOUR INFORMATION?</h2>
            <p>
              <b>In Short: </b> We process your information to provide, improve, and administer our
              Services, communicate with you, for security and fraud prevention, and comply with the
              law. We may also process your information for other purposes with your consent.
            </p>
            <b>
              We process your personal information for a variety of reasons, depending on how you
              interact with our Services, including:
            </b>
            <br /> <br />
            <ul>
              <li>
                <b>
                  To facilitate account creation and authentication and otherwise manage user
                  accounts.
                </b>
                We may process your information so you can create and log in to your account, as
                well as keep your account in. working order.
              </li>
              <li>
                <b>To send administrative information to you.</b>
                We may process your information to send you details about our products and services,
                changes to our terms and policies, and other similar information.
              </li>
              <li>
                <b>To fulfill and manage your orders.</b>
                We may process your information to fulfill and manage your orders, payments,
                returns, and exchanges made through the Services.
              </li>
              <li>
                <b>To request feedback.</b>
                We may process your information when necessary to request feedback and to contact
                you about your use of our Services.
              </li>
              <li>
                <b>To identify usage trends.</b>
                We may process information about how you use our Services to better understand how
                they are being used so we can improve them.
              </li>
              <li>
                <b>To save or protect an individual&#39;s vital interest.</b>
                We may process your information when necessary to save or protect an individual’s
                vital interest, such as to prevent harm.
              </li>
            </ul>
          </li>
          <li data-list-text="3.">
            <h2 id="bookmark3">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
            <p>
              <b>In Short: </b> We only process your personal information when we believe it is
              necessary and we have a valid legal reason (i.e., legal basis) to do so under
              applicable law, like with your consent, to comply with laws, to provide you with
              services to enter into or fulfill our contractual obligations, to protect your rights,
              or to fulfill our legitimate business interests.
            </p>
            <p className="s7">If you are located in the EU or UK, this section applies to you.</p>
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the
              valid legal bases we rely on in order to process your personal information. As such,
              we may rely on the following legal bases to process your personal information:
            </p>

            <ul>
              <li>
                <b>Consent. </b>We may process your information if you have given us permission
                (i.e., consent) to use your personal information for a specific purpose. You can
                withdraw your consent at any time.
              </li>
              <li>
                <b>Performance of a Contract.</b>We may process your personal information when we
                believe it is necessary to fulfill our contractual obligations to you, including
                providing our Services or at your request prior to entering into a contract with
                you.
              </li>
              <li>
                <b>Legitimate Interests.</b>We may process your information when we believe it is
                reasonably necessary to achieve our legitimate business interests and those
                interests do not outweigh your interests and fundamental rights and freedoms. For
                example, we may process your personal information for some of the purposes described
                in order to:
                <ul>
                  <li>
                    Analyze how our services are used so we can improve them to engage and retain
                    users.
                  </li>
                  <li>
                    Understand how our users use our products and services so we can improve user
                    experience
                  </li>
                </ul>
              </li>
              <li>
                <b>Legal Obligations.</b>
                We may process your information where we believe it is necessary for compliance with
                our legal obligations, such as to cooperate with a law enforcement body or
                regulatory agency, exercise or defend our legal rights, or disclose your information
                as evidence in litigation in which we are involved.
              </li>

              <li>
                <b>Vital Interests.</b>
                We may process your information where we believe it is necessary to protect your
                vital interests or the vital interests of a third party, such as situations
                involving potential threats to the safety of any person.
              </li>
            </ul>
            <br />
            <b>If you are located in Canada, this section applies to you.</b>
            <p>
              We may process your information if you have given us specific permission (i.e.,
              express consent) to use your personal information for a specific purpose, or in
              situations where your permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time.
            </p>
            <p>
              In some exceptional cases, we may be legally permitted under applicable law to process
              your information without your consent, including, for example:
            </p>
            <ul>
              <li>
                If collection is clearly in the interests of an individual and consent cannot be
                obtained in a timely way
              </li>
              <li>For investigations and fraud detection and prevention</li>
              <li>For business transactions provided certain conditions are met</li>
              <li>
                If it is contained in a witness statement and the collection is necessary to assess,
                process, or settle an insurance claim
              </li>
              <li>
                For identifying injured, ill, or deceased persons and communicating with next of kin
              </li>
              <li>
                If we have reasonable grounds to believe an individual has been, is, or may be a
                victim of financial abuse
              </li>
              <li>
                If it is reasonable to expect collection and use with consent would compromise the
                availability or the accuracy of the information and the collection is reasonable for
                purposes related to investigating a breach of an agreement or a contravention of the
                laws of Canada or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant, court order, or rules
                of the court relating to the production of records
              </li>
              <li>
                If it was produced by an individual in the course of their employment, business, or
                profession and the collection is consistent with the purposes for which the
                information was produced
              </li>
              <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
              <li>If the information is publicly available and is specified by the regulations</li>
            </ul>
          </li>
          <li data-list-text="4.">
            <h2 id="bookmark4">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
            <p>
              <b>In Short: </b> We may share information in specific situations described in this
              section and/or with the following categories of third parties.
            </p>
            <p>
              <b>Vendors, Consultants, and Other Third-Party Service Providers. </b> We may share
              your data with third-party vendors, service providers, contractors, or agents (&quot;
              third parties &quot;) who perform services for us or on our behalf and require access
              to such information to do that work. We have contracts in place with our third
              parties, which are designed to help safeguard your personal information. This means
              that they cannot do anything with your personal information unless we have instructed
              them to do it. They will also not share your personal information with any
              organization apart from us. They also commit to protect the data they hold on our
              behalf and to retain it for the period we instruct. The categories of third parties we
              may share personal information with are as follows:
            </p>

            <ul>
              <li>Data Analytics Services</li>
              <li>Payment Processors</li>
              <li>User Account Registration &amp; Authentication Services</li>
            </ul>
            <p>We also may need to share your personal information in the following situations:</p>

            <ul>
              <li>
                <b>Business Transfers. </b>We may share or transfer your information in connection
                with, or during negotiations of, any merger, sale of company assets, financing, or
                acquisition of all or a portion of our business to another company.
              </li>
            </ul>
          </li>
          <li data-list-text="5.">
            <h2 id="bookmark5">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
            <p>
              <b>In Short:</b>We may use cookies and other tracking technologies to collect and
              store your information.
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web beacons and pixels) to
              access or store information. Specific information about how we use such technologies
              and how you can refuse certain cookies is set out in our Cookie Notice.
            </p>
          </li>
          <li data-list-text="6.">
            <h2 id="bookmark6">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
            <p>
              <b>In Short:</b>If you choose to register or log in to our services using a social
              media account, we may have access to certain information about you.
            </p>
            <p>
              Our Services offer you the ability to register and log in using your third-party
              social media account details (like your Facebook or Twitter logins). Where you choose
              to do this, we will receive certain profile information about you from your social
              media provider. The profile information we receive may vary depending on the social
              media provider concerned, but will often include your name, email address, friends
              list, and profile picture, as well as other information you choose to make public on
              such a social media platform.
            </p>
            <p>
              We will use the information we receive only for the purposes that are described in
              this privacy notice or that are otherwise made clear to you on the relevant Services.
              Please note that we do not control, and are not responsible for, other uses of your
              personal information by your third-party social media provider. We recommend that you
              review their privacy notice to understand how they collect, use, and share your
              personal information, and how you can set your privacy preferences on their sites and
              apps.
            </p>
          </li>
          <li data-list-text="7.">
            <h2 id="bookmark7">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
            <p>
              <b>In Short:</b>We may transfer, store, and process your information in countries
              other than your own.
            </p>
            <p>
              Our servers are located in the United States. If you are accessing our Services from
              outside the United States, please be aware that your information may be transferred
              to, stored, and processed by us in our facilities and by those third parties with whom
              we may share your personal information (see &quot;WHEN AND WITH WHOM DO WE SHARE YOUR
              PERSONAL INFORMATION?&quot; above), in the United States, and other countries.
            </p>
            <p>
              If you are a resident in the European Economic Area (EEA) or the United Kingdom (UK),
              then these countries may not necessarily have data protection laws or other similar
              laws as comprehensive as those in your country. However, we will take all necessary
              measures to protect your personal information in accordance with this privacy notice
              and applicable law.
            </p>
            <p>European Commission&#39;s Standard Contractual Clauses:</p>
            <p>
              We have implemented measures to protect your personal information, including by using
              the European Commission&#39;s Standard Contractual Clauses for transfers of personal
              information between our group companies and between us and our third-party providers.
              These clauses require all recipients to protect all personal information that they
              process originating from the EEA or UK in accordance with European data protection
              laws and regulations. Our Standard Contractual Clauses can be provided upon request.
              We have implemented similar appropriate safeguards with our third-party service
              providers and partners and further details can be provided upon request.
            </p>
          </li>
          <li data-list-text="8.">
            <h2 id="bookmark8">HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
            <p>
              <b>In Short: </b>We keep your information for as long as necessary to fulfill the
              purposes outlined in this privacy notice unless otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is necessary for the
              purposes set out in this privacy notice unless a longer retention period is required
              or permitted by law (such as tax, accounting, or other legal requirements). No purpose
              in this notice will require us to keep your personal information for longer than the
              period of time in which users have an account with us.
            </p>
            <p>
              When we have no ongoing legitimate business need to process your personal information,
              we will either delete or anonymize such information or, if this is not possible (for
              example, because your personal information has been stored in backup archives), then
              we will securely store your personal information and isolate it from any further
              processing until deletion is possible.
            </p>
          </li>
          <li data-list-text="9.">
            <h2 id="bookmark9">HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
            <p>
              <b>In Short: </b>We aim to protect your personal information through a system of
              organizational and technical security measures.
            </p>
            <p>
              We have implemented appropriate and reasonable technical and organizational security
              measures designed to protect the security of any personal information we process.
              However, despite our safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology can be guaranteed to
              be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
              other unauthorized third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information. Although we will do our
              best to protect your personal information, the transmission of personal information to
              and from our Services is at your own risk. You should only access the Services within
              a secure environment.
            </p>
          </li>
          <li data-list-text="10.">
            <h2 id="bookmark10">DO WE COLLECT INFORMATION FROM MINORS?</h2>
            <p>
              <b>In Short:</b> We do not knowingly collect data from or market to children under 18
              years of age.
            </p>
            <p className="s3">
              We do not knowingly solicit data from or market to children under 18 years of age. By
              using the Services, you represent that you are at least 18 or that you are the parent
              or guardian of such a minor and consent to such minor dependent’s use of the Services.
              If we learn that personal information from users less than 18 years of age has been
              collected, we will deactivate the account and take reasonable measures to promptly
              delete such data from our records. If you become aware of any data we may have
              collected from children under the age of 18, please contact us at{" "}
              <a href="mailto:contact@internaut.io" target="_blank" rel="noreferrer">
                contact@internaut.io
              </a>
              .
            </p>
          </li>
          <li data-list-text="11.">
            <h2 id="bookmark11">WHAT ARE YOUR PRIVACY RIGHTS?</h2>
            <p>
              <b>In Short:</b> In some regions, such as the European Economic Area (EEA), United
              Kingdom (UK), and Canada, you have rights that allow you greater access to and control
              over your personal information. You may review, change, or terminate your account at
              any time.
            </p>

            <p>
              In some regions (like the EEA, UK, and Canada), you have certain rights under
              applicable data protection laws. These may include the right (i) to request access and
              obtain a copy of your personal information, (ii) to request rectification or erasure;
              (iii) to restrict the processing of your personal information; and (iv) if applicable,
              to data portability. In certain circumstances, you may also have the right to object
              to the processing of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section &quot;HOW CAN YOU
              CONTACT US ABOUT THIS NOTICE?&quot; below.
            </p>
            <p>
              We will consider and act upon any request in accordance with applicable data
              protection laws.
            </p>
            <p>
              If you are located in the EEA or UK and you believe we are unlawfully processing your
              personal information, you also have the right to complain to your local data
              protection supervisory authority. You can find their contact details here:{" "}
              <a
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                target="_blank"
                rel="noreferrer"
              >
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
            </p>
            <p>
              If you are located in Switzerland, the contact details for the data protection
              authorities are available here:{" "}
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.edoeb.admin.ch/edoeb/en/home.html
              </a>
            </p>

            <p className="s7">
              <b>Withdrawing your consent: </b>
              <span className="p">
                If we are relying on your consent to process your personal information, which may be
                express and/or implied consent depending on the applicable law, you have the right
                to withdraw your consent at any time. You can withdraw your consent at any time by
                contacting us by using the contact details provided in the section &quot;HOW CAN YOU
                CONTACT US ABOUT THIS NOTICE?&quot; below or by updating your preferences.
              </span>
            </p>
            <p>
              However, please note that this will not affect the lawfulness of the processing before
              its withdrawal nor, when applicable law allows, will it affect the processing of your
              personal information conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <b>Account Information</b>

            <p>
              If you would at any time like to review or change the information in your account or
              terminate your account, you can:
            </p>
            <ul>
              <li>Log in to your account settings and update your user account.</li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or delete your account
              and information from our active databases. However, we may retain some information in
              our files to prevent fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms, and/or comply with applicable legal requirements.
            </p>
            <p className="s7">
              <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies
              by default. If you prefer, you can usually choose to set your browser to remove
              cookies and reject cookies. If you choose to remove cookies or reject cookies, this
              could affect certain features or services of our Services. To opt-out of
              interest-based advertising by advertisers on our Services visit{" "}
              <a href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">
                http://www.aboutads.info/choices/
              </a>
              <span className="p">.</span>
            </p>
            <p className="s3">
              If you have questions or comments about your privacy rights, you may email us at{" "}
              <a href="mailto:contact@internaut.io" target="_blank" rel="noreferrer">
                contact@internaut.io
              </a>
            </p>
          </li>
          <li data-list-text="12.">
            <h2 id="bookmark12">CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
            <p>
              Most web browsers and some mobile operating systems and mobile applications include a
              Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your
              privacy preference and not to have data about your online browsing activities
              monitored and collected. At this stage, no uniform technology standard for recognizing
              and implementing DNT signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically communicates your
              choice not to be tracked online. If a standard for online tracking is adopted that we
              must follow in the future, we will inform you about that practice in a revised version
              of this privacy notice.
            </p>
          </li>
          <li data-list-text="13.">
            <h2 id="bookmark13">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
            <p>
              <b>In Short:</b> Yes, if you are a resident of California, you are granted specific
              rights regarding access to your personal information.
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot;
              law, permits our users who are California residents to request and obtain from us,
              once a year and free of charge, information about categories of personal information
              (if any) we disclosed to third parties for direct marketing purposes and the names and
              addresses of all third parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California resident and would like
              to make such a request, please submit your request in writing to us using the contact
              information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a registered account
              with Services, you have the right to request the removal of unwanted data that you
              publicly post on the Services. To request the removal of such data, please contact us
              using the contact information provided below and include the email address associated
              with your account and a statement that you reside in California. We will make sure the
              data is not publicly displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from all our systems (e.g., backups,
              etc.).
            </p>
            <b>CCPA Privacy Notice</b>
            <p>The California Code of Regulations defines a &quot;resident&quot; as:</p>

            <ul>
              <li>
                (1) every individual who is in the State of California for other than a temporary or
                transitory purpose and
              </li>
              <li>
                (2) every individual who is domiciled in the State of California who is outside the
                State of California for a temporary or transitory purpose
              </li>
            </ul>

            <p>All other individuals are defined as &quot;non-residents.&quot;</p>
            <p>
              If this definition of &quot;resident&quot; applies to you, we must adhere to certain
              rights and obligations regarding your personal information. What categories of
              personal information do we collect?
            </p>
            <p>
              We have collected the following categories of personal information in the past twelve
              (12) months:
            </p>

            <table className={Styles.table}>
              <tr>
                <td>
                  <p className="s9">Category</p>
                </td>
                <td>
                  <p className="s9">Examples</p>
                </td>
                <td>
                  <p className="s9">Collected</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">A. Identifiers</p>
                </td>
                <td>
                  <p className="s10">
                    Contact details, such as real name, alias, postal address, telephone or mobile
                    contact number, unique personal identifier, online identifier, Internet Protocol
                    address, email address, and account name
                  </p>
                </td>
                <td>
                  <p className="s10">YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">
                    B. Personal information categories listed in the California Customer Records
                    statute
                  </p>
                </td>
                <td>
                  <p className="s10">
                    Name, contact information, education, employment, employment history, and
                    financial information
                  </p>
                </td>
                <td>
                  <p className="s10">YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">
                    C. Protected classification characteristics under California or federal law
                  </p>
                </td>
                <td>
                  <p className="s10">Gender and date of birth</p>
                </td>
                <td>
                  <p className="s10">NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">D. Commercial information</p>
                </td>
                <td>
                  <p className="s10">
                    Transaction information, purchase history, financial details, and payment
                    information
                  </p>
                </td>
                <td>
                  <p className="s10">YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">E. Biometric information</p>
                </td>
                <td>
                  <p className="s10">Fingerprints and voiceprints</p>
                </td>
                <td>
                  <p className="s10">NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">F. Internet or other similar network activity</p>
                </td>
                <td>
                  <p className="s10">
                    Browsing history, search history, online behavior, interest data, and
                    interactions with our and other websites, applications, systems, and
                    advertisements
                  </p>
                </td>
                <td>
                  <p className="s10">YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">G. Geolocation data</p>
                </td>
                <td>
                  <p className="s10">Device location</p>
                </td>
                <td>
                  <p className="s10">YES</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">
                    H. Audio, electronic, visual, thermal, olfactory, or similar information
                  </p>
                </td>
                <td>
                  <p className="s10">
                    Images and audio, video or call recordings created in connection with our
                    business activities
                  </p>
                </td>
                <td>
                  <p className="s10">NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">I. Professional or employment-related information</p>
                </td>
                <td>
                  <p className="s10">
                    Business contact details in order to provide you our Services at a business
                    level or job title, work history, and professional qualifications if you apply
                    for a job with us
                  </p>
                </td>
                <td>
                  <p className="s10">NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">J. Education Information</p>
                </td>
                <td>
                  <p className="s10">Student records and directory information</p>
                </td>
                <td>
                  <p className="s10">NO</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="s10">K. Inferences drawn from other personal information</p>
                </td>
                <td>
                  <p className="s10">
                    Inferences drawn from any of the collected personal information listed above to
                    create a profile or summary about, for example, an individual’s preferences and
                    characteristics
                  </p>
                </td>
                <td>
                  <p className="s10">YES</p>
                </td>
              </tr>
            </table>
            <p>
              We may also collect other personal information outside of these categories through
              instances where you interact with us in person, online, or by phone or mail in the
              context of:
            </p>

            <ul>
              <li>Receiving help through our customer support channels;</li>
              <li>Participation in customer surveys or contests; and</li>
              <li>
                Facilitation in the delivery of our Services and responding to your inquiries.
              </li>
            </ul>
            <br />
            <b>How do we use and share your personal information?</b>
            <p>Internaut S.A.S collects and shares your personal information through:</p>
            <ul>
              <li>Targeting cookies/Marketing cookies</li>
            </ul>
            <p>
              More information about our data collection and sharing practices can be found in this
              privacy notice.
            </p>
            <p className="s3">
              You may contact us by email at{" "}
              <a href="mailto:contact@internaut.io" rel="noreferrer" target="_blank">
                contact@internaut.io
              </a>
              <span>
                , by calling toll-free at +57 312 525 66 55, or by referring to the contact details
                at the bottom of this document.
              </span>
            </p>
            <p>
              If you are using an authorized agent to exercise your right to opt-out we may deny a
              request if the authorized agent does not submit proof that they have been validly
              authorized to act on your behalf.
            </p>
            <p>
              <b>Will your information be shared with anyone else?</b>
            </p>
            <p>
              We may disclose your personal information with our service providers pursuant to a
              written contract between us and each service provider. Each service provider is a
              for-profit entity that processes the information on our behalf.
            </p>
            <p>
              We may use your personal information for our own business purposes, such as for
              undertaking internal research for technological development and demonstration. This is
              not considered to be the &quot;selling&quot; of your personal information.
            </p>
            <p>
              Internaut S.A.S has not sold any personal information to third parties for a business
              or commercial purpose in the preceding twelve (12) months. Internaut S.A.S has
              disclosed the following categories of personal information to third parties for a
              business or commercial purpose in the preceding twelve (12) months:
            </p>
            <p>
              The categories of third parties to whom we disclosed personal information for a
              business or commercial purpose can be found under &quot;WHEN AND WITH WHOM DO WE SHARE
              YOUR PERSONAL INFORMATION?&quot;.
            </p>
            <p>
              <b>Your rights with respect to your personal data</b>
            </p>
            <p className="s11">Right to request deletion of the data — Request to delete</p>
            <p>
              You can ask for the deletion of your personal information. If you ask us to delete
              your personal information, we will respect your request and delete your personal
              information, subject to certain exceptions provided by law, such as (but not limited
              to) the exercise by another consumer of his or her right to free speech, our
              compliance requirements resulting from a legal obligation, or any processing that may
              be required to protect against illegal activities.
            </p>
            <p className="s11">Right to be informed — Request to know</p>
            <p>Depending on the circumstances, you have a right to know:</p>
            <ul>
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>the purposes for which the collected personal information is used;</li>
              <li>whether we sell your personal information to third parties;</li>
              <li>
                the categories of personal information that we sold or disclosed for a business
                purpose;
              </li>
              <li>
                the categories of third parties to whom the personal information was sold or
                disclosed for a business purpose; and
              </li>
              <li>
                the business or commercial purpose for collecting or selling personal information.
              </li>
            </ul>
            <p>
              In accordance with applicable law, we are not obligated to provide or delete consumer
              information that is de-identified in response to a consumer request or to re-identify
              individual data to verify a consumer request.
            </p>
            <p className="s11">
              Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
              <span className="p">
                We will not discriminate against you if you exercise your privacy rights.
              </span>
            </p>
            <p className="s11">Verification process</p>
            <p>
              Upon receiving your request, we will need to verify your identity to determine if you
              are the same person with whom we have the information in our system. These
              verification efforts require us to ask you to provide information so that we can match
              it with the information you have previously provided us. For instance, depending on
              the type of request you submit, we may ask you to provide certain information so that
              we can match the information you provide with the information we already have on file,
              or we may contact you through a communication method (e.g., phone or email) that you
              have previously provided to us. We may also use other verification methods as the
              circumstances dictate.
            </p>
            <p>
              We will only use personal information provided in your request to verify your identity
              or authority to make the request. To the extent possible, we will avoid requesting
              additional information from you for the purposes of verification. However, if we
              cannot verify your identity from the information already maintained by us, we may
              request that you provide additional information for the purposes of verifying your
              identity and for security or fraud-prevention purposes. We will delete such
              additionally provided information as soon as we finish verifying you.
            </p>
            <p className="s11">Other privacy rights</p>

            <p>You may object to the processing of your personal information.</p>

            <p>
              You may request correction of your personal data if it is incorrect or no longer
              relevant, or ask to restrict the processing of the information. You can designate an
              authorized agent to make a request under the CCPA on your behalf. We may deny a
              request from an authorized agent that does not submit proof that they have been
              validly authorized to act on your behalf in accordance with the CCPA.
            </p>

            <p>
              You may request to opt-out from future selling of your personal information to third
              parties. Upon receiving an opt-out request, we will act upon the request as soon as
              feasibly possible, but no later than fifteen (15) days from the date of the request
              submission.
            </p>
            <p className="s3">
              To exercise these rights, you can contact us by email at{" "}
              <a href="mailto:contact@internaut.io" rel="noreferrer" target="_blank">
                contact@internaut.io
              </a>
              <span>
                , by calling toll-free at +57 312 525 66 55, or by referring to the contact details
                at the bottom of this document. If you have a complaint about how we handle your
                data, we would like to hear from you.
              </span>
            </p>
          </li>
          <li data-list-text="14.">
            <h2 id="bookmark14">DO WE MAKE UPDATES TO THIS NOTICE?</h2>
            <p>
              <b>In Short: </b> Yes, we will update this notice as necessary to stay compliant with
              relevant laws.
            </p>
            <p>
              We may update this privacy notice from time to time. The updated version will be
              indicated by an updated &quot;Revised&quot; date and the updated version will be
              effective as soon as it is accessible. If we make material changes to this privacy
              notice, we may notify you either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review this privacy notice
              frequently to be informed of how we are protecting your information.
            </p>
          </li>
          <li data-list-text="15.">
            <h2 id="bookmark15">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
            <p className="s3">
              If you have questions or comments about this notice, you may email us at{" "}
              <a href="mailto:contact@internaut.io" rel="noreferrer" target="_blank">
                contact@internaut.io
              </a>
              <span>or by post to: </span>
            </p>
            <p>Internaut S.A.S</p>
            <p>Cl. 18 #31-28, Bucaramanga, Santander, Colombia</p>
            <p>Bucaramanga, Santander 680002</p>
            <p>Colombia</p>
          </li>
          <li data-list-text="16.">
            <h2 id="bookmark16">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </h2>
            <p>
              Based on the applicable laws of your country, you may have the right to request access
              to the personal information we collect from you, change that information, or delete
              it. To request to review, update, or delete your personal information, please contact
              us.
            </p>
          </li>
        </ol>
      </NeonBox>
    </GalacticWallpaper>
  );
};
