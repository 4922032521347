import { CircularProgress } from "@mui/material";
import { NexusInput, PlatformButton } from "components";
import { ButtonType } from "enums";
import { useTranslation } from "react-i18next";
import { useController } from "./use-controller";
import { UserToSelect } from "./user-to-select";

import Styles from "./index.module.css";

interface Props {
  closeModal: () => void;
  title: string;
}

export const SelectUserFromOrganization = ({ closeModal, title }: Props) => {
  const { t } = useTranslation();
  const { filterUsers, isLoading } = useController();

  let content = <CircularProgress />;
  if (!isLoading) {
    content = (
      <>
        {filterUsers.map(user => (
          <UserToSelect key={user.id} user={user} />
        ))}
      </>
    );
  }

  return (
    <section>
      <h1 className={Styles.title}>
        {t(title)}
      </h1>
      <NexusInput name="userSearch" label={t("common.filter_by_name")} />
      <div className={`${Styles.container} neonScroll`}>{content}</div>
      <PlatformButton
        onClick={() => closeModal()}
        type={ButtonType.BUTTON}
        label={t("common.cancel")}
      />
    </section>
  );
};
