import { PlatformModuleHeader } from "components";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { Category } from "models";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { categoryService } from "services";
import { AddCategories, Table } from "./components";

export const CustomCategories = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadCategories = async () => {
    setIsLoading(true);
    const response = await categoryService.getCategories();
    setCategories(response.data);
    setIsLoading(false);
  };

  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.custom_categories")} />
        <AddCategories loadCategories={loadCategories} />
        <Table isLoading={isLoading} categories={categories} loadCategories={loadCategories} />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
