import { PlatformMenu } from "components";
import { Question } from "models";
import {
  BuildInitValuesType,
  BuildQuestionFormType,
  BuildValidationsType,
  OnSubmitType,
} from "../types";
import { EditQuestionAction } from "./edit-question-action";

interface Props {
  question: Question;
  loadQuestions: () => Promise<void>;
  buildInitValues: BuildInitValuesType;
  buildQuestionForm: BuildQuestionFormType;
  buildValidations: BuildValidationsType;
  onSubmit: OnSubmitType;
  suffix: string;
}

export const Actions = (props: Props) => {
  return (
    <PlatformMenu>
      <EditQuestionAction {...props} />
    </PlatformMenu>
  );
};
