import { GameButton } from "components";
import { GalacticColors } from "enums";
import { useUsernameFormatted } from "hooks";
import { useStoryUserIsTheSameUserInSession } from "hooks/story-user-is-the-same-user-in-session";
import { useTranslation } from "react-i18next";
import { thinkFastService } from "services/think-fast.service";
import { RootState, useShallowEqualSelector } from "store";

export const NextButton = () => {
  const { t } = useTranslation();
  const isStoryTimeUser = useStoryUserIsTheSameUserInSession();
  const { userForStoryTime, roomPin, userId } = useShallowEqualSelector((state: RootState) => ({
    userForStoryTime: state.room.gameState?.userForStoryTime,
    roomPin: state.room.pin,
    userId: state.user.id as number,
  }));

  const userName = useUsernameFormatted(userForStoryTime);
  let label = t("think_fast.i_already_said_the_word");
  let surrenderButton = (
    <GameButton
      backgroundImage={GalacticColors.RED}
      label={t("common.surrender")}
      onClick={() => thinkFastService.surrender(roomPin as string, userId)}
    />
  );

  if (!isStoryTimeUser) {
    label = `${t("common.waiting_for")} ${userName} ${t("common.to_continue")}`;
    surrenderButton = <></>;
  }

  return (
    <>
      {surrenderButton}
      <GameButton
        label={label}
        isDisabled={!isStoryTimeUser}
        onClick={() => thinkFastService.nextPlayer(roomPin as string)}
      />
    </>
  );
};
