import { Question } from "models";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { questionService } from "services";

export const useLoadQuestions = () => {
  const { gameId, categoryId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<Array<Question>>([]);

  const loadQuestions = async () => {
    try {
      setIsLoading(true);
      const response = await questionService.getQuestions(Number(gameId), categoryId as string);
      setQuestions(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    questions,
    loadQuestions,
  };
};
