import { PlatformBox } from "components";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";

interface Props {
  comments: Array<string>;
  questionId: number;
}
export const Comments = ({ comments, questionId }: Props) => {
  const { t } = useTranslation();
  if (!comments.length) return null;

  return (
    <div>
      <label className={Styles.title}>{t("feedback_360.comments")} #{questionId}</label>
      {comments.map(comment => (
        <PlatformBox key={comment} className={Styles.comment}>
          {comment}
        </PlatformBox>
      ))}
    </div>
  );
};
