import { languageResources } from "lang/languages";

const languages = Object.keys(languageResources);

const initValuesTemplate: Record<string, string | number | boolean> = {
  autotranslation: 1,
};

languages.forEach(language => {
  initValuesTemplate[`name_${language}`] = "";
});

export { initValuesTemplate };
