import { PlatformButton, PlatformModal } from "components";
import GameTitle from "components/game-title/game-title";
import { Feedback, Feedback360Question } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { feedback360Service } from "services";
import { FeedbackResultsViewer } from "./feedback-results-viewer";
import Styles from "./index.module.css";

interface Props {
  currentFeedbackId?: string;
  setCurrentFeedback: (feedback: Feedback | undefined) => void;
}

export const FeedbackResultsViewerWrapper = ({ currentFeedbackId, setCurrentFeedback }: Props) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<Array<Feedback360Question>>();
  const [feedback, setFeedback] = useState<Feedback>();
  useEffect(() => {
    (async () => {
      const response = await feedback360Service.loadQuestions();
      setQuestions(response.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!currentFeedbackId) return;
      const response = await feedback360Service.getFeedbackById(currentFeedbackId);
      setFeedback(response.data);
    })();
  }, [currentFeedbackId]);

  if (!questions?.length) {
    return null;
  }

  return (
    <PlatformModal isOpen={!!feedback && !!currentFeedbackId}>
      <div className={Styles.container}>
        <GameTitle gameName={t("feedback_360.feedback_results")} />
        <FeedbackResultsViewer feedback={feedback as Feedback} questions={questions} />
        <PlatformButton
          onClick={() => {
            setCurrentFeedback(undefined);
          }}
          label={t("common.close")}
        />
      </div>
    </PlatformModal>
  );
};
