import { CircularProgress } from "@mui/material";
import { NexusSelect } from "components";
import { CreditCard } from "models";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { userService } from "services";

export const PaymentMethods = () => {
  const { t } = useTranslation();
  const [creditCards, setCreditCards] = useState<Array<CreditCard>>();
  useEffect(() => {
    (async () => {
      const response = await userService.findCreditCards();
      setCreditCards(response.data);
    })();
  }, []);
  if (!creditCards) return <CircularProgress />;

  const options = creditCards.map(creditCard => ({
    label: `${creditCard.franchise} - ${creditCard.maskedNumber}`,
    value: creditCard.id,
  }));
  options.unshift({
    value: 0,
    label: t("user_plan_management.payment_by_payu"),
  });
  return (
    <NexusSelect items={options} label={`${t("billing.payment_method")}*`} name="paymentMethodId" />
  );
};
