import { useCurrentLanguage } from "hooks";
import { find, head } from "lodash";
import { LearningPost, LinkLanguage } from "models";
import Styles from "./index.module.css";
interface Props {
  post: LearningPost;
  setLearningPost: React.Dispatch<React.SetStateAction<LearningPost | null>>;
}

const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

export const Post = ({ post, setLearningPost }: Props) => {
  const { imageUrl, linkLanguages } = post;
  const currentLanguage = useCurrentLanguage();

  const { link, label } = (find(
    linkLanguages,
    linkLanguage => linkLanguage.languageId === currentLanguage,
  ) || head(linkLanguages)) as LinkLanguage;

  const isYoutubeLink = youtubeUrlRegex.test(link);

  const handleClick = () => {
    if (isYoutubeLink) {
      setLearningPost(post);
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <div onClick={handleClick} className={Styles.card}>
      <div className={`${Styles.wallpaper}`} style={{ backgroundImage: `url(${imageUrl})` }}></div>
      <div className={Styles.content}>
        <label className="subtitle">{label}</label>
      </div>
    </div>
  );
};
