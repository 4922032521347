import {ChangeLanguageButton} from "components";
import { GoToFeedButtonWrapper } from "hoc";
import { ReactNode } from "react";
import Styles from "./index.module.css";

interface Props {
  children: ReactNode;
}
export const PlatformLayout = (props: Props) => {
  return (
    <GoToFeedButtonWrapper>
      <div className={Styles.container}>
        {props.children}
        <ChangeLanguageButton />
      </div>
    </GoToFeedButtonWrapper>
  );
};
