import { Feeling } from "enums";
import { useTranslation } from "react-i18next";
import { feelingService } from "services";
import { feelings } from "./constants";
import Styles from "./index.module.css";

interface Props {
  afterSubmit: () => void;
  isHorizontal?: boolean;
}

export const FeelingsThermometer = ({ afterSubmit, isHorizontal }: Props) => {
  const { t } = useTranslation();

  const onClick = (feeling: { value: Feeling }) => async () => {
    await feelingService.addFeeling(feeling.value);
    const today = new Date().toISOString().split("T").shift() as string;
    localStorage.setItem("feelings_day", today);
    if (afterSubmit) {
      afterSubmit();
    }
  };

  const iconContainerClasses = [Styles.iconContainer];
  const iconsContainerClasses = [Styles.iconsContainer];
  const iconClasses = [Styles.icon];

  if (isHorizontal) {
    iconContainerClasses.push(Styles.iconContainerHorizontal);
    iconsContainerClasses.push(Styles.iconsContainerHorizontal);
    iconClasses.push(Styles.iconHorizontal);
  }

  return (
    <div>
      <label className="neon">{t("feelings.tell_us_how_do_you_feel_today")}</label>
      <div className={iconsContainerClasses.join(" ")}>
        {feelings.map(feeling => (
          <div
            className={iconContainerClasses.join(" ")}
            key={feeling.value}
            onClick={onClick(feeling)}
          >
            <img className={iconClasses.join(" ")} src={feeling.icon} alt={t(feeling.label)} />
            <label className={Styles.label}>{t(feeling.label)}</label>
          </div>
        ))}
      </div>
    </div>
  );
};
