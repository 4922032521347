import { AnonymousFeedback } from "models";
import { useState, useEffect } from "react";
import { anonymousFeedbackService } from "services";

export const useController = () => {
  const [feedbacks, setFeedbacks] = useState<Array<AnonymousFeedback>>([]);
  const [lastFeedback, setLastFeedback] = useState("");
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await anonymousFeedbackService.getCount();
      setCount(response.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await anonymousFeedbackService.getCategoriesByGameId(6, lastFeedback);
      setFeedbacks(feedbacks.concat(response.data));
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFeedback]);

  return {
    count,
    isLoading,
    feedbacks,
    setLastFeedback,
  };
};
