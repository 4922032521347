import * as yup from "yup";
import { Options } from "../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { NexusSelect } from "components";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FeelingPerMonth } from "../feeling-per-month";
import Styles from "./index.module.css";
import { ProtonsInPeriod } from "../protons-in-period";

export const AnalyticsMetric = () => {
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    metric: yup.string(),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      metric: "",
    },
  });

  const values = methods.watch();

  let metricComponent = null;

  switch (values.metric) {
    case "feeling-per-month":
      metricComponent = <FeelingPerMonth />;
      break;
    case "protons-in-period":
      metricComponent = <ProtonsInPeriod />;
      break;
  }

  return (
    <>
      <FormProvider {...methods}>
        <form className={Styles.container}>
          <NexusSelect
            label={`${t("analytics.metric")}`}
            name="metric"
            variant="outlined"
            items={Options.map(option => ({ ...option, label: t(option.label) }))}
          />
        </form>
      </FormProvider>
      {metricComponent}
    </>
  );
};
