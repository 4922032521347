import { PlatformModuleHeader } from "components";
import { Plan } from "enums";
import { GalacticWallpaper, GoToFeedButtonWrapper, NotActivePlan, PlatformLayout } from "hoc";
import { useTranslation } from "react-i18next";
import { RootState, useShallowEqualSelector } from "store";
import { Consumption } from "./consumption";
import { Summary } from "./summary";

export const PayAsYouGoPlan = () => {
  const { t } = useTranslation();
  const { planId } = useShallowEqualSelector((state: RootState) => ({
    planId: state?.user?.plan?.id,
  }));
  const hasPayAsYouGoPlan = planId === Plan.PAY_AS_YOU_GO;
  if (!hasPayAsYouGoPlan) {
    return (
      <GoToFeedButtonWrapper>
        <NotActivePlan />
      </GoToFeedButtonWrapper>
    );
  }
  return (
    <GalacticWallpaper>
      <PlatformLayout>
        <PlatformModuleHeader label={t("menu.pay_as_you_go")} />
        <Summary />
        <Consumption />
      </PlatformLayout>
    </GalacticWallpaper>
  );
};
