import { Box, ClickAwayListener, Modal } from "@mui/material";
import ShareIcon from "assets/icons/platform/share.svg";
import { GameButton } from "components";
import { GalacticColors } from "enums/galactic-backgrounds";
import { useUserIsHost } from "hooks/use-user-is-host";
import { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { modalStyle } from "styles";
import { shareLink } from "utils";
import Styles from "./index.module.css";

interface Props {
  children?: React.ReactNode;
}

const copyLink =
  (t: TFunction<"translation", undefined>, setOpen: (open: boolean) => void) => async () => {
    const url = window.location.href;
    shareLink(t, url);
    setOpen(false);
  };

export const ShareButtonWrapper = (props: Props) => {
  const lastRoomPinShared = localStorage.getItem("lastRoomPinShared");
  const { t } = useTranslation();
  const isHost = useUserIsHost();
  const roomPin = useSelector((state: RootState) => state.room.pin);
  const shouldShowShareModal = isHost && roomPin !== lastRoomPinShared;
  const [open, setOpen] = useState(shouldShowShareModal);

  localStorage.setItem("lastRoomPinShared", roomPin as string);

  return (
    <>
      {props.children}
      <div data-testid="share-button-container" className={Styles.buttonContainer}>
        <GameButton
          dataTestId="share-button"
          onClick={copyLink(t, setOpen)}
          backgroundImage={GalacticColors.BLUE}
          isRound={true}
          imageSrc={ShareIcon}
          tooltip={t("common.share_link")}
        />
      </div>

      <Modal open={open}>
        <Box sx={modalStyle} className={Styles.container}>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div data-testid="share-modal-container">
              <h2 className="neon">{t("common.invite_your_teammates_to_the_party")}</h2>
              <GameButton
                dataTestId="share-modal-button"
                onClick={copyLink(t, setOpen)}
                backgroundImage={GalacticColors.BLUE}
                label={t("common.share_link")}
              />
            </div>
          </ClickAwayListener>
        </Box>
      </Modal>
    </>
  );
};
