import { BrowserValidator, OnlyAdminWrapper, TokenExtractor } from "hoc";
import {
  AdminEvent,
  Analytics,
  BillsView,
  CustomCategories,
  CustomQuestions,
  Event,
  Feedback360,
  LearningCenter,
  PayAsYouGoPlan,
  PaymentMethods,
  ProtonCenter,
  SlotsManagement,
  UserPlanManagement,
} from "pages/platform";
import { AnonymousFeedback } from "pages/platform/anonymous-feedback";
import { Events } from "pages/platform/events";
import RoomPage from "pages/platform/room/room.page";
import RoomsPage from "pages/platform/rooms/rooms.page";
import UserAccount from "pages/platform/user-account/user-account";
import { Route, Routes } from "react-router-dom";
import { Routing } from "routing";

const Platform = () => (
  <TokenExtractor>
    <BrowserValidator>
      <Routes>
        <Route path={Routing.platform.rooms} element={<RoomsPage />} />
        <Route path={`${Routing.platform.room}/:pin`} element={<RoomPage />} />
        <Route path={Routing.platform.account} element={<UserAccount />} />
        <Route path={Routing.platform.protonCenter} element={<ProtonCenter />} />
        <Route path={Routing.platform.learningCenter} element={<LearningCenter />} />
        <Route
          path={Routing.platform.userPlanManagement}
          element={
            <OnlyAdminWrapper>
              <UserPlanManagement />
            </OnlyAdminWrapper>
          }
        />
        <Route path={Routing.platform.payAsYouGoPlan} element={<PayAsYouGoPlan />} />
        <Route path={Routing.platform.feedback360} element={<Feedback360 />} />
        <Route path={Routing.platform.paymentMethods} element={<PaymentMethods />} />
        <Route path={Routing.platform.slotsManagement} element={<SlotsManagement />} />
        <Route path={Routing.platform.bills} element={<BillsView />} />
        <Route path={Routing.platform.customCategories} element={<CustomCategories />} />
        <Route
          path={`${Routing.platform.customQuestions}/:gameId/:categoryId`}
          element={<CustomQuestions />}
        />
        <Route path={Routing.platform.anonymousFeedback} element={<AnonymousFeedback />} />
        <Route path={`${Routing.platform.adminEvent}/:eventId`} element={<AdminEvent />} />
        <Route path="event/:pin" element={<Event />} />
        <Route path="events" element={<Events />} />
        <Route path="analytics" element={<Analytics />} />
      </Routes>
    </BrowserValidator>
  </TokenExtractor>
);

export default Platform;
