import {
  Button,
  CircularProgress, 
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { NexusInput, NexusSelect, PlatformModal } from "components";
import { ButtonType } from "enums";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Action, ActionType } from "../../reducer";
import { useController } from "./use-controller";

export interface Props {
  open: boolean;
  dispatch: React.Dispatch<Action<ActionType>>;
}

export const CreateRoomForm = ({ open, dispatch }: Props) => {
  const { t } = useTranslation();
  const { isLoading, methods, roomTypes, onSubmit } = useController({ open, dispatch });

  let bottomSection = <CircularProgress />;
  if (!isLoading) {
    bottomSection = (
      <>
        <Button onClick={() => dispatch({ type: ActionType.CLEAR_DIALOG })}>
          {t("common.no")}
        </Button>

        <Button type={ButtonType.SUBMIT} autoFocus>
          {t("common.yes")}
        </Button>
      </>
    );
  }

  return (
    <PlatformModal isOpen={open}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          id="create-room-form"
          data-testid="create-room-form"
        >
          <DialogTitle color="white">{t("rooms.create_a_new_room")}</DialogTitle>
          <DialogContent>
            <NexusInput label={`${t("common.name")} *`} name="name" variant="standard" />
            <NexusSelect
              label={`${t("common.room_type")} *`}
              name="type"
              variant="standard"
              items={roomTypes}
            />
            <NexusInput
              label={`${t("rooms.link_after_room")}`}
              name="linkAfterRoom"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>{bottomSection}</DialogActions>
        </form>
      </FormProvider>
    </PlatformModal>
  );
};
