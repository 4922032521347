import { YoutubePlayer } from "components";
import { GameInstructions } from "components/game-instructions";
import { useTranslation } from "react-i18next";

const Instructions = () => {
  const { t } = useTranslation();
  return (
    <GameInstructions slider="stars" maxFails={10} gameName={t("games.never_have_i_ever")}>
      <YoutubePlayer label="instructions" link="https://youtube.com/embed/nSRaaMce4To" />
    </GameInstructions>
  );
};

export default Instructions;
