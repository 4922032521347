import { Plan } from "enums";
import { Routing } from "routing";
import { RootState, useShallowEqualSelector } from "store";
import Styles from "./index.module.css";
import { Option } from "./option";

const ALL_PLANS = [Plan.ENTERPRISE, Plan.PAY_AS_YOU_GO, Plan.STANDARD, Plan.SLOTS];

const MONTHLY_PLANS = [Plan.ENTERPRISE, Plan.STANDARD];

const GENERAL_SECTION = () => [
  {
    label: "menu.profile",
    link: `/${Routing?.platformModule}/account`,
  },
  {
    label: "menu.custom_categories",
    link: `/${Routing?.platformModule}/${Routing.platform.customCategories}`,
    plans: ALL_PLANS,
  },
  {
    label: "menu.anonymous_feedback",
    link: `/${Routing?.platformModule}/${Routing.platform.anonymousFeedback}`,
    plans: ALL_PLANS,
  },
  {
    label: "menu.protons_center",
    link: `/${Routing?.platformModule}/${Routing.platform.protonCenter}`,
    plans: ALL_PLANS,
  },
  {
    label: "menu.feedback_360",
    link: `/${Routing?.platformModule}/${Routing.platform.feedback360}`,
    plans: ALL_PLANS,
  },
  {
    label: "menu.pay_as_you_go",
    link: `/${Routing.platformModule}/${Routing.platform.slotsManagement}`,
    plans: [Plan.SLOTS],
  },
  {
    label: "menu.bills",
    link: `/${Routing.platformModule}/bills`,
  },
];

const ADMIN_SECTION = () => [
  {
    label: "menu.analytics",
    link: `/${Routing?.platformModule}/${Routing.platform.analytics}`,
    plans: ALL_PLANS,
  },
  {
    label: "menu.users",
    link: `/${Routing.platformModule}/${Routing.platform.userPlanManagement}`,
    plans: MONTHLY_PLANS,
  },
  {
    label: "menu.pay_as_you_go",
    link: `/${Routing.platformModule}/${Routing.platform.payAsYouGoPlan}`,
    plans: [Plan.PAY_AS_YOU_GO],
  },
  {
    label: "menu.payment_methods",
    link: `/${Routing.platformModule}/${Routing.platform.paymentMethods}`,
    plans: ALL_PLANS,
  },
];

const LOGOUT_SECTION = () => [
  {
    label: "menu.logout",
    link: `/${Routing.login}`,
  },
];

export const Options = () => {
  const { overview } = useShallowEqualSelector((state: RootState) => ({
    overview: state.user.overview,
  }));

  const sections = [GENERAL_SECTION()];

  if (overview?.isHost) {
    sections[0].push({
      label: "menu.events",
      link: `/${Routing?.platformModule}/${Routing.platform.events}`,
      plans: ALL_PLANS,
    });
  }

  if (overview?.isAdmin) {
    sections.push(ADMIN_SECTION());
  }

  sections.push(LOGOUT_SECTION());
  console.log(sections);
  return (
    <>
      {sections.map((section, index) => (
        <div key={index} className={Styles.section}>
          {section.map(option => (
            <Option key={option.label} {...option} />
          ))}
        </div>
      ))}
    </>
  );
};
