import { GameButton, PlatformButton, PlatformModal } from "components";
import Styles from "./index.module.css";
import RequestAdminImg from "assets/icons/platform/request-admin.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useUserIsHost } from "hooks";

const requestHost = () => {
  const url = `${window.location.href.replaceAll("?host=true", "")}?host=true`;
  window.location.href = url;
};

export const RequestAdmin = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isHost = useUserIsHost();

  if (isHost) {
    return null;
  }

  return (
    <>
      <div className={Styles.container}>
        <GameButton
          imageSrc={RequestAdminImg}
          tooltip={`${t("room.request_host")}`}
          onClick={() => setIsOpen(true)}
        />
      </div>
      <PlatformModal isOpen={isOpen}>
        <label className="modalTitle">{t("room.are_you_sure_you_want_to_host_the_room")}</label>
        <div className={Styles.buttonContainer}>
          <PlatformButton label={t("common.cancel")} onClick={() => setIsOpen(false)} />
          <PlatformButton label={t("room.request_host")} onClick={requestHost} />
        </div>
      </PlatformModal>
    </>
  );
};
