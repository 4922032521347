import { CircularProgress } from "@mui/material";
import NeonBox from "components/neon-box/neon-box";
import { LocalStorage } from "enums";
import { GalacticWallpaper, PlatformLayout } from "hoc";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { eventService } from "services";
import { goToRoom } from "utils";


export const Event = () => {
  const { pin } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      if (!pin) return;
      try {
        localStorage.setItem(LocalStorage.eventPin, pin);
        const event = await eventService.getEventRoom(pin);
        const roomId = event.data.pin;
        goToRoom(roomId)();
      } catch (e) {
        const status = get(e, "response.data.statusCode");
        const message = t(get(e, "response.data.message"));
        setErrorMessage(message);
        if (status !== 403) {
          localStorage.removeItem("eventPin");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  let content = <CircularProgress />;
  if (errorMessage) {
    content = (
      <NeonBox>
        <h2 className="neon">{errorMessage}</h2>
      </NeonBox>
    );
  }

  return (
    <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
      <PlatformLayout>{content}</PlatformLayout>
    </GalacticWallpaper>
  );
};
