import { Button, DialogActions, DialogTitle } from "@mui/material";
import { Action } from "@reduxjs/toolkit";
import { PlatformModal } from "components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { roomService } from "services";
import { goToRoom } from "utils";
import { ActionType } from "../../reducer";

interface Props {
  open: boolean;
  dispatch: React.Dispatch<Action<ActionType>>;
}

export const DeleteRoom = ({ open, dispatch }: Props) => {
  const { t } = useTranslation();
  const [roomId, setRoomId] = useState("");

  const recreateRoom = async () => {
    await roomService.deleteRoom();
    dispatch({ type: ActionType.OPEN_CREATE_ROOM_DIALOG });
  };

  const cancel = () => dispatch({ type: ActionType.CLEAR_DIALOG });

  useEffect(() => {
    (async () => {
      const room = await roomService.getRoom();
      setRoomId(room?.data?.pin);
    })();
  }, []);

  return (
    <PlatformModal isOpen={open}>
      <DialogTitle color="white">{t("errors.user_has_an_active_room")}</DialogTitle>
      <DialogActions>
        <Button onClick={goToRoom(roomId)}>{t("room.go_room")}</Button>
        <Button onClick={cancel}>{t("common.no")}</Button>
        <Button onClick={recreateRoom} autoFocus>
          {t("common.yes")}
        </Button>
      </DialogActions>
    </PlatformModal>
  );
};
