import { keys } from "lodash";
import { RootState, useShallowEqualSelector } from "store";
import { formatUsername } from "utils";

export const usePendingUsersToVote = (): Array<string> => {
  const { usersDetailsWithoutSupport, votes } = useShallowEqualSelector((state: RootState) => ({
    usersDetailsWithoutSupport: state.room.usersDetailsWithoutSupport,
    votes: state.room.gameState?.votes as Record<number, number>,
  }));

  const userIds = keys(usersDetailsWithoutSupport);
  const userWithVote = keys(votes);
  const pendingUsers = [];
  for (const userId of userIds) {
    const hasVoted = userWithVote.includes(String(userId));
    const userName = usersDetailsWithoutSupport[userId].name;
    if (!hasVoted && userName) {
      pendingUsers.push(formatUsername(userName));
    }
  }
  return pendingUsers;
};
