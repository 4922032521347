import VideoPlayers from "components/video-players/video-players";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { RootState, useShallowEqualSelector } from "store";
import { waitingVoteMessage } from "utils";
import { usePendingUsersToVote, useUserIsSupport } from "../../hooks";
import { Question } from "../components/question";
import { GameOptions } from "./game-options";
import { SkipPlayerButton } from "components";

export const ShowQuestions = () => {
  const { t } = useTranslation();
  const { votes, userId } = useShallowEqualSelector((state: RootState) => ({
    votes: state.room.gameState?.votes as Record<number, number>,
    userId: state.user.id,
  }));

  const pendingUsersToVote = usePendingUsersToVote();
  const isSupport = useUserIsSupport();

  let bottomSection = <GameOptions />;

  const userAnswer = get(votes, userId as number);

  if (userAnswer || isSupport) {
    bottomSection = <h2 className="neon">{waitingVoteMessage(t, pendingUsersToVote)}</h2>;
  }

  return (
    <>
      <SkipPlayerButton />
      <Question />
      <VideoPlayers />
      <div>{bottomSection}</div>
    </>
  );
};
