import { MenuItem } from "@mui/material";
import { get } from "lodash";
import { UserLicenseAssignment } from "models";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { planService } from "services";

interface Props {
  assignment: UserLicenseAssignment;
  loadUsers?: () => void;
  handleClose: () => void;
}

export const ChangeAdminAction = ({ assignment, loadUsers, handleClose }: Props) => {
  const { t } = useTranslation();
  const message = assignment.isAdmin
    ? t("user_plan_management.remove_from_admins")
    : t("user_plan_management.set_as_admin");
  const changeAssignmentStatus = async () => {
    try {
      await planService.updateIsAdminUserLicenseAssignment(
        assignment.toUser.id,
        !assignment.isAdmin,
      );
      if (loadUsers) {
        await loadUsers();
      }
      handleClose();
      return;
    } catch (e) {
      const errorMessage = get(e, "response.data.message");
      toast.error(t(errorMessage));
    }
  };

  return (
    <>
      <MenuItem onClick={changeAssignmentStatus}>{message}</MenuItem>
    </>
  );
};
