import { get } from "lodash";

const mapping = {
  es: "es-es",
  en: "en-us",
  pt: "pt-bz",
};

export const formatOnlyDate = (dateString = "", omitTimestamp = false) => {
  const language = localStorage.getItem("i18nextLng") || "en";
  const distribution = get(mapping, language) || "en-us";
  const hasTimestamp = dateString.split("T").length === 2;
  try {
    const date = new Date(dateString);
    const dayFormatted = date.toLocaleString(distribution, {
      month: "long",
      day: "2-digit",
      year: "numeric",
    });
    if (!hasTimestamp || omitTimestamp) {
      return dayFormatted;
    }
    return `${dayFormatted}, ${date.toLocaleTimeString()}`;
  } catch (e) {
    return dateString;
  }
};
