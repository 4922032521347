import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Divider } from "@mui/material";
import { PlatformButton, TranslateLanguagesHelper } from "components";
import { useCurrentLanguage } from "hooks";
import { Question } from "models";
import { useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { onError } from "../../utils/on-error";
import { ModalBody } from "../modal-body";
import {
  BuildInitValuesType,
  BuildQuestionFormType,
  BuildValidationsType,
  OnSubmitType,
} from "../types";
import Styles from "./index.module.css";

interface Props {
  afterCompleteForm: () => Promise<void>;
  closeModal: () => void;
  buildInitValues: BuildInitValuesType;
  buildValidations: BuildValidationsType;
  onSubmit: OnSubmitType;
  suffix: string;
  question?: Question;
  buildQuestionForm: BuildQuestionFormType;
}

export const QuestionModal = ({
  afterCompleteForm,
  closeModal,
  question,
  buildInitValues,
  buildValidations,
  onSubmit,
  suffix,
  buildQuestionForm,
}: Props) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const { gameId, categoryId } = useParams();

  const requiredMessage = t("common.is_required");

  const modulePrefix = question?._id ? "edit" : "add";

  const initialValues = buildInitValues(t, question);

  const methods = useForm({
    resolver: yupResolver(buildValidations(t, requiredMessage, currentLanguage)),
    defaultValues: initialValues,
  });

  let bottomBar = <CircularProgress />;

  if (!isLoading) {
    bottomBar = (
      <>
        <PlatformButton onClick={closeModal} label={t("common.cancel")} />
        <PlatformButton label={t(`custom_questions.${modulePrefix}_${suffix}`)} />
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(
          onSubmit(
            currentLanguage,
            setIsLoading,
            afterCompleteForm,
            categoryId as string,
            Number(gameId),
            question?._id,
          ),
          onError(t),
        )}
        className={Styles.formContainer}
        id={`${modulePrefix}-${suffix}-form`}
        data-testid={`${modulePrefix}-${suffix}-form`}
      >
        <label className="modalTitle">{t(`custom_questions.${modulePrefix}_${suffix}`)}</label>
        <TranslateLanguagesHelper />
        <Divider />
        <ModalBody
          buildQuestionForm={buildQuestionForm}
          currentLanguage={currentLanguage}
          methods={methods as UseFormReturn<Record<string, string | number | boolean>, object>}
        />
        <div className={Styles.buttonContainer}>{bottomBar}</div>
      </form>
    </FormProvider>
  );
};
