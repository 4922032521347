import { CircularProgress } from "@mui/material";
import { GameButton } from "components";
import { get, last } from "lodash";
import { AnonymousFeedback } from "models";
import { useTranslation } from "react-i18next";

interface Props {
  count: number;
  feedbacks: Array<AnonymousFeedback>;
  isLoading: boolean;
  setLastFeedback: (lastFeedBack: string) => void;
}

export const BottomSection = ({ count, feedbacks, isLoading, setLastFeedback }: Props) => {
  const { t } = useTranslation();
  if (count === feedbacks.length) {
    return <></>;
  }

  if (isLoading) {
    return (
      <section className="center">
        <CircularProgress />
      </section>
    );
  }

  const lastFeedback = get(last(feedbacks), "_id", "");
  const onClick = () => setLastFeedback(lastFeedback);

  return (
    <section className="center">
      <GameButton
        onClick={onClick}
        label={t("common.load_more")}
      />
    </section>
  );
};
