export const pricingPlans = [
  {
    value: 0,
    label: "",
  },
  {
    value: 25,
    label: "",
  },
  {
    value: 50,
    label: "",
  },
  {
    value: 100,
    label: "",
  },
  {
    value: 200,
    label: "",
  },
];