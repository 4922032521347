import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { NexusInput, PlatformButton } from "components";
import { sanitizeLanguage } from "lang/i18";
import { get } from "lodash";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { userService } from "services";
import * as yup from "yup";
import { CardNumberInput } from "./card-number-input";
import { CCVInput } from "./ccv-input";
import { CountryInput } from "./country-input";
import { CreateCreditCard } from "./create-credit-card";
import { FranchiseInput } from "./franchise-input";
import Styles from "./index.module.css";
import { MonthInput } from "./month-input";
import { YearInput } from "./year-input";

import PayULogo from "assets/icons/platform/payu.webp";

interface Props {
  loadCreditCards: () => Promise<void>;
}

export const AddCreditCard = ({ loadCreditCards }: Props) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const requiredMessage = t("common.is_required");

  const validationSchema = yup.object().shape({
    name: yup.string().required(`${t("event.max_users_per_room")} ${requiredMessage}`),
    identificationNumber: yup
      .number()
      .typeError(`${t("payment_methods.identificationNumber")} ${requiredMessage}`)
      .required(`${t("payment_methods.identificationNumber")} ${requiredMessage}`),
    franchise: yup.string().required(`${t("payment_methods.franchise")} ${requiredMessage}`),
    number: yup
      .string()
      .typeError(`${t("payment_methods.number")} ${requiredMessage}`)
      .transform(value => {
        const valueFormatted = String(value).replaceAll(/-/g, "").replaceAll(/_/g, "");
        return String(valueFormatted);
      })
      .required(`${t("payment_methods.number")} ${requiredMessage}`),
    expirationMonth: yup
      .string()
      .required(`${t("payment_methods.expirationMonth")} ${requiredMessage}`),
    expirationYear: yup
      .string()
      .required(`${t("payment_methods.expirationYear")} ${requiredMessage}`),
    ccv: yup.string().typeError(`CCV ${requiredMessage}`).required(`CCV ${requiredMessage}`),
    shippingAddress: yup
      .string()
      .required(`${t("payment_methods.shippingAddress")} ${requiredMessage}`),
    shippingCity: yup.string().required(`${t("payment_methods.shippingCity")} ${requiredMessage}`),
    shippingState: yup
      .string()
      .required(`${t("payment_methods.shippingState")} ${requiredMessage}`),
    shippingCountry: yup
      .string()
      .typeError(`${t("payment_methods.shippingCountry")} ${requiredMessage}`)
      .required(`${t("payment_methods.shippingCountry")} ${requiredMessage}`),
    postalCode: yup.string().required(`${t("payment_methods.postalCode")} ${requiredMessage}`),
    phone: yup.string().required(`${t("payment_methods.phone")} ${requiredMessage}`),
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      identificationNumber: "",
      franchise: "",
      number: "",
      expirationMonth: "",
      expirationYear: "",
      ccv: "",
      shippingAddress: "",
      shippingCity: "",
      shippingState: "",
      shippingCountry: "CO",
      postalCode: "",
      phone: "",
    },
  });

  const onSubmit = async (values: unknown) => {
    if (isLoading) return;
    const {
      ccv,
      expirationMonth,
      expirationYear,
      franchise,
      identificationNumber,
      name,
      phone,
      postalCode,
      shippingAddress,
      shippingCity,
      shippingCountry,
      shippingState,
      ...otherValues
    } = values as CreateCreditCard;
    try {
      setIsLoading(true);
      await userService.createCreditCard(
        name,
        identificationNumber,
        otherValues.number,
        franchise,
        expirationMonth,
        expirationYear,
        ccv,
        sanitizeLanguage(i18n.language),
        shippingAddress,
        shippingCity,
        shippingState,
        shippingCountry,
        postalCode,
        phone,
      );
      methods.reset();
      setIsLoading(false);
      setIsOpen(false);
      await loadCreditCards();
    } catch (e) {
      const message = get(e, "response.data.message");
      toast.error(t(message));
    } finally {
      setIsLoading(false);
    }
  };

  let options = (
    <>
      <Button
        onClick={() => {
          methods.reset();
          setIsOpen(false);
        }}
      >
        {t("common.cancel")}
      </Button>
      <Button disabled={isLoading} type="submit">
        {t("payment_methods.add_credit_card")}
      </Button>
    </>
  );

  if (isLoading) {
    options = <CircularProgress />;
  }

  return (
    <>
      <div className={Styles.container}>
        <PlatformButton
          onClick={() => setIsOpen(true)}
          label={t("payment_methods.add_credit_card")}
        />
      </div>
      <Dialog className={Styles.dialog} open={isOpen}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            id="add-credit-card-form"
            data-testid="add-credit-card-form"
          >
            <DialogTitle>{t("payment_methods.add_credit_card")}</DialogTitle>
            <DialogContent>
              <div className={Styles.formContainer}>
                <NexusInput label={`${t("common.name")}*`} name="name" />
                <NexusInput
                  label={`${t("payment_methods.identificationNumber")}*`}
                  type="number"
                  name="identificationNumber"
                />
                <NexusInput
                  label={`${t("payment_methods.shippingAddress")}*`}
                  name="shippingAddress"
                />
                <NexusInput label={`${t("payment_methods.shippingCity")}*`} name="shippingCity" />
                <NexusInput label={`${t("payment_methods.shippingState")}*`} name="shippingState" />
                <CountryInput />
                <NexusInput label={`${t("payment_methods.postalCode")}*`} name="postalCode" />
                <NexusInput label={`${t("payment_methods.phone")}*`} name="phone" />
                <CardNumberInput />
                <FranchiseInput />
                <MonthInput />
                <YearInput />
                <CCVInput />
                <div>
                  <img width={120} src={PayULogo} alt="payu logo" />
                </div>
              </div>
            </DialogContent>
            <DialogActions>{options}</DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
