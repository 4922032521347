import AstronautIcon from "assets/icons/platform/astronaut.svg";
import { GameButton } from "components";
import { GalacticColors } from "enums/galactic-backgrounds";
import { useState } from "react";
import Styles from "./index.module.css";
import { userButtonStyle } from "./index.style";
import { Menu } from "./menu";

interface Props {
  children?: React.ReactNode;
}

export const UserConfigurationButtonWrapper = (props: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  
  let menu;
  if (showMenu) {
    menu = <Menu onClickOutside={() => setShowMenu(false)}/>;
  }

  return (
    <>
      <div className={Styles.container}>
        <GameButton
          dataTestId="go-to-account-button"
          style={userButtonStyle}
          isRound={true}
          backgroundImage={GalacticColors.BLUE}
          imageSrc={AstronautIcon}
          onClick={() => setShowMenu(!showMenu)}
        />
        {menu}
      </div>
      {props.children}
    </>
  );
};
