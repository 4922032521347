import { GameButton } from "components";
import NeonBox from "components/neon-box/neon-box";
import { GalacticColors } from "enums/galactic-backgrounds";
import { GalacticWallpaper } from "hoc";
import { useTranslation } from "react-i18next";
import { RootState, useShallowEqualSelector } from "store";

export const NotActivePlan = () => {
  const { t } = useTranslation();
  const { userEmail } = useShallowEqualSelector((state: RootState) => ({
    userEmail: state.user.email as string,
  }));
  return (
    <GalacticWallpaper isContentHorizontallyCentered={true} isContentVerticallyCentered={true}>
      <NeonBox className="center">
        <h1 className="neon">
          {t("room.ask_to_your_manager")} 
          <label className="neon-alternative"> {userEmail}</label>
          {t("room.ask_to_your_manager_suffix")} 
        </h1>
        <GameButton
          onClick={() => window.location.reload()}
          backgroundImage={GalacticColors.BLUE}
          label={t("room.try_again")}
        />
        <GameButton
          onClick={() => window.location.replace('/login')}
          backgroundImage={GalacticColors.BLUE}
          label={t("menu.logout")}
        />
      </NeonBox>
    </GalacticWallpaper>
  );
};
