import { Action } from "@reduxjs/toolkit";
import { Reducer, useReducer } from "react";
import { ButtonsContainer, CreateRoomForm, DeleteRoom } from "./components";
import { ActionType, State, initialState, reducer } from "./reducer";

export const CreateRoom = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action<ActionType>>>(reducer, initialState);
  const { isLoading, openRoomErrorDialog, openCreateRoomDialog } = state;

  return (
    <>
      <ButtonsContainer dispatch={dispatch} isLoading={isLoading} />
      <CreateRoomForm open={openCreateRoomDialog} dispatch={dispatch} />
      <DeleteRoom open={openRoomErrorDialog} dispatch={dispatch} />
    </>
  );
};
