import { MD5 } from "crypto-js";

export const generateSignature = (
  apiKey: string,
  merchantId: string,
  referenceCode: string,
  value: number,
  currency: string,
): string => {
  const preSignature = `${apiKey}~${merchantId}~${referenceCode}~${value}~${currency}`;
  return MD5(preSignature).toString();
};
