import { PlatformTable, PlatformTableRow } from "components";
import { size } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { planService } from "services";
import Styles from "./index.module.css";

const titles = ["common.id", "common.email"];

export const SlotsConsumption = () => {
  const [slotsConsumption, setSlotsConsumption] = useState<Array<string> | undefined>(undefined);
  const { t } = useTranslation();

  const isLoading = slotsConsumption === undefined;

  useEffect(() => {
    (async () => {
      const response = await planService.getSlotsConsumption();
      setSlotsConsumption(response.data || []);
    })();
  }, []);

  const header = titles.map(title => t(title));

  let body: Array<PlatformTableRow> = [];

  if (size(slotsConsumption)) {
    body = (slotsConsumption as Array<string>)?.map((slot, index) => ({
      id: slot,
      columns: [
        {
          id: `id_${slot}`,
          content: index + 1,
        },
        {
          id: `email_${slot}`,
          content: slot,
        },
      ],
    }));
  }

  return (
    <PlatformTable className={Styles.table} isLoading={isLoading} header={header} body={body} />
  );
};
