import { Plan } from "models";
import { useEffect, useState } from "react";
import { planService } from "services";
import { Form } from "./form";

export const Summary = () => {
  const [plan, setPlan] = useState<Plan>();

  useEffect(() => {
    (async () => {
      const response = await planService.findUserPlanActive();
      const responsePlan = response.data as Plan;
      setPlan(responsePlan);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!plan) return null;
  return <Form plan={plan} />;
};
