import { PlatformMenu } from "components";
import { Category } from "models";
import { UpdateCategoryAction } from "./update-category-action";

interface Props {
  category: Category;
  loadCategories: () => Promise<void>;
}
export const Actions = ({ category, loadCategories }: Props) => {
  return (
    <PlatformMenu>
      <UpdateCategoryAction loadCategories={loadCategories} category={category} />
    </PlatformMenu>
  );
};
