import { NexusInput, PlatformButton, UserContainer } from "components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Styles from "./index.module.css";
interface Props {
  closeModal: () => void;
}
export const TypeFeedback = ({ closeModal }: Props) => {
  const { watch } = useFormContext();
  const { user } = watch();
  const { t } = useTranslation();
  return (
    <div>
      <div className={Styles.container}>
        <h1>{t("anonymous_feedback.write_your_feedback")}</h1>
        <UserContainer user={user} />
      </div>
      <NexusInput name="feedback" label={t("anonymous_feedback.feedback")} rows={3} multiline />
      <div className={Styles.bottomContainer}>
        <PlatformButton onClick={closeModal} label={t("common.cancel")} />
        <PlatformButton label={t("anonymous_feedback.send_feedback")} />
      </div>
    </div>
  );
};
