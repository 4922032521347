import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routing } from "routing";
import { Event } from "models";

interface Props {
  event: Event;
}

export const GoToEvent = ({ event }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <MenuItem
      onClick={() =>
        navigate(`/${Routing.platformModule}/${Routing.platform.adminEvent}/${event.eventId}`)
      }
    >
      {t("events.go_event")}
    </MenuItem>
  );
};
