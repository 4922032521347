export enum VideoUserActionAction {
  ADD_VIDEO = "ADD_VIDEO",
  ADD_AUDIO = "ADD_AUDIO",
  REMOVE_VIDEO = "REMOVE_VIDEO",
  REMOVE_AUDIO = "REMOVE_AUDIO",
  ADD_USER = "ADD_USER",
  REMOVE_USER = "REMOVE_USER",
  ADD_ROOM = "ADD_ROOM",
  REMOVE_ROOM = "REMOVE_ROOM",
  MUTE_AUDIO_USER = "MUTE_AUDIO_USER",
  MUTE_VIDEO_USER = "MUTE_VIDEO_USER",
  UNMUTE_AUDIO_USER = "UNMUTE_AUDIO_USER",
  UNMUTE_VIDEO_USER = "UNMUTE_VIDEO_USER",
}
