import { NexusSelect } from "components";
import { Franchise } from "enums";
import { useTranslation } from "react-i18next";

const options = [
  {
    value: Franchise.AMEX,
    label: "American Express",
  },
  {
    value: Franchise.MASTERCARD,
    label: "Mastercard",
  },
  {
    value: Franchise.VISA,
    label: "VISA",
  },
  {
    value: Franchise.DINERS,
    label: "Diners",
  },
];

export const FranchiseInput = () => {
  const { t } = useTranslation();
  return (
    <NexusSelect items={options} label={`${t("payment_methods.franchise")}*`} name="franchise" />
  );
};
